const displayPieChart = async (canvasId, ratios) => { // don't store these in state due to refreshing issue in draggable pie chart


    console.log(" displayPieChart (small) has been invoked: " + canvasId + " " + JSON.stringify(ratios));


    let targetuserParamSliceArray = [
        ['Fat', ratios[0], fmdColors[0], fmdColors[3]],
        ['Protein', ratios[1], fmdColors[1], fmdColors[3]],
        ['Carbs', ratios[2], fmdColors[2], fmdColors[3]]
    ];


  //  console.log(" user slice array: " + ratios [0] + " " + JSON.stringify(ratios));


    pieTarget = null;
    pieTarget = new PieChartJS(canvasId, targetuserParamSliceArray, true);

}
// see X11 color names = https://en.wikipedia.org/wiki/Web_colors
const fmdColors = [
    '#80a1d9'
    , '#cc6a55'
    , '#f8e48a'
    , '#f7b529'

];


// declare global variable (not inside function)
var pieTarget;

//displayPieChart(canvasId, ratios);


function PieChartJS(canvasElementId, userParamSliceArray, startFrom6Oclock) {

  //  console.log("this is an element that aint there:" + document.getElementById("somefink that aint there 43243"))

    //console.log("this is the element by id we're getting:" + document.getElementById(canvasElementId))

    var pieObject = {
        canvas: document.getElementById(canvasElementId) // copy from parameter
        , userParamSliceArray: userParamSliceArray // copy from parameter
        , ctx: null
        // sliceArray info consists of:
        // this.sliceRadius
        //this.radStartAngle
        //this.radEndAngle
        //this.sliceUserParams;
        , sliceArray: []
        , totalAllValue: 0
        , centX: 0
        , centY: 0
        , radius: 0
        , MAX_RADIUS: 0
        // convert 1 Degree to Radian = Math.PI / 180
        , ONE_DEGREE_TO_RADIAN: 0.017453292519943295
        // convert 1 Radian to Degree = 180 / Math.PI
        , ONE_RADIAN_TO_DEGREE: 57.29577951308232
        // How many Degree in 1 full circle ? = 360 Degree
        // How many Radian angle in 1 full circle ? = (2 * Math.PI) = 6.283185307179586 Radian
        , MAX_RADIAN_VALUE: 6.283185307179586
        , DEFAULT_BORDER_COLOR: '#CCCCCC'
        , BORDER_LINE_WIDTH: 1
        , degToRad
        , radToDeg
        , getPointXY
        , SliceOfPie
        , drawSlice
        , initialiseCharts
        , updateSliceArray
        , refreshSliceArray
        , getRadianDegree
        , isPointInsideSliceOfPie
        , redraw
        , _create
    };

    // IIFE (Immediately-invoked function expression) -> return PieChartJS of this userParamSliceArray set object
    return pieObject._create(canvasElementId, userParamSliceArray, startFrom6Oclock);
} // function PieChartJS(canvas, userParamSliceArray) {


/////////////////////////////////////////////////////////////////////////////////////////////////////


// start/init
function _create(canvasElementId, userParamSliceArray, startFrom0oClock) {
    if (canvasElementId == null || userParamSliceArray == null) {
        return null;
    }

    this.canvas = document.getElementById(canvasElementId);
    if (this.canvas == null) {
        return null;
    }

    this.userParamSliceArray = userParamSliceArray;

    // parsing to boolean true/false, true = slice start from top, false = slice start from 3 O'Clock
    this.startFrom0oClock = startFrom0oClock ? true : false;

    this.ctx = this.canvas.getContext('2d');

    // constant value
    this.ctx.font = 'bold 12px sans-serif'; // label font
    this.ctx.lineWidth = 5;

    // calculate total value for degToRad
    this.totalAllValue = 0;
    userParamSliceArray.forEach(function (value, index, ele) {
        // calculate total
        this.totalAllValue += value[1];
    }.bind(this)); // send 'this' to the function !!
  //  console.log('total all value: ' + this.totalAllValue);

    // init
    this.initialiseCharts();


    // make Responsive by catch window (viewport) resize and re-init
    // window.addEventListener('resize', this.onWindowResize.bind(this), false);

    return this; // return of 'this' (PieChartJS) object
}


function degToRad(deg) {
    // Math.PI = 3.141592653589793
    // Math.PI / 180 = 0.017453292519943295

    return deg * this.ONE_DEGREE_TO_RADIAN; //deg * (Math.PI / 180);
}


function radToDeg(rad) {
    return rad * this.ONE_RADIAN_TO_DEGREE; //rad * (180 / Math.PI);
}


function getPointXY(sliceRadius, radianAngle) {
    //console.log('getPointXY(' + sliceRadius + ',' + radianAngle + ')');

    // WARNING: this function is slow because using Math.cos() and Math.sin()
    // use cache if possible

    return {
        'x': this.centX + sliceRadius * Math.cos(radianAngle),
        'y': this.centY + sliceRadius * Math.sin(radianAngle)
    };
}


function SliceOfPie(userParamSliceArray, radStartAngle, radEndAngle, radius) {

    // for enlarging slice size animation by changing its radius size
    // so need to save individual slice's radius
    // in this context this means an element of SliceArray
    this.sliceRadius = radius;
    this.radStartAngle = radStartAngle;
    this.radEndAngle = radEndAngle;
    this.sliceUserParams = userParamSliceArray;

    // RMH a new one
    this.isHighlighted = false;

}

function drawSlice(slice) {
    let ctx = this.ctx;


    if (ctx) {


        // set this point as beginning of path
        ctx.beginPath();

        // 1. draw the arc (THIS IS starting point)
        ctx.arc(this.centX, this.centY, slice.sliceRadius, slice.radStartAngle, slice.radEndAngle);

        // 2. draw line from end of arc to center
        ctx.lineTo(this.centX, this.centY); // line to go back to center make a PIE SLICE

        // 3. draw line from center to start of arc (to complete line)
        // let point = this.getPointXY(slice.sliceRadius, slice.radStartAngle);
        // ctx.lineTo(point.x, point.y);

        // use closePath() instead of calculate the point using getPointXY() --> slow math calculation
        ctx.closePath(); // NOTE: must call closePath() before call fill() or stroke() !!

        // IMPORTANT: default stroke is in the MIDDLE of the line,
        // to change it to 'INNER', we need to work in sequence: fill() then clip() then stroke())
        ctx.fillStyle = slice.sliceUserParams[2];
        ctx.fill();
        let borderColor = this.DEFAULT_BORDER_COLOR;// non highlighted border color
        // draw border ONLY if this slice is active
        if (slice.isHighlighted) {

            if (slice.sliceUserParams[3]) {
                borderColor = slice.sliceUserParams[3];
            }

        }

        //console.log('drawSlice(slice), this is the active slice: ' + this.activeSliceOfPie);

        // need to draw border

        ctx.save(); // save before clip
        ctx.clip();

        // lineWidth was previously defined but not working, so always define it before use
        ctx.lineWidth = this.BORDER_LINE_WIDTH; // will be used in HALF (because inner clip)
        ctx.strokeStyle = borderColor;

        // use the provided value if existed or use default WHITE
        //if (slice.sliceUserParams[3]) {
        //  borderColor = slice.sliceUserParams[3];
        //}
        ctx.stroke();

        // restore will let open all canvas area (remove clip)
        ctx.restore();

        // }
    }
}

function initialiseCharts() {


    if (!this.canvas) {
        console.log("pie returning .. the canvas is null");

        return;
    }



    // get parent size
    let parentW = this.canvas.parentNode.offsetWidth;
    let parentH = this.canvas.parentNode.offsetHeight;




    // resize canvas as BIG as parent
   // if (this.canvas.width !== parentW || this.canvas.height !== parentH) {
        //console.log('parent: ' + parentW + '*' + parentH + ', canvas: ' + this.canvas.width + '*' + this.canvas.height);


        let maxDiameterPie = Math.min(parentW, parentH);
        let maxRadiusPie = maxDiameterPie / 2
        // square w = h
        this.canvas.width = maxDiameterPie;


        //this.MIN_RADIUS = this.MAX_RADIUS - 50; // -5 : gap to avoid pie go outside parent
        this.canvas.height = this.canvas.width;

  //  }


    // minus 10%
    this.originalRadius = (maxRadiusPie * 90) / 100;

    // center
    this.centX = (maxDiameterPie / 2) - 1;
    this.centY = this.centX;//this.height / 2;

    // resized means NEED TO recreate all slices and do full draw(), not only redraw same slices
    this.refreshSliceArray();//true); // force
    this.redraw();

}

function updateSliceArray(radius) {

    for (let i in this.userParamSliceArray) {
        this.sliceArray[i].sliceRadius = radius;
    }
}

function refreshSliceArray() {

    if (this.totalAllValue <= 0) {
        this.sliceArray = []; // no slice
        return;
    }

    let radStartAngle = 0, radEndAngle = 0;

    if (this.startFrom0oClock) {

        radStartAngle = 90 * Math.PI / 180

    }
    for (let i in this.userParamSliceArray) {



        // how many degree this 1 slice ? .. 360 * (value / total)
        let angle = this.MAX_RADIAN_VALUE * (this.userParamSliceArray[i][1] / this.totalAllValue);
        radEndAngle = radStartAngle + angle;

        if (this.startFrom0oClock && radEndAngle > this.MAX_RADIAN_VALUE) {
            radEndAngle -= this.MAX_RADIAN_VALUE;
        }



        this.sliceArray[i] = new this.SliceOfPie(this.userParamSliceArray[i], radStartAngle, radEndAngle, this.originalRadius);

        // update for next loop
        radStartAngle = radEndAngle;
    }
}

function getRadianDegree(x, y) {
    let x2 = x - this.centX;
    let y2 = y - this.centY;
    let v1dl = Math.sqrt((x2 * x2) + (y2 * y2));

    let radDegree = Math.acos(x2 / v1dl);
    if (y2 < 0) {
        radDegree = this.MAX_RADIAN_VALUE - radDegree;
    }

    return radDegree;
}


function isPointInsideSliceOfPie(x, y, slice) {
    //console.log('isPointInsideSliceOfPie(' + x + ',' + y + ', slice angle:' + slice.radStartAngle + ',' + slice.radEndAngle + ')');

    // 1. check if the angle is inside or not (because cheaper/faster operator then checking distance/radius ???? TODO:)
    // calculate the Radian angle of this point
    let angle = Math.atan2(y - this.centY, x - this.centY);

    // if negative then convert to positive
    if (angle < 0) {
        angle += this.MAX_RADIAN_VALUE;
    }
    //console.log('angle: ' + angle);

    if (this.startFrom0oClock && slice.radStartAngle > slice.radEndAngle) {

        if (angle < slice.radStartAngle && angle > slice.radEndAngle) {
            return false;
        }
    } else if (angle < slice.radStartAngle || angle > slice.radEndAngle) {
        //console.log('** point is OUTSIDE slice angle');
        return false; // this point is outside of pie
    }

    // 2. check distance and radius
    let pointDistance = ((x - this.centX) * (x - this.centX)) + ((y - this.centY) * (y - this.centY));
    if (pointDistance > slice.sliceRadius * slice.sliceRadius) {
        //console.log('** point is OUTSIDE slice distance');
        return false; // point is outside radius
    }

    return true; // this point is inside slice of pie
}

function redraw() {
    //console.log('redraw(), full drawing without setting sliceArray');

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    for (let i in this.sliceArray) {

        this.drawSlice(this.sliceArray[i]);
    }
}

export default displayPieChart;










