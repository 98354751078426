import React from 'react'
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";

const SearchResultsView = ({results}) => {
    return results.loading ? (
        <p>Loading...</p>
    ) : results.error ? (
        <p>Try again later...</p>
    ) : (
        <div className="outerDiv">
            <h1 style={{margin: 10}}>Search Results</h1>
            <br/>

            {results.data.itemsOnThisPage.length === 0 ? (
                <p>No recipes match</p>
            ) : (
                <>
                    {results.data.itemsOnThisPage.map((rec) => (
                        <div style={{padding: 10, background: "#eee", margin: 30}} key={rec.recipeId}>
                            <h3>
                                <Link to={"/recipes/" + rec.recipeId}>
                                    {rec.recipeName}
                                </Link>
                            </h3>
                            <p>{
                                isEmpty(rec.recipeSummary) ? (rec.recipeInstructions.substring(0, 180) + "...") : (rec.recipeSummary)
                            }</p>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default SearchResultsView;
