export const numFormat = (num, dp, trailZeroes = false, plusForPositive) => {

    let answer = num.toFixed(dp);
    if (!trailZeroes) {
        answer = parseFloat(answer).toString();
    }
    // console.log ("formatting " + num + " to: " + answer);
    if (plusForPositive && answer > 0) {
        answer = "+" + answer;
    }
    return answer;

}


export const logJSON = (jsonToLog) => {
// makes json prettier for logging, so we can make head / tail of it.
    // let ans = jsonToLog.replace('\"ingredientsList\":','\"ingredientsList\":\n\n');
    //  let ans = jsonToLog.replace('\"ingredientsList\":','\"ingredientsList\":\n\n');
    let ans = JSON.stringify(jsonToLog)
    ans = ans.replaceAll('{', '\n    {');
    ans = ans.replaceAll('[', '\n[');
    // this is the hex version on the email address. it hopefully goes some way to avoid scrapers
    return ans;
}


export const privateSupportEmail = () => {

    // this is the hex version on the email address. it hopefully goes some way to avoid scrapers
    return atob('c3VwcG9ydEBmaXRteWRpZXQuY29t');
}


const msInYear = 86400000 * 365
const msInWeek = 86400000 * 7
const msInDay = 86400000
const msInMinute = 60000
const msInHour = 3600000
const msInSecond = 1000

const monthName = new Intl.DateTimeFormat("en-US", {month: "long"}).format;


/*
Similar to dateFormat but is more human readable e.g. "yesterday" or, "two days ago" */
export const dateWordFormat = (dateStringInUtc) => {

    var answer = "";
    var endofToday = new Date();
    endofToday.setHours(23, 59, 50,);
    var thisMoment = new Date();
    let passedDate = new Date(dateStringInUtc)
    let diffFromDayEnd = endofToday - passedDate
    let diffFromMoment = thisMoment - passedDate

    if (diffFromMoment < msInSecond * 10) {
        answer = "right now "

    } else if (diffFromMoment < msInMinute) {
        // less than 60 seconds ago
        answer = "just seconds ago "

    } else if (diffFromMoment < msInHour) {

        let minsAgo = Math.floor(diffFromMoment / msInMinute);
        let s = (minsAgo > 1) ? ("s") : ("");

        answer = minsAgo + " minute" + s + " ago"
    } else if (diffFromMoment < msInDay) {
        let hoursAgo = Math.floor(diffFromMoment / msInHour);

        let s = (hoursAgo > 1) ? ("s") : ("");

        answer = hoursAgo + " hour" + s + " ago"


    } else if (diffFromDayEnd < msInDay * 2) {

        answer = "yesterday at " + formatTime(passedDate.getTime())

    } else if (diffFromDayEnd < msInDay * 21) {
        //use days
        let daysAgo = Math.floor(diffFromDayEnd / msInDay)
        let s = (daysAgo > 1) ? ("s") : ("");

        answer = daysAgo + " day" + s + " ago"
    } else if (diffFromDayEnd < msInWeek * 5) {
        // less than 5 weeks... so will report 4 or less weeks
        let weeksAgo = Math.floor(diffFromDayEnd / msInWeek)
        let s = (weeksAgo > 1) ? ("s") : ("");

        answer = weeksAgo + " week" + s + " ago"

    } else if (diffFromDayEnd < msInYear) {

        let strMonth = monthName(passedDate); // "July"
        let monthsAgo = Math.abs(passedDate.getMonth() - endofToday.getMonth())
        let s = (monthsAgo > 1) ? ("s") : ("");

        answer = monthsAgo + s + " months ago (" + strMonth + ")"

    } else {
        let yearsAgo = Math.floor(diffFromDayEnd / msInYear)
        let strMonth = monthName(passedDate); // "July"

        if (yearsAgo === 1) {
            answer = strMonth + "last year"
        } else {
            answer = strMonth + " " + passedDate.getFullYear()

        }

    }

    return answer;
}

export const numToWord = (num) => {

    switch (num) {
        case 0:
            return "zero";
        case 1:
            return "one";
        case 2:
            return "two"
        case 3:
            return "three"
        case 4:
            return "four"
        case 5:
            return "five"
        case 6:
            return "six"
        case 7:
            return "seven"
        case 8:
            return "eight"
        case 9:
            return "nine"
        case 10:
            return "ten"
    }

}

export const recursiveObjToString = (obj) => {
    var result = {}, _tmp;
    for (var i in obj) {
        // enabledPlugin is too nested, also skip functions
        if (i === 'enabledPlugin' || typeof obj[i] === 'function') {
            continue;
        } else if (typeof obj[i] === 'object') {
            // get props recursively
            _tmp = recursiveObjToString(obj[i]);
            // if object is not {}
            if (Object.keys(_tmp).length) {
                result[i] = _tmp;
            }
        } else {
            // string, number or boolean
            result[i] = obj[i];
        }
    }
    return JSON.stringify(result);
}


export const restrictCharsBySplit = (original, maxLen, centreSplitAdjuster = 0, elipsis = "..") => {

    let answer = original;
    console.log("calling restrict")
    if (original.length > maxLen) {
        let eachStrLen = Math.floor((maxLen - (elipsis.length)) / 2)
        console.log("eachStrLen" + eachStrLen)
        let firstHalf = original.substr(0, eachStrLen + centreSplitAdjuster);
        let LastHalf = original.substr(original.length - eachStrLen + centreSplitAdjuster, original.length);
        answer = firstHalf + elipsis + LastHalf;
    }
    return answer;

}


export function thIt(dayInMonth) {


    let lastChar = dayInMonth.toString().substring(dayInMonth.length - 1, dayInMonth.length)

    if (dayInMonth == "11") {
        return "th"
    } else if (dayInMonth == "12") {
        return "th"
    } else if (dayInMonth == "13") {
        return "th"
    } else if (lastChar == "1") {
        return "st"
    } else if (lastChar == "2") {
        return "nd"
    } else if (lastChar == "3") {
        return "rd"
    } else {
        return "th"
    }

}


export function formatDate(dateString) {

    let date = new Date(dateString)

    var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    var weekdayArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];


    const prettyMonth = mS[date.getMonth()]
    const weekday = weekdayArr[date.getDay()];
    const year = date.getFullYear();
    const day = date.getDate() + thIt(date.getDate())

    return weekday + " " + day + " " + prettyMonth + " " + year

}


export function formatTime(dateString) {

    let time = new Date(dateString)

    const hour = time.getHours();
    const minute = time.getMinutes() //+ time.getTimezoneOffset();
    //  const second = time.getSeconds();
    let temp = '' + ((hour > 12) ? hour - 12 : hour);
    if (hour === 0)
        temp = '12';
    temp += ((minute < 10) ? ':0' : ':') + minute;
    //   temp += ((second < 10) ? ':0' : ':') + second;
    temp += (hour >= 12) ? 'pm' : 'am';

    return temp;
}

export const makeNonBreaking = (stringWithSpaces) => {
    return stringWithSpaces.replace(/\s/g, '\u00A0');
}

export const highlightOnFocus = (event) => event.target.select();

export const titleCase = (mySentence) => {
    mySentence = mySentence.replace("-", " ")
    return mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}