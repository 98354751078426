import React, {useEffect, useState} from 'react'
import {restGet, restPost} from "../../utils/RestUtils";
import NavBar from "../nav-bar/NavBar";
import {
    addSingleErrorToScreen,
    getServerSideErrorMessage,
    isBlank,
    registrationIsValid,
    usernameValid
} from "../../Validation/ErrorValidation";
import {isUndefined} from "lodash";
import RegistrationView from "./RegistrationView";
import UserRegistrationConfirmationPopup from "./UserRegistrationConfirmationPopup";
import {getLoginItem} from "../../utils/LoginUtils";


const RegistrationRestWrapper = () => {

    const [registeredUserData, setRegisteredUserData] = useState();
    const [errorMessageData, setErrorMessageData] = useState([]);
    const [unameInUse, setUnameInUse] = useState(null);// null true or false
    const [registrationConfirmationVisible, setRegistrationConfirmationVisible] = useState(false);

    const addRegisteredUserData = async (json) => {


        setUnameInUse(await (unameInUseAsYouType(json)));


        console.log("whats in the username" + JSON.stringify(json))
        await setRegisteredUserData({
            ...registeredUserData, ...json
        });

    }
    const unameInUseAsYouType = async (json) => {
        console.log("who knows uname:" + json)
        let unameVal = json.username
        let answer = null;

        if (usernameValid(unameVal)) {
            let responseJson = await restGet("/fmd/registeredusers/uname/" + unameVal);
            if (!isBlank(responseJson.inUse)) {
                answer = responseJson.inUse
            }
        }
        return answer;
    }
    const submitRegisteredUserData = async () => {

        let registeredUserDto;
        if (isUndefined(registeredUserData)) {
            registeredUserDto = {}; // to avoid null pointers and weird behaviour if registeredUserDto isn't even in the json (which it isn't at first)
        } else {
            registeredUserDto = registeredUserData;
        }

        let registeredUserValid = await registrationIsValid(registeredUserDto, setErrorMessageData);// client side validation

        if (registeredUserValid) {

            // on registration, not on registeredUser
            //  registeredUserDto.vuid=localStorage.getItem("vuid")
            // It doesn't do a pemanent change, but this info needs to be put into the body, so it's a post
            registeredUserDto.visitorId = localStorage.getItem("vuid")
            let responseJson = await restPost("/fmd/registeredusers/", {registeredUserDto});

            let errorText = getServerSideErrorMessage(responseJson);

            if (errorText != null) {
                //setErrorMessageData({"errorPosition" : json.errorCode, "message": error}) // for error in different positions
                await addSingleErrorToScreen(responseJson.messageCode, errorText, setErrorMessageData) // hard coded for error in same position regardless of the error
            } else {
                // liu = logged in user
                sessionStorage.setItem("liu", JSON.stringify(responseJson));
                // now display pop up confirmation screen
                setRegistrationConfirmationVisible(true)
                setRegisteredUserData(null)
            }

        }

    }


    const closePopUp = () => {
        console.log("closepop");
        setRegistrationConfirmationVisible(false)
        window.location = "/"; // TODO: refactor these out at some point prob, just use state

    }
    const doFirst = async () => {

    }

    useEffect(() => {

        doFirst();


    }, []);


    return (<>

        {registrationConfirmationVisible === true ?
            <UserRegistrationConfirmationPopup
                userName={getLoginItem("us")}
                closePopUp={closePopUp}
            /> : null}
        <NavBar/>
        <RegistrationView
            addRegisteredUserData={addRegisteredUserData}
            submitRegisteredUserData={submitRegisteredUserData}
            errorMessageData={errorMessageData}
            unameInUse={unameInUse}

        />
        <br/>
    </>);
};

export default RegistrationRestWrapper;
