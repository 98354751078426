import React from 'react'
import NavBar from "../nav-bar/NavBar";
import {privateSupportEmail} from "../../utils/FormattingUtils";


const FaqView = ({}) => {

    return (
        <>
            <NavBar/>
            <div className="outerDiv">
                <h1>Help & FAQ</h1>
                <br/>
                <p style={{textAlign:"center"}}>

                    We're not so great as to have gotten around to completing this section yet. <br/><br/>The site is changing
                    quickly, check back soon!<br/><br/>
                    If you need to get in touch, contact us at:<br/>
                    <a href={"mailto:" + privateSupportEmail()}>{privateSupportEmail()}</a>

                </p>

                <br/>

            </div>
        </>
    );
};

export default FaqView;




