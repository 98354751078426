import React, {useEffect} from 'react'

const UserLoginConfirmationPopup = ({
                                        userName,
                                        closePopUp
                                    }) => {


    useEffect(() => {
        window.location.hash = "#topOfPage"

    }, []);


    return (
        <div className="popupstyle">
            <div className="popupstyle_content">




          <span className="closePopupX" onClick={(e) => {
              // same action here as clicking save!

              closePopUp();
          }}>
            &times;
          </span>

                <br/>
                <br/>
                <h2>Welcome back {userName},</h2> <br/>You are now logged in to Fit My Diet
                <br/><br/>
                We're glad you're back.

                <br/>
                <br/>
                <button onClick={() => {
                    closePopUp()
                }}>Continue
                </button>
            </div>
        </div>
    );
};

export default UserLoginConfirmationPopup;
