import React from 'react'
import {highlightOnFocus, titleCase} from "./FormattingUtils";
import ErrorMessage, {styleIfError} from "../Validation/ErrorMessage";
import questionMark from '../img/question.png';

export const FmdTextArea = ({
                                jsonName,
                                errorMessageData,
                                dataAddFunction,
                                compulsory = true,
                                fldNameLabel = titleCase(jsonName),
                                errorMessageCodes,
                                toolTip = "",
                                imageForTip = questionMark,
                                placeholder = "",
                                numRows = 10,
                                withPx = "100%",
                                defaultVal
                            }) => {
    return (
        <>
            <td colSpan={2} style={{textAlign: "left", verticalAlign: "top", paddingTop: 4, paddingRight: 0}}>
                {compulsory ? (<label title="required" className="field-compulsory"  style={{ lineHeight: "32px"}}>&nbsp;<sup
                        className="field-compulsory-indicator">REQ&nbsp;</sup>{fldNameLabel}&nbsp;&nbsp;</label>)
                    : (<label title="optional" style={{ lineHeight: "32px"}}>&nbsp;{fldNameLabel}&nbsp;&nbsp;</label>)}

                              <textarea rows={numRows}
                                        style={{width: withPx, paddingRight: 15}}
                                        id={jsonName}
                                        placeholder={placeholder}
                                        className={styleIfError(errorMessageCodes, errorMessageData, "inputboxerror", "")}
                                        value={defaultVal}
                                        onFocus={(e) => {
                                            highlightOnFocus(e)
                                        }}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            dataAddFunction({[jsonName]: val})
                                        }}
                              />
                {toolTip.length > 0 ? (<img style={{
                    cursor: "pointer",
                    textAlign: "right",
                    verticalAlign: "bottom",
                    margin: "0px",
                    paddingLeft: 2,
                    paddingBottom: 2
                }} src={imageForTip} title={toolTip} alt={toolTip}/>) : ("")}
                <ErrorMessage errorCodes={errorMessageCodes} errorDataArray={errorMessageData}/>
            </td>
        </>

    );

}


