import React, {useEffect, useState} from 'react'
import CreateFoodView from "./CreateFoodView";
import {logJSON} from "../../utils/FormattingUtils";
import {restGet, restPost} from "../../utils/RestUtils";
import NavBar from "../nav-bar/NavBar";
import {
    addSingleErrorToScreen,
    blankErrorMessagesOnScreen,
    foodIsValid,
    getServerSideErrorMessage
} from "../../Validation/ErrorValidation";
import {isUndefined} from "lodash";
import FoodCreatePopUp from "./FoodCreatePopUp";


const CreateFoodRestWrapper = () => {

    const [nuts, setNuts] = useState([]);
    const [foodData, setFoodData] = useState();
    const [errorMessageData, setErrorMessageData] = useState([]);
    const [foodCreatePopUpVisible, setFoodCreatePopUpVisible] = useState(false);

    // the defaults here are the compulsory nuts hard coded / displayed in the compulsory section
    let excludeFromOptionalFields = [10, 20, 30, 35];

    const createNewNutOnLabJson = (nutId, nutName, val) => {

        console.log("createNewNutOnLabJson method nutName" + nutName + " nutId" + nutId + " val" + val)

        let newNutOnLab = {
            "nutrientDto": {
                "nutrientId": nutId,
                "nutrientName": nutName
            },// for display
            "accuracyCode": null, // 1. trace , null. we know the value
            "valuePer100g": val
        }

        return (newNutOnLab);
    }

    const addFoodData = async (json) => {
        await setFoodData({
            ...foodData,
            ...json
        });


    }

    const doNutAddRestCall = async (nutId) => {
        return await restGet(`/fmd/nutrients/${nutId}`);
    }

    const onChangeOfFat = (newQuant) => {
        changeNutrientQuantity(10, newQuant.fatVal, 0)
    }
    const onChangeOfProtein = (newQuant) => {
        changeNutrientQuantity(20, newQuant.proVal, 1)
    }
    const onChangeOfCarbs = (newQuant) => {
        changeNutrientQuantity(30, newQuant.carbVal, 2)
    }

    const onChangeOfSugar = (newQuant) => {
        changeNutrientQuantity(35, newQuant.sugVal, 3)
    }
        const changeNutrientQuantity = (nutId, newQuant, insertBackAtIndex) => {

        //if (isNaN(newQuant)) return;

        console.log("nutId param:" + nutId);
        console.log(" nuts: \n" + JSON.stringify(nuts));

        console.log("on ingredient quantity changed value:" + newQuant);
        console.log("on ingredient quantity changed insertBackAtIndex:" + insertBackAtIndex);
        const relevantNutArr = nuts.filter((nut) => nut.nutrientDto.nutrientId === nutId); // the one we're removing
        const relevantNut = relevantNutArr[0];
        console.log(" relevantNut: \n" + JSON.stringify(relevantNut));
        let nutOnLabJson = createNewNutOnLabJson(relevantNut.nutrientDto.nutrientId, relevantNut.nutrientDto.nutrientName, newQuant);
        console.log(" nutOnLabJson (new one to enter): \n" + JSON.stringify(nutOnLabJson));


        const updatedNuts = nuts.filter((nut) => nut.nutrientDto.nutrientId !== nutId); // remove this nut from the array


        //getting confused here about creating a nut to put into the list..
        //is it a stripped down nutonlab
        //is it a straight from the database nutrient.. oh jeez.

        updatedNuts.splice(insertBackAtIndex, 0, nutOnLabJson); // add ingredient back in at the same array index
        setNuts(updatedNuts);

    };


    const addNutrientToList = async (nutrientId) => {

        // because changing the state variable directly results in failure to rerender
        let nutsCopy = JSON.parse(JSON.stringify(nuts));
        let nutsThatsAlreadyThere = nutsCopy.filter((nut) => nut.nutrientDto.nutrientId === nutrientId);

        // not already there
        if (nutsThatsAlreadyThere != null && nutsThatsAlreadyThere.length === 0.0) {

            let nutFromDbJson = await doNutAddRestCall(nutrientId);
            let nutOnLabJson = createNewNutOnLabJson(nutFromDbJson.nutrientId, nutFromDbJson.nutrientName, 0.00);
            nutsCopy.unshift(nutOnLabJson); // Add to the top of the list
            console.log("nuts are this after new nut added :\n" + logJSON(nutsCopy))

            setNuts(nutsCopy);

            excludeFromOptionalFields.push(nutrientId);

        } else {
            return null;
        }

    }

    const submitAddFoodData = async () => {

        let foodDto;

        if (isUndefined(foodData)) {
            foodDto = {}; // to avoid null pointers and weird behaviour if foodDto isn't even in the json (which it isn't at first)
        } else{
            foodDto = foodData;
        }

        foodDto.nutrientOnFoodLabelsList = nuts; // add the nutrients to the food

        let foodValid = await foodIsValid(foodDto, setErrorMessageData);// client side validation

        if (foodValid) {
            let responseJson = await restPost("/fmd/foods/", {foodDto});

            let errorText = getServerSideErrorMessage(responseJson); // message chosen on client side here
            let errorCode = responseJson.messageCode;
            if (errorText != null) {
                //setErrorMessageData({"errorPosition" : json.errorCode, "message": error}) // for error in different positions
                await addSingleErrorToScreen(errorCode, errorText, setErrorMessageData) // hard coded for error in same position regardless of the error
            } else {
                await blankErrorMessagesOnScreen(setErrorMessageData)
                setFoodCreatePopUpVisible(true);// confirmation screen
                //let fId = responseJson.newFoodId;
            }

        }

    }

    const closePopUp = () => {
        console.log("closepop")
        setFoodData({});
        setFoodCreatePopUpVisible(false)
    }
    const doFirst = async () => {

        let nutsCopy = [];
        // Add the compulsory nutrients to the database so that they can be MODIFIED on the front end.
        // they need adding before they get modified, such is the design.
        let nutFromDbJson = await doNutAddRestCall(10);//fat
        let nutOnLabJson = createNewNutOnLabJson(nutFromDbJson.nutrientId, nutFromDbJson.nutrientName, "");
        nutsCopy.push(nutOnLabJson); // Add to the top of the list

        nutFromDbJson = await doNutAddRestCall(20);//protein
        nutOnLabJson = createNewNutOnLabJson(nutFromDbJson.nutrientId, nutFromDbJson.nutrientName, "");
        nutsCopy.push(nutOnLabJson); // Add to the top of the list

        nutFromDbJson = await doNutAddRestCall(30);//carbs
        nutOnLabJson = createNewNutOnLabJson(nutFromDbJson.nutrientId, nutFromDbJson.nutrientName, "");
        nutsCopy.push(nutOnLabJson); // Add to the top of the list

        nutFromDbJson = await doNutAddRestCall(35);//of which sugar
        nutOnLabJson = createNewNutOnLabJson(nutFromDbJson.nutrientId, nutFromDbJson.nutrientName, "");
        nutsCopy.push(nutOnLabJson); // Add to the top of the list

        setNuts(nutsCopy);

    }


    useEffect(() => {

        doFirst();

    }, []);


    return (<>
            {foodCreatePopUpVisible ?
                <FoodCreatePopUp closePopUp={closePopUp} doFirst={doFirst}/> : null}
            <NavBar/>
            <CreateFoodView
                nutris={nuts} onNutrientAdd={addNutrientToList}
                onChangeOfFat={onChangeOfFat}
                onChangeOfProtein={onChangeOfProtein}
                onChangeOfCarbs={onChangeOfCarbs}
                onChangeOfSugar={onChangeOfSugar}
                onChangeOfNutrientQuantity={changeNutrientQuantity}
                addFoodData={addFoodData}
                submitFoodData={submitAddFoodData}
                excludeFromOptionals={excludeFromOptionalFields}
                errorMessageData={errorMessageData}

            />
            <br/>{
                /*JSON.stringify(foodData)*/
            }
        </>
    );
};

export default CreateFoodRestWrapper;
