import React, {useEffect} from 'react'
//be sure pieChartClient is in here... it will be greyed out but if your ide has removed it... the canvas won't render
import displayDraggablePie from "./piechart-draggable.js";

const PieChartDraggableView = ({
                                   initialPositions = [30, 30, 34], // pass in what you want it to display at first
                                   fatSetCallback,
                                   proteinSetCallback,
                                   carbSetCallback,
                                   topText,
                                   middleText,
                                   bottomText
                               }) => {


    const setReactVariablesAfterRender = () => {

        // this is setting what the chart should be displaying at any point in tims
        fatSetCallback(window.fatPercent)
        proteinSetCallback(window.proteinPercent)
        carbSetCallback(window.carbsPercent)

        // can just have an array here and update that (might be better actually)
      //  nutArrayCallback([window.fatPercent, window.proteinPercent, window.carbsPercent])


    }

    const doFirst = () => {


// to keep things consistent, use the setters to reflect the initialposition passed in

        displayDraggablePie()

        fatSetCallback(initialPositions[0])
        proteinSetCallback(initialPositions[1])
        carbSetCallback(initialPositions[2])

        const script = document.createElement("script");
        script.src = "./piechart-draggable.js";
        script.async = true;
        script.innerHTML = "let var = 'placeholder'";
        //script.onload = () => setReactVariablesAfterRender()



        let chart = document.getElementById("piechart-controls")
        chart.addEventListener('click', setReactVariablesAfterRender);
        chart.addEventListener('mouseout', setReactVariablesAfterRender);
        chart.addEventListener('mouseup', setReactVariablesAfterRender);
        chart.addEventListener('touchend', setReactVariablesAfterRender);
        
        document.body.appendChild(script);


    }


    useEffect(() => {

        doFirst();

    }, [doFirst]);


    return (
        <>

            <div id={"piechart-controls"}>
                {topText}
                <canvas id={"piechart"} width="300" height="300" macroDefaults={initialPositions}>
                    The pie chart can't be displayed on your browser version.
                    Please upgrade or if that's not possible, contact support.
                </canvas><br/>
                {middleText}
                <table id={"proportions-table"} className="pietable"/><br/>
                {bottomText}

            </div>


        </>
    );


};

export default PieChartDraggableView;




