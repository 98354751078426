import React, {useEffect} from 'react'
import IngredientsList from "./tuna-container/IngredientsList";
import ButtonPanel from "./tuna-container/ButtonPanel";
import {dateWordFormat, formatDate, formatTime, logJSON} from "../../utils/FormattingUtils";
import ErrorMessage from "../../Validation/ErrorMessage";
import favoriteOn from '../../img/favoriteOn.png';
import favoriteOff from '../../img/favoriteOff.png'
import favoriteDeactivated from '../../img/favoriteDeactivated.png';


import {
    FAVORITE_RECIPE_LIST_BLANK,
    RECIPE_ING_IN_RECIPE,
    TUNA_FOODS_All_LOCKED,
    TUNA_FOODS_LABEL_NEGATIVE_NUTRIENT,
    TUNA_FOODS_LIST_EMPTY,
    TUNA_FOODS_LOCKED_NEGATIVE_QUANTITY,
    TUNA_FOODS_LOCKED_TOO_MANY_CALORIES,
    TUNA_NUTRIENT_PROPORTION_NEGATIVE,
    TUNA_NUTRIENTS_DONT_ADD_UP_TO_ONE,
    TUNA_TOTAL_CALS_NOT_POSITIVE
} from "../../Validation/ErrorConstants";
import {isLoggedIn} from "../../utils/LoginUtils";
import {FmdInputRow} from "../../utils/FmdInputRow";
import {FmdTextArea} from "../../utils/FmdTextArea";
import MacroPies from "./tuna-container/MacroPies";
import FavoriteStar from "./tuna-container/FavoriteStar";
import {isUndefined} from "lodash";

const RecipeView = ({
                        recipe,
                        ings,
                        setIngredients,
                        targetMacros,
                        setTargetMacros,
                        setCurrSavedTarget,
                        resultMacros,
                        errorMessageData,
                        doAnalysis,
                        doFitting,
                        doEqualise,
                        doAddIngredient,
                        doRecipeRefresh,
                        setEditableRecFields,
                        saveAllFieldsToNewRecipe,
                        closePopUp,
                        openPopUp,
                        sanitiseDisplayOrders,
                        sortByDisplayOrders,
                        recipeIsFavorite,
                        usersTargets,
                        toggleFavorite,

                        setTransientTargetPopupVisible

                    }) => {

    {/* ings really should be a cut down version of recipe.ingredientsList SOMEHOW.  The problem is, a full ingredient with associated entities
     is too much redundant data to send through and remain efficient, some thinking and cutting down and thinking about TunaCompatibleFood and what it needs
     should be done.  Maybe making TunaIngredient a non abstract class?  This is a non functional problem that needs sorting at some stage*/
    }
    // This will get re rendered every time the ingredients are changed, bare that in mind, may be a little inefficient


    const signInWall = async () => {

        //signInPopup or something

    }
    const onRoundPortionChange = (value, index, ingredient) => {

        console.log("onRoundPortionChange value:" + value);
        console.log("onRoundPortionChange index:" + index);

        let currentProportion = ingredient.quantity / ingredient.foodDto.portionQuantity;

        // get rid of rounding errors
        currentProportion = Math.round((currentProportion + Number.EPSILON) * 100) / 100

        // console.log("ingredient.foodDto.portionQuantity :" + ingredient.foodDto.portionQuantity);

        if (currentProportion <= 0 || Number.isInteger(currentProportion)) {
            console.log("currentProportion is AN INTEGER" + currentProportion);
            if (value < 0) {
                if (currentProportion > 0)
                    currentProportion--;
                console.log("currentProportion--" + currentProportion);
            } else {
                currentProportion++;
                console.log("currentProportion--" + currentProportion);
            }


        } else {
            console.log("currentProportion is NOT an integer" + currentProportion);

            console.log("value.toInteger :" + value);


            if (value < 0) {
                currentProportion = Math.floor(currentProportion);
                console.log("floored:" + currentProportion);
            } else {
                currentProportion = Math.ceil(currentProportion);
                console.log("ceil:" + currentProportion);
            }
        }
        console.log("sent to on Portion Change:" + currentProportion);

        onPortionChange(currentProportion.toString(), index, ingredient);
        //    ingredient.foodDto.portionQuantity = rounded;

//console.log("ingredient.portionProportion:" + rounded);
        //      const updatedIngredients = ings.filter((ing) => ing.foodDto.foodId !== ingredient.foodDto.foodId);
        //    updatedIngredients.splice(index, 0, ingredient); // add ingredient back in at the same array index

        //  setIngredients(updatedIngredients); {
        //  TODO: I DO NEED THIS.. FOR WHEN FIT IT IS PRESSED not sure if there is a need to set ingredients here... this is being called because they have changed not to chnage them*/}

    };

    const onPortionChange = (portEntered, index, ingredient) => {

        let lastChar = portEntered.substring(portEntered.length - 1);
        let dotsInEntered = portEntered.split(".").length - 1;
        console.log("dotsInEntered" + dotsInEntered);
        if (isNaN(portEntered) && lastChar !== '.' || dotsInEntered > 1) return;// Think some more about edge cases here.

        console.log("on ingredient portion changed value:" + portEntered);
        console.log("on ingredient portion changed index:" + index);

        // && (portEntered.split(".").length - 1) >0


        const updatedIngredients = ings.filter((ing) => ing.foodDto.foodId !== ingredient.foodDto.foodId);
        ingredient.portionProportion = portEntered; // add in new entry

        ingredient.quantity = Number(portEntered * ingredient.foodDto.portionQuantity);

        if (portEntered > 0) {
            ingredient.quantityLocked = true; // pop the lock on automatically for convenience??
        } else {
            ingredient.quantityLocked = false;
        }

        updatedIngredients.splice(index, 0, ingredient); // add ingredient back in at the same array index

        setIngredients(updatedIngredients);
        {/*TODO: I DO NEED THIS.. FOR WHEN FIT IT IS PRESSED not sure if there is a need to set ingredients here... this is being called because they have changed not to chnage them*/
        }


        if (lastChar !== '.') {// don't want this "updating" back to a value without a dp, if you're in the process of putting one in!
            console.log("NOT a dot! (if it's a dot don't reanalyse");

            //  tunaReqIng = ingredientsToTunaRequestItems(ings)
            doAnalysis(ings) // todo: is this necesary?.. wont it happen when ingredients changed programatically?
        }

    };


    const onIngredientQuantityChange = (value, index, ingredient) => {

        if (isNaN(value)) return;

        console.log("on ingredient quantity changed value:" + value);
        console.log("on ingredient quantity changed index:" + index);
        const updatedIngredients = ings.filter((ing) => ing.foodDto.foodId !== ingredient.foodDto.foodId);
        ingredient.quantity = Number(value);

        ingredient.portionProportion = Number(value / ingredient.foodDto.portionQuantity); // add in new entry

        updatedIngredients.splice(index, 0, ingredient); // add ingredient back in at the same array index

        setIngredients(updatedIngredients);
        {/*TODO: I DO NEED THIS.. FOR WHEN FIT IT IS PRESSED not sure if there is a need to set ingredients here... this is being called because they have changed not to chnage them*/
        }

        doAnalysis(ings)
    };

    const onIngredientLockedChange = (toLockNow, index, ingredient) => {

        console.log("on ingredient locked changed checkd:" + toLockNow);
        console.log("on ingredient locked changed index:" + index);
        const updatedIngredients = ings.filter((ing) => ing.foodDto.foodId !== ingredient.foodDto.foodId);// removed the passed ingredient from the list
        ingredient.quantityLocked = Boolean(toLockNow);
        console.log("ingredient.quantityLocked:" + ingredient.quantityLocked);

        updatedIngredients.splice(index, 0, ingredient); // add ingredient back in at the same array index
        console.log("updated INGREDIENTS::" + logJSON(updatedIngredients));
        setIngredients(updatedIngredients);

        // don't do analysis upon locked status changing
        // doAnalysis()
    };


    const onUnlockAllIngredients = () => {

        console.log("calling unlock all ings:");

        let newIngs = ings.map((ingredient) => {
            if (ingredient.quantityLocked) ingredient.quantityLocked = Boolean(false)
            console.log("calling unlock on food:" + ingredient.foodDto.foodId + ": " + ingredient.quantityLocked);
            return ingredient
        })
        setIngredients(newIngs);
    };

    const onIngredientBinned = async (ingIndex) => {

        if (ingIndex > -1) {
            ings.splice(ingIndex, 1);
            // setIngredients(ings); // no idea why this still works without this
        }

        let cleanedIngs = sanitiseDisplayOrders(ings);
        let sortedIngs = sortByDisplayOrders(cleanedIngs)
        doAnalysis(sortedIngs)

    }

    const moveIngUpInList = async (ingre) => {


        let newIngs = ings.map((ingredient) => {

            if (ingredient.displayOrder === ingre.displayOrder - 1) {
                // promoting the ingredient to higher in the list
                // move the one that's already higher, to a lower position in the list
                // by adding one to it's position

                ingredient.displayOrder = ingredient.displayOrder + 1
                // then remove one from the current ingredient
                console.log("calling reordering adjacent:" + ingredient.ingredientId + " to order: " + ingredient.displayOrder);

            } else if (ingredient.displayOrder === ingre.displayOrder) {
                if (ingredient.displayOrder > 1) {
                    ingredient.displayOrder = ingre.displayOrder - 1
                }
                console.log("calling reordering:" + ingredient.ingredientId + " to order: " + ingre.displayOrder);

            }

            return ingredient
        })

        sortByDisplayOrders(newIngs)// numbers are set correctly, now actually sort the order according to them
        setIngredients(newIngs);

    }


    const moveIngDownInList = async (ingre) => {


// change the adjacent first
        let tempIngs = ings.map((ingredient) => {

            if (ings.length >= ingredient.displayOrder) {
                if (ingredient.displayOrder === ingre.displayOrder + 1) {
                    // promoting the ingredient to higher in the list
                    // move the one that's already higher, to a lower position in the list
                    // by adding one to it's position
                    ingredient.displayOrder = ingredient.displayOrder - 1
                    // then remove one from the current ingredient
                    console.log("calling reordering adjacent:" + ingredient.ingredientId + " to order: " + ingredient.displayOrder);

                }
            }

            return ingredient
        })


        let newIngs = tempIngs.map((ingredient) => {
            if (ings.length > ingredient.displayOrder) {
                if (ingredient.displayOrder === ingre.displayOrder) {
                    ingredient.displayOrder = ingre.displayOrder + 1
                    console.log("calling reordering:" + ingredient.ingredientId + " to order: " + ingre.displayOrder);

                }
            }
            return ingredient
        })

        sortByDisplayOrders(newIngs)// numbers are set correctly, now actualls sort the order according to them
        setIngredients(newIngs);

    }


    const doFit = async () => {

        doFitting(ings)
    }

    const doEqual = async () => {

        doEqualise(ings)
    }


    useEffect(() => {

        console.log("FULL RECIPE JSON LOADING IN USE EFFECT:\n" + logJSON(recipe));

        console.log("FULL TARGET CHANGED:\n" + logJSON(targetMacros));


    }, [recipe, recipeIsFavorite]);

    let brandNewRecipe = (<>

            <div className="outerDiv">
                <h1>Enter New Recipe</h1>

                <br/>



                <IngredientsList ingreds={ings} onIngredientAdd={doAddIngredient}
                                 onIngredientBinned={onIngredientBinned} onRoundPortionClicked={onRoundPortionChange}
                                 onPortionProportionChange={onPortionChange}
                                 onChangeOfIngredientQuantity={onIngredientQuantityChange}
                                 onChangeOfIngredientLockedStatus={onIngredientLockedChange}
                                 moveIngUpInList={moveIngUpInList}
                                 moveIngDownInList={moveIngDownInList}
                                 errorDataArray={errorMessageData}/>




                <p>

                    <MacroPies targetMacros={targetMacros}
                               setTargetMacros={setTargetMacros}
                               usersTargets={usersTargets}
                               setSavedTarget={setCurrSavedTarget}
                               results={resultMacros}
                               openTransientTargetPopUp={setTransientTargetPopupVisible}/>



                </p>
                <p><ErrorMessage errorCodes={
                    [TUNA_TOTAL_CALS_NOT_POSITIVE,
                        TUNA_FOODS_LIST_EMPTY,
                        TUNA_FOODS_LOCKED_TOO_MANY_CALORIES,
                        TUNA_FOODS_All_LOCKED,
                        TUNA_FOODS_LOCKED_NEGATIVE_QUANTITY,
                        TUNA_FOODS_LABEL_NEGATIVE_NUTRIENT,
                        TUNA_NUTRIENT_PROPORTION_NEGATIVE,
                        TUNA_NUTRIENTS_DONT_ADD_UP_TO_ONE,
                        RECIPE_ING_IN_RECIPE]
                } errorDataArray={errorMessageData}/></p>
                <ButtonPanel callFitIt={doFit}
                             callEqualise={doEqual}
                             onUnlockAll={onUnlockAllIngredients}
                             revertRecipe={doRecipeRefresh}/>

                <table className="mainTable">
                    <tbody>
                    <tr>
                        <FmdTextArea jsonName={"recipeInstructions"} fldNameLabel={"Recipe Instructions"}
                                     errorMessageCodes={[FAVORITE_RECIPE_LIST_BLANK]}
                                     dataAddFunction={setEditableRecFields}
                                     placeholder={"Describe how you made this and what to do with each of the ingredients so that someone can follow it"}
                                     compulsory={true}
                                     errorMessageData={errorMessageData}
                                     numRows={10}/>

                    </tr>
                    <tr>
                        <FmdTextArea jsonName={"recipeSummary"} fldNameLabel={"Search Summary"}
                                     errorMessageCodes={[FAVORITE_RECIPE_LIST_BLANK]}
                                     dataAddFunction={setEditableRecFields}
                                     placeholder={"Enter a short summary that displays when you search for your recipe"}
                                     compulsory={false}
                                     errorMessageData={errorMessageData}
                                     numRows={3}/>

                    </tr>
                    <tr>
                        <FmdInputRow jsonName={"recipeName"} fldNameLabel={"Recipe Name"}
                                     errorMessageCodes={[FAVORITE_RECIPE_LIST_BLANK]}
                                     dataAddFunction={setEditableRecFields}
                                     placeholder={"Enter it's name here"}
                                     compulsory={true}
                                     errorMessageData={errorMessageData}/>

                    </tr>
                    </tbody>
                </table>

                <br/>
                <p style={{textAlign: 'center'}}>
                    <button onClick={() => {
                        saveAllFieldsToNewRecipe();
                    }}>Save New Recipe
                    </button>
                </p>
                <br/>

            </div>
            <br/>
            <br/>

        </>
    );

    let dispUname = "Non-Logged-In-User"
    if (!isUndefined(recipe.createdByUserDto)) {
        dispUname = recipe.createdByUserDto.username
    }
    let existingRecipe = (<>


            <div className="outerDiv">



            <span title={formatDate(recipe.dateCreated) + " at " + formatTime(recipe.dateCreated)}>
            Created {dateWordFormat(recipe.dateCreated)} by {dispUname}
            </span>

                <span style={{float: "right", paddingRight:6}}>
                <FavoriteStar loggedIn={isLoggedIn}
                              onImage={favoriteOn} offImage={favoriteOff} inactiveImage={favoriteDeactivated}
                              onInactiveClick={signInWall}
                              onToggleFavorite={toggleFavorite}
                              recipeFavorite={recipeIsFavorite}
                />


        </span>
                <h1 title={"id:" + recipe.recipeId}>{recipe.recipeName} {recipe.duplicateNum > 1 ? " (" + recipe.duplicateNum + ")" : ""}</h1>

                <p>
                    <ul>

                        <li>Click on 'Fit It!' and the ingredient quantities will change to match your targets (or as close as it can be).</li>
                        <li>See your results update in real time.</li>
                        <li>Enter your ingredients below. Happy Fitting!</li>
                    </ul>
                </p>



                <MacroPies targetMacros={targetMacros}
                           setTargetMacros={setTargetMacros}
                           usersTargets={usersTargets}
                           setSavedTarget={setCurrSavedTarget}
                           results={resultMacros}
                           openTransientTargetPopUp={setTransientTargetPopupVisible}/>


                <p>
                    <ErrorMessage errorCodes={
                        [TUNA_TOTAL_CALS_NOT_POSITIVE,
                            TUNA_FOODS_LIST_EMPTY,
                            TUNA_FOODS_LOCKED_TOO_MANY_CALORIES,
                            TUNA_FOODS_All_LOCKED,
                            TUNA_FOODS_LOCKED_NEGATIVE_QUANTITY,
                            TUNA_FOODS_LABEL_NEGATIVE_NUTRIENT,
                            TUNA_NUTRIENT_PROPORTION_NEGATIVE,
                            TUNA_NUTRIENTS_DONT_ADD_UP_TO_ONE,
                            RECIPE_ING_IN_RECIPE]
                    } errorDataArray={errorMessageData}/>
                </p>
                <ButtonPanel callFitIt={doFit}
                             callEqualise={doEqual}
                             onUnlockAll={onUnlockAllIngredients}
                             revertRecipe={doRecipeRefresh}/>

                <IngredientsList ingreds={ings} onIngredientAdd={doAddIngredient}
                                 onIngredientBinned={onIngredientBinned} onRoundPortionClicked={onRoundPortionChange}
                                 onPortionProportionChange={onPortionChange}
                                 onChangeOfIngredientQuantity={onIngredientQuantityChange}
                                 onChangeOfIngredientLockedStatus={onIngredientLockedChange}
                                 moveIngUpInList={moveIngUpInList}
                                 moveIngDownInList={moveIngDownInList}
                                 errorDataArray={errorMessageData}/>

                <div style={{textAlign: "center"}}>
                    <button onClick={() => {
                        openPopUp()
                    }}>Save My Copy...
                    </button>
                </div>
                <h2 style={{textAlign:"left"}}>Instructions</h2>
                <p>{recipe.recipeInstructions}</p>
                <br/>
                <br/>


            </div>
            <br/>
            <br/>
            <br/>
        </>
    );

    return recipe.recipeId === 0 ? (brandNewRecipe) : (existingRecipe);

};

export default RecipeView;
