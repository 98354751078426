import React from 'react'


export const padLeftZeros = (inputString, length) => {

    let inputLength = inputString.length
    if (inputLength >= length) {
        return inputString;
    }
    let answer = inputString;

    while (answer.length < length) {
        console.log("adding a zero:" + answer);

        answer = "0" + answer;
    }

    return answer;
}

let shiftByKey = [
    323, 348, 359, 213, 197, 109, 349, 301, 219, 213, 274, 152, 318, 300, 296, 291, 345,
    494, 145, 368, 191, 338, 248, 119, 382, 218, 280, 352, 212, 109, 355, 214, 169, 104,
    321, 63, 141, 56, 30, 281, 211, 326, 294, 58, 211, 376, 306, 139, 286, 102, 48, 247,
    347, 232, 383, 170, 135, 337, 5, 92, 315, 359, 371, 327, 81, 177, 243, 228, 236, 95,
    325, 368, 172, 250, 128, 230, 170, 40, 104, 186, 85, 365, 333, 36, 272, 203, 234, 104,
    235, 157, 96, 284, 180, 122, 325, 22, 397, 87, 388, 114];

export const obfuscate = (toObfus) => {

    let answerNumberList = ""

    for (let i = 0; i < toObfus.length; i++) {

        let ascii = toObfus.codePointAt(i); // start on the first character
        let shiftByIndex = i;

        while (shiftByIndex >= shiftByKey.size) {
            shiftByIndex = shiftByIndex - shiftByKey.size + toObfus.length +13; // arbitrary and valid
        }

        let shiftedAscii = ascii + shiftByKey.at(shiftByIndex);
        let hexShifted = shiftedAscii.toString(16);
        hexShifted = padLeftZeros(hexShifted, 3);
        answerNumberList += hexShifted;

    }


    return answerNumberList;

}

export const ranHex = (numOfNums = 16, max = 450) => {
    let answer = "";
    for (let i = 0; i < numOfNums; i++) {
        let ran = Math.floor((Math.random() * max) + 1);
        let hexShifted = ran.toString(16);
        // hexShifted = padLeftZeros(hexShifted, 3);
        answer += hexShifted;

    }

    return answer;
}



