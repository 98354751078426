import React, {useEffect} from 'react'
import displayRewardingPieChart from "./piechart-rewarding.js";
import {random, round} from "lodash";

const PieChartRewardingView = ({
                                   uniqueName,
                                   resultsObj,// pass in what you want it to display at first
                                   targetsObj,
                                   widthPx = 300 // pass in what you want it to display at first

                               }) => {


    const doFirst = () => {

// todo: consider the efficiency of this, and review efficiency of pie chart rewarding. For the rewarding and rewarding one shouldn't i do this in index once only
        console.log("using the effect in pie chart rewarding ratios are " + round(resultsObj.fat), +" " + round(resultsObj.protein) + " " + round(resultsObj.carbs))
        // marry this up to the relevant canvas using canvas id
        displayRewardingPieChart(uniqueName
            , [round(resultsObj.fat), round(resultsObj.protein), 100 -round(resultsObj.fat) - round(resultsObj.protein)]
            , [round(targetsObj.fat), round(targetsObj.protein), 100 -round(targetsObj.fat) - round(targetsObj.protein)]
        )
        // displayRewardingPieChart(uniqueName, [30, 30, 40])

    }


    useEffect(() => {
        console.log("using the effect")
        doFirst();

    }, [resultsObj]);


    return (
        <>

            <div
                style={{textAlign: 'center', width: widthPx, height: widthPx}}>
                <canvas id={uniqueName}>
                    Sorry, our pie chart isn't compatible with your browser, please contact support
                </canvas>
            </div>

        </>
    );


};

export default PieChartRewardingView;




