import React, {useEffect} from 'react'
import NavBar from "../nav-bar/NavBar";
import PiechartSmallView from "./PieChartSmallView";


const PieChartSmallWrapperExample = () => {

    const loadScreen = async () => {


    }

    useEffect(() => {
        loadScreen();
    }, []);


    return (<>
        <NavBar/>
        <PiechartSmallView
            uniqueName={"fistOne"}
            initialPositions={[10, 80, 10]}
        />
        <PiechartSmallView
            uniqueName={"second"}
            initialPositions={[33, 33, 34]}
        />
        <br/>
        <button onClick={() => {
        }}>Save Target
        </button>
        <br/>

    </>);
};

export default PieChartSmallWrapperExample;
