import React, {useEffect} from 'react'
import NavBar from "../nav-bar/NavBar";
import PieChartRewardingView from "./PieChartRewardingView";
import PieChartSmallView from "../piechart-small/PieChartSmallView";


const PieChartRewardingWrapperExample = () => {

    const loadScreen = async () => {


    }

    useEffect(() => {
        loadScreen();
    }, []);


    return (<>
        <NavBar/>


        <table className="mainTable">
            <tbody>
            <tr>
                <td title={"Change the ingredients below to get different results here"}>
                    <PieChartRewardingView
                        uniqueName={"fistOne"}
                        resultsObj={{
                            "fat": 5,
                            "protein": 5,
                            "carbs": 90,
                            "kcals": 500
                        }}
                        widthPx={300}
                    />
                </td>
            </tr>
            </tbody>
        </table>


        <PieChartRewardingView
            uniqueName={"secondOne"}
            resultsObj={{
                "fat": 50,
                "protein": 25,
                "carbs": 50,
                "kcals": 500
            }}
            widthPx={300}
        />
        <br/>
        <button onClick={() => {
        }}>Save Target
        </button>
        <br/>

    </>);
};

export default PieChartRewardingWrapperExample;
