import React from 'react'
import logo from '../../img/logo.png'
import RecipeSearch from "../auto-suggestion-Lists/RecipeSearch";
import NavBar from "../nav-bar/NavBar";


const HomeSearchScreen = () => {

    return(<>
        <NavBar/>
        <div className={"outerDiv"} style={{textAlign: "center", alignContent:"center"}}>
            <img alt="logo" width={280} src={logo} style={{marginBottom: "10px"}}/>
            <br/><br/>ALPHA TESTING RELEASE<br/><br/>
            <RecipeSearch/>
            <div style={{height: 40}}>
                &nbsp;
            </div>
        </div>

    </>
    )

};

export default HomeSearchScreen;
