import React from 'react'

const ButtonPanel = ({callFitIt, callEqualise, onUnlockAll, revertRecipe}) => {

    return (<table className="mainTable">
            <tr style={{textAlign:"center"}}>
                <td colSpan={3} style= {{border:"none"}}>
                    <button onClick={() => {
                        callFitIt()
                    }}>Fit It!
                    </button>
                </td>
            </tr>
            <tr>
                <td style= {{border:"none"}}>
                    <button onClick={() => {
                        revertRecipe()
                    }}>Revert
                    </button>
                </td>
                <td style= {{border:"none"}}>
                    <button onClick={() => {
                        onUnlockAll()
                    }}>Unlock All
                    </button>
                </td>
                <td style= {{border:"none"}}>
                    <button onClick={() => {
                        callEqualise()
                    }}>Equalise
                    </button>
                </td>
            </tr>
        </table>
    );
};

export default ButtonPanel;
