import React from 'react'
import {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";
import queryString from 'query-string';
import SearchResultsView from "./SearchResultsView";
import {restGet} from "../../utils/RestUtils"
import NavBar from "../nav-bar/NavBar";

const SearchResultsRestWrapper = () => {
  const location = useLocation();

    const [recipeListing, setRecipeListing] = useState({
        loading: true,
        data: null,
        error: null
    });

    const fetchSearchListing = async(keyword) => {

        // p page number, s size of that page
        let json = await restGet(`/fmd/recipes/detailed?p=1&s=12&keyword=${keyword}`)

        setRecipeListing({
            loading: false,
            data : json,
            error: false
        });
    }

  useEffect( () => {

    const queryParams = queryString.parse(location.search);
    const keyword = queryParams.search;

      {/* pass in the last part of the uri (after the domain part, and the state method to use for setting the state*/}
    fetchSearchListing(keyword)

  }, [location.search]);{/* when this changes, do the useEffect again*/}

  return (
      <>
      <NavBar/>
    <SearchResultsView
      results={recipeListing}
    />
      </>
  );
};

export default SearchResultsRestWrapper;
