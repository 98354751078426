import React, {useEffect} from 'react'
import NavBar from "../nav-bar/NavBar";
import PieChartDraggableView from "../piechart-draggable/PieChartDraggableView";
import {FmdInputRow} from "../../utils/FmdInputRow";
import {logJSON} from "../../utils/FormattingUtils";
import {restPost} from "../../utils/RestUtils";

const TargetCreatePopUp = ({

                               saveTarget,
                               closePopUp,
                           }) => {

    const topText = <><b>Adjust your targets (optional)</b><br/></>;
    const middleText = <><br/><b>% calories (kcals) from each nutrient</b><br/><br/></>;
    const bottomText = <><br/><p id={"piechart-instructions"}>Drag the
        slices <b> or </b> click the <b>&#43;</b>/<b>&#8722;</b> buttons <br/></p><br/></>

    let fatVal = null;
    let proVal = null;
    let carbVal = null;

    const setFatz = async (fat) => {

        fatVal = fat;

    }
    const setProteinz = async (pro) => {

        proVal = pro
    }
    const setCarbsz = async (car) => {
        carbVal = car


    }
    useEffect(() => {

        window.location.hash = "#topOfPage"

    }, []);

    return (
        <div className="popupstyle">
            <div className="popupstyle_content">
          <span className="closePopupX" onClick={(e) => {
              closePopUp()
          }}>
            &times;
          </span>

                Create it here:


                <PieChartDraggableView
                    initialPositions={[33, 33, 34]}
                    fatSetCallback={setFatz}
                    proteinSetCallback={setProteinz}
                    carbSetCallback={setCarbsz}
                    topText={topText}
                    middleText={middleText}
                    bottomText={bottomText}
                />
                <br/>
                <button onClick={() => {
                    saveTarget(fatVal, proVal, carbVal)
                }}>Save Target
                </button>
                <br/>


            </div>
        </div>
    );
};

export default TargetCreatePopUp;
