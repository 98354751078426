import React from 'react'
import {isEmpty, isUndefined} from "lodash";
import {Link} from "react-router-dom";
import bin from "../../img/bin.png";
import favoriteOn from '../../img/favoriteOn.png';

import {FmdInputRow} from "../../utils/FmdInputRow";
import {FAVORITE_RECIPE_LIST_BLANK} from "../../Validation/ErrorConstants";

const FavoritesView = ({
                           currentFavItems,
                           allUsersList,
                           changeListToView,
                           onRemoveItemFromFavorites,
                           onReasignItem,
                           onAddFavoritesFolder,
                           setNewListName,
                           onRemoveFavoritesFolder,
                           errorMessageData
                       }) => {






    let optionTemplate = null;
    {
        console.log("all users here " + JSON.stringify(allUsersList))
    }
    if (!isEmpty(allUsersList)) {
        optionTemplate = allUsersList.favoritesFolderList.map(v => (
            (currentFavItems.favoritesFolderId === v.favoritesFolderId) ? (null) : (
                <option value={v.favoritesFolderId}>{v.favoritesFolderName}</option>
            )))
    }


    return (
        <>
            <div className="outerDiv">
                <h1>My Favorites</h1>
               <p>
                Organise your starred favorites into folders here.
                   </p>
                <table className="mainTable">
                    <tbody>
                    <tr>
                        <FmdInputRow jsonName={"favoritesFolderName"} fldNameLabel={"New Folder"}
                                     errorMessageCodes={[FAVORITE_RECIPE_LIST_BLANK]}
                                     dataAddFunction={setNewListName}
                                     placeholder={"Enter New Folder Name"}
                                     compulsory={false}
                                     errorMessageData={errorMessageData}/>
                    </tr>

                    <tr style={{textAlign: "center"}}>
                        <td colSpan={2} style={{border: "none"}}>
                            <button onClick={() => {
                                onAddFavoritesFolder()
                            }}>Add Folder
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>



                            <h3><b>My Folders</b></h3>
                <table className="mainTable">
                    {
                        !isUndefined(allUsersList.favoritesFolderList) ? (


                            allUsersList.favoritesFolderList.sort(function (a, b) {
                                return a.favoritesFolderId - b.favoritesFolderId
                            }).map((thisList, itemInd) => (  // sorted so last goes in at the top!


                                <tr>





                                    { currentFavItems.favoritesFolderId === thisList.favoritesFolderId ? (
                                    <td className={"highlighted-cell"} style={{minWidth: 150, textAlign:"right", paddingLeft:5, paddingRight:5}}

                                    >

                                        <Link >{


                                                <b>➡{thisList.favoritesFolderName}</b>

                                        }</Link>
                                    </td>

                                    ) : (<>



                                    <td style={{minWidth: 150, textAlign:"right", cursor:"pointer", paddingLeft:5, paddingRight:5}}
                                        onClick={() => {
                                            changeListToView(thisList.favoritesFolderId)
                                        }}
                                    >
                                        <Link >{


                                            <b>{thisList.favoritesFolderName}</b>

                                        }</Link>&nbsp;&nbsp;
                                    </td>
                                   </>)}













                                    {thisList.listType !== 10 ? (
                                    <td style={{display: "table-cell", verticalAlign:"bottom", border:"none", height:28
                                    }} onMouseDown={(e) => {
                                             onRemoveFavoritesFolder(thisList.favoritesFolderId)
                                         }}>

                                            <img className={"round-tappable-image"} src={bin} title="remove folder"
                                                 alt="remove folder" />

                                    </td>) : (<td  style={{border:"none", height:28}}>&nbsp;</td>)
                                    }
                                </tr>
                            ))
                        ) : (<></>)

                    }

                </table>
                <br/>

                <h3><b>{currentFavItems.favoritesFolderName}</b></h3>
                    {

                        !isUndefined(currentFavItems.favoriteRecipeItemsList) && !isEmpty(currentFavItems.favoriteRecipeItemsList) ? (



                                <table className="mainTable">



                                    {
                            currentFavItems.favoriteRecipeItemsList.sort(function (a, b) {
                                return b.favoriteRecipeItemId - a.favoriteRecipeItemId
                            }).map((favInList, itemInd) => (  // reverse so last in goest to the top.. should sort really!
                                <tr>
                                    <td className={"highlighted-cell"} style={{paddingLeft:5, paddingRight:5}}>
                                        <Link to={"/recipes/" + favInList.recipeDto.recipeId}>
                                            <b>
                                            {favInList.recipeDto.recipeName} {favInList.recipeDto.duplicateNum > 1 ? " (" + favInList.recipeDto.duplicateNum + ")" : ""}
                                            </b>
                                        </Link>
                                    </td>
                                    <td style={{border:"none"}}>

                                        <select value={currentFavItems.favoritesFolderId}
                                                onChange={(e) => {
                                                    onReasignItem(favInList, e.target.value)
                                                }}>
                                            <option>{"Move to.."}</option>
                                            {optionTemplate}
                                        </select>
                                    </td>
                                    <td style={{display: "table-cell", verticalAlign:"bottom", border:"none"}}>

                                        <img className={"round-tappable-image"} src={bin} alt="remove from favorites"
                                             onClick={(e) => {
                                                 onRemoveItemFromFavorites(currentFavItems.favoritesFolderId, itemInd)
                                             }}
                                        />


                                    </td>
                                </tr>

                            ))}

                                </table>  ) : (<>
                            <div className="mainTable" style={{textAlign: 'center'}}>

                                <div style={{textAlign: 'center', color: '#990033'}}>
The {currentFavItems.favoritesFolderName} folder is empty.<br/>
                                </div>
                                {currentFavItems.listType !== 10 ? (<>Add favorites by moving them from the 'Unassigned' folder.</>
                                ) : (<>Star <img style={{height:30, paddingTop:12, paddingBottom:0}} src={favoriteOn}/> your favorite recipes and they will appear here.</>)
                                }

                            </div>
                        </>)

                    }

                <br/>
                <br/>
            </div>
            <br/>
            <br/>

        </>
    );
};

export default FavoritesView;
