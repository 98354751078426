import React, {useEffect, useState} from 'react'
import {useParams} from "react-router";
import {restDelete, restGet, restPost} from "../../utils/RestUtils";
import RecipeView from "./RecipeView";
import {isUndefined} from "lodash";
import {logJSON, privateSupportEmail} from "../../utils/FormattingUtils";
import NavBar from "../nav-bar/NavBar";
import * as Const from "../../Validation/ErrorConstants";

import {
    addSingleErrorToScreen,
    blankErrorMessagesOnScreen,
    getServerSideErrorMessage
} from "../../Validation/ErrorValidation";
import {getLoginItem, getVisitorId, isLoggedIn} from "../../utils/LoginUtils";
import TransientTargetPopup from "./TransientTargetPopup";
import RecipeModifyPopUp from "./tuna-container/RecipeModifyPopUp";


const RecipeRestWrapper = () => {

        const {recid} = useParams();
        const [errorMessageData, setErrorMessageData] = useState([]);
        const [recipeIsFavorite, setRecipeIsFavorite] = useState(-1);// negative means not favorite

        const [recipePopupVisible, setRecipePopupVisible] = useState(false);
        const [transientTargetPopup, setTransientTargetPopupVisible] = useState(false);


        const [editableRecFields, setEditableRecFields] = useState({
            recipeName: null,
            recipeSummary: null,
            recipeInstructions: null
        });

        const [recipe, setRecipeDetails] = useState(
            {
                loading: true,
                data: null,
                error: false
            });


        // a subset of recipe is fed into this as a default, then used as the main thing that gets modified
        const [ings, setIngredients] = useState([]);

        const [targetMacros, setTargetMacros] = useState({
            fat: 33,
            protein: 33,
            carbs: 34,
            kcals: 500
        });

        const [resultMacros, setResultMacros] = useState({
            // identical to target! For purpose of when adding brand-new recipe without any ingredients at the start
            fat: 0,
            protein: 0,
            carbs: 0,
            kcals: 100,
            kcalsStated: 100
        });


        /*
        Below for when the user is logged in only
         */
        const [favorites, setFavorites] = useState();
        const [targets, setTargets] = useState();


        const openPopUp = () => {
            console.log("openpop");


            // initialise the state on the fields (they'll change upon onChange on each field)
            setEditableRecipeFields({
                "recipeName": recipe.data.recipeName,
                "recipeSummary": recipe.data.recipeSummary,
                "recipeInstructions": recipe.data.recipeInstructions
            })

            setRecipePopupVisible(true)
        }

        const closePopUp = () => {
            console.log("closepop");
            setRecipePopupVisible(false)
            setTransientTargetPopupVisible(false)

            doAnalysis(ings) // so results / reward refreshes as it might not now be dead on. For efficiency, could just re-set the results to themselves and pass them in. Reanalysis is easier though.
        }
        const ingredientsToTunaRequestItems = async (ingrees) => {

            let ingJsonResult = "[";
            //    console.log("ingredients converting to requestitems" + JSON.stringify(ings));

            ingrees.map((ing, index) => {
                ingJsonResult += `${(index !== 0) ? "," : ""}{"foodId":"${ing.foodDto.foodId}","quantity":"${ing.quantity}","locked":"${ing.quantityLocked}"}`
            })

            ingJsonResult += "]";
            //  console.log("unencoded ingredients json result:" + ingJsonResult)
            return ingJsonResult;
        }

// TODO: just remove this
        const refreshTheLot = async () => {

            // setRefreshFlag(intRefreshedTimes); // arbitrary change. the change is detected by useEffect array and causes a re render of the lot.
            // RecipeRestWrapper();

            await fetchFullRecipe(recid);
        }

        const saveAllFieldsToNewRecipe = async () => {

            console.log("ATTEMPTING A SAVE OF:\n" + logJSON(editableRecFields))
            // rejig the whole recipe: part.  It should go in to this as recipe: to begin with.
            let registeredUserDto = null;
            if (isLoggedIn()){
                registeredUserDto = {"registeredUserId" : getLoginItem("re")}

            }


            let saveObj = await restPost("/fmd/recipes", {
                "currentRecipeId": recipe.data.recipeId,
                "ingredientsList": ings,
                "createdByUser": registeredUserDto,
                "createdByVisitorId" : getVisitorId(),
                "recipeName": editableRecFields.recipeName,
                "recipeSummary": editableRecFields.recipeSummary,
                "recipeInstructions": editableRecFields.recipeInstructions
            }            )
            //   await setRecipeDetails({
            //     loading: false,
            //   data: recipe,
            // error: false
            // })
            //await setIngredients(ings);
            //  await doAnalysis(ings);
            console.log("the new ID is in this form: " + JSON.stringify(saveObj));
            console.log("full saveobj: " + JSON.stringify(saveObj));

            let rId = await saveObj.newRecId;
            closePopUp();
            navToRecipe(await rId);

        }

        const setCurSavedTarget = async (json) => {

            let percArr = await targetProfileToNutsPercent(json)

            await setTargetMacros({
                ...targetMacros,
                fat: percArr[0],
                protein: percArr[1],
                carbs: percArr[2]

            });

            console.log("target macros set now " + percArr[0] + ", " + percArr[1] + ", " + percArr[2]);

        }

        const setEditableRecipeFields = async (json) => {

            console.log("updating rec thing:\n" + logJSON(json));

            await setEditableRecFields({
                ...editableRecFields,
                ...json
            });

            console.log("all the rec thing:\n" + logJSON(editableRecFields));

        }

        const fetchFullRecipe = async (recid, analyse = true) => {
            let jsonRec = await restGet(`/fmd/recipes/extended/${recid}`);
            console.log('done recipe rest call for recipe: ' + recid);

            // let jsonAnal = await restCall("/fmd/tuna/analysed?i=" + tunaReqIng)
            // console.log("Full JSON from analysis: \n" + JSON.stringify(json));
            //console.log("Setting result: fat: " + json.fatPercentage);
            //console.log("Setting result: protein: " + json.proteinPercentage);
            //console.log("Setting result: carbs: " + json.carbsPercentage);

            await setRecipeDetails({
                loading: false,
                data: jsonRec,
                error: false
            })

            let cleanedIngs = sanitiseDisplayOrders(jsonRec.ingredientsList);
            let sortedIngs = sortByDisplayOrders(cleanedIngs)

            if (analyse) {// the first load of the starter recipe (id 0) does not do an analysis, because we don't want errors on first load
                await doAnalysis(sortedIngs);
            }
            await setIngredients(sortedIngs);

            return jsonRec
        }

        const createNewIngredientFromFood = (foodJson) => {
            console.log("foodJson= " + logJSON(foodJson));
            console.log("nutonlist= " + logJSON(foodJson.nutrientOnFoodLabelsList));
            let nutrientQuantitiesPer100g = {};
            {
                foodJson.nutrientOnFoodLabelsList.map((nut) => {
                    let nutNameInCapitals = nut.nutrientDto.nutrientCode;
                    let nutVal = nut.valuePer100g
                    if (!isUndefined(nutNameInCapitals) && nutNameInCapitals != null) {
                        nutrientQuantitiesPer100g[nutNameInCapitals] = nutVal;
                    }
                })
            }
            let recipeId = recipe.data.recipeId;
            let newIngredientJson = {

                "quantityLocked": false,
                "kcalPer100g": foodJson.kcalPer100g,
                "quantity": 10,
                "displayOrder": -1,
                "ingredientId": null,
                "recipeDto": {recipeId},
                "foodDto": foodJson,
                "identifier": foodJson.foodId,
                "nutrientQuantitiesPer100g": nutrientQuantitiesPer100g

            }
            console.log("newly formed ingredient" + logJSON(newIngredientJson));
            return newIngredientJson;

        }

        const doFoodAddRestCall = async (foodId) => {
            return await restGet(`/fmd/foods/detailed/${foodId}`);
        }

        const sortByDisplayOrders = (dodgyIngs) => {

            dodgyIngs.sort(function (a, b) {
                return a.displayOrder - b.displayOrder
            });
            return dodgyIngs;
        }

        //puts an order number into the new non ordered items
        const sanitiseDisplayOrders = (dodgyIngs) => {

            for (let i = 0; i < dodgyIngs.length; i++) {
                console.log("sanitising num " + i);
                dodgyIngs[i].displayOrder = i + 1;
                console.log("dodgyIngs assigning displayOrder:" + dodgyIngs[i].foodDto.foodName + " " + i);
            }

            return dodgyIngs;
        }


        const addIngredientToRecipeListing = async (foodId) => {

            // questionable global ing here??
            let ingThatsAlreadyThere = ings.filter((ing) => ing.foodDto.foodId == foodId);
            console.log("result of filter " + logJSON(ingThatsAlreadyThere))
            blankErrorMessagesOnScreen(setErrorMessageData)
            // not already there
            if (ingThatsAlreadyThere != null && ingThatsAlreadyThere.length === 0.0) {
                let foodFromDbJson = await doFoodAddRestCall(foodId);
                let fullIngredientToAdd = createNewIngredientFromFood(foodFromDbJson);
                console.log("unshifting " + foodId);
                ings.unshift(fullIngredientToAdd);
                console.log("full on recipe with sweetcorn= " + logJSON(ings));

                // TODO:  order ingredients correctly here to ingredients here.


                let cleanedIngs = sanitiseDisplayOrders(ings);
                let sortedIngs = sortByDisplayOrders(cleanedIngs)

                setIngredients(sortedIngs);

                // tunaReqIng = ingredientsToTunaRequestItems(ings)
                doAnalysis(sortedIngs);
            } else {

                await addSingleErrorToScreen(Const.RECIPE_ING_IN_RECIPE, "Ingredient already exists in your recipe", setErrorMessageData)
                //  setErrorMessageData({"errorPosition" : {Const.RECIPE_MAIN_ERR}, message: "Ingredient already exists in your recipe"});
                // the item is already there, consider an error message. Make an error message placeholder using a state object
            }
        }


        const doAnalysis = async (ingys) => {

            let tunaReqIng = await ingredientsToTunaRequestItems(ingys)

            let json = await restGet("/fmd/tuna/analysed?i=" + tunaReqIng)
            let error = getServerSideErrorMessage(json);

            if (error != null) {
                await addSingleErrorToScreen(json.messageCode, error, setErrorMessageData)
                // setErrorMessageData({"errorPosition" : json.errorCode, "message": error}) // for error in different positions
                //  setErrorMessageData({"errorPosition" : {Const.RECIPE_MAIN_ERR}, "message": error}) // hard coded for error in same position regardless of the error
            } else {
                await blankErrorMessagesOnScreen(setErrorMessageData)
                await setResultMacros({
                    fat: json.fatPercentage,
                    protein: json.proteinPercentage,
                    carbs: json.carbsPercentage,
                    kcals: json.derivedCalories,
                    kcalsStated: json.statedCaloriesLessAccurate
                });
            }

        }


        const doEqualise = async (ingys) => {
            let tunaReqIng = await ingredientsToTunaRequestItems(ingys)
            console.log("going for a tuna equalise with:" + tunaReqIng);
            let json = await restGet(`/fmd/tuna/equalised?&k=${targetMacros.kcals}&i=${tunaReqIng}`)
            console.log("the equalised json foods: \n" + logJSON(json.foods));
            let newQuantIngs = await fitOrderedIngredientsWithTunaResult(json.foods)

            let error = getServerSideErrorMessage(json);
            if (error != null) {
                await addSingleErrorToScreen(json.messageCode, error, setErrorMessageData)
                //  setErrorMessageData({"errorPosition" : {Const.RECIPE_MAIN_ERR}, "message": error})
            } else {
                blankErrorMessagesOnScreen(setErrorMessageData)
                setResultMacros({
                    fat: json.fatPercentage,
                    protein: json.proteinPercentage,
                    carbs: json.carbsPercentage,
                    kcals: json.derivedCalories,
                    kcalsStated: json.statedCaloriesLessAccurate
                });
                /*
                            I think it would be way better just to set the quantities of the ingredients correctly in the existing ings...
                            The alternative is to overwrite the lot, the displayOrder would disappear as it's neither int he db or required for tuna... so it gets lost' +
                            It's more robust to just set the quantity here, since it means if more fields are added to ingredient it won't affect the tuna call... less side effect.s more surgical.
                */

                setIngredients(newQuantIngs);
            }
        };


        const fitOrderedIngredientsWithTunaResult = async (ingsFromTunaResult) => {
            let answerIngs = ings;
            if (ingsFromTunaResult != null) {
                for (let i = 0; i < ingsFromTunaResult.length; i++) {

                    // ingsFromTunaResult have null Ingredient Ids because they're not all necessarily in the database.  They all have food Ids though
                    // this selection step is pure defensive programming, this method necessarily relies on ORDER to set quantities,
                    // potentially fragile.  We could use ingredient ID to match them up, but newly added ones won't have it.
                    // we check where we can.
                    if (ingsFromTunaResult[i].foodDto.foodId === answerIngs[i].foodDto.foodId) {
                        // all we change in a tuna call is the quantity. There may be results etc dealt with elsewehre,
                        // but all that is CHANGED in the ings is quantity.
                        answerIngs[i].quantity = ingsFromTunaResult[i].quantity;
                        if (!isUndefined(ingsFromTunaResult[i].foodDto.portionQuantity)) {
                            console.log("propx: portion quantity is there: " + ingsFromTunaResult[i].foodDto.portionQuantity);
                            console.log("propx: quantity is there: " + ingsFromTunaResult[i].quantity);
                            // Fixes a bug where once you edit proportion the proportion no longer changes upon fitting. Before fitting it's fine. Odd... the below resolves this.
                            let prop = Number(ingsFromTunaResult[i].quantity / ingsFromTunaResult[i].foodDto.portionQuantity); // add in new entry
                            console.log("propx: proportion is :" + prop);
                            answerIngs[i].portionProportion = prop;

                        }

                        console.log("setting ingredient to: " + ingsFromTunaResult[i].quantity + " on ingredient " + ingsFromTunaResult[i].ingredientId + " display order from tuna :" + ingsFromTunaResult[i].displayOrder);
                        console.log("current answerIngs quantity: " + answerIngs[i].quantity);
                    } else {
                        console.error("ingredients must be ordered before ingredients are set on them")
                        alert("Our apologies.\nThere has been an internal error.\n Please let support know if possible\n" +
                            "\nERROR: ingredients must be ordered  in fitOrderedIngredientsWithTunaResult before quantities are set on them\")\n");
                    }

                }
            }

            return answerIngs;

        };

        const fetchTargets = async (userId) => {

            // TODO: refactor this maybe? does this do anything at this stage?
            let responseJson = await restGet(`/fmd/registeredusers/${userId}/target-profiles/`);
            let errorText = getServerSideErrorMessage(responseJson);

            if (errorText != null) {
                await addSingleErrorToScreen(responseJson.messageCode, errorText, setErrorMessageData) // hard coded for error in same position regardless of the error
            } else {

                await setTargets(responseJson.targetProfilesList)
            }
            return await responseJson;

        }

        const fetchFavorites = async (userId) => {

            console.log("starting fetching favs")

            let responseJson = await restGet(`/fmd/registeredusers/${userId}/favorites-folder/`);
            let errorText = getServerSideErrorMessage(responseJson);

            if (errorText != null) {
                //setErrorMessageData({"errorPosition" : json.errorCode, "message": error}) // for error in different positions
                await addSingleErrorToScreen(responseJson.messageCode, errorText, setErrorMessageData) // hard coded for error in same position regardless of the error
            } else {
                await setFavorites(responseJson)
                logJSON("response from get: " + responseJson);
            }

            await calculateRecipeFavouriteness(responseJson)
            return responseJson;
        }


        const doFitting = async (ingys) => {

            let tunaReqIng = await ingredientsToTunaRequestItems(ingys)
            console.log("going for a tuna fitting with:" + tunaReqIng);

            let json = await restGet(`/fmd/tuna/fitted?r=${recipe.data.recipeId}&f=${targetMacros.fat}&p=${targetMacros.protein}&c=${targetMacros.carbs}&k=${targetMacros.kcals}&i=${tunaReqIng}`)
            let error = getServerSideErrorMessage(json);


            if (error != null) {
                await addSingleErrorToScreen(json.messageCode, error, setErrorMessageData)
            } else {

                let correctQuantIngs = await fitOrderedIngredientsWithTunaResult(json.foods);

                blankErrorMessagesOnScreen(setErrorMessageData)
                console.log("the fitted json foods: \n" + logJSON(json.foods));

                setResultMacros({
                    fat: json.fatPercentage,
                    protein: json.proteinPercentage,
                    carbs: json.carbsPercentage,
                    kcals: json.derivedCalories,
                    kcalsStated: json.statedCaloriesLessAccurate
                });

                // the json that comes back from fitting is extensive, the ingredients are under the json "foods"
                // not ingredientsList as they would be from the database
                setIngredients(correctQuantIngs);
            }
        };

        const navToRecipe = (recId) => {
            window.location = "/recipes/" + recId;

        }

// checkout here and get to be congruent with a new request on the webservice that doesn't use a dto... the favorite recipe list id is unknown at this stage'
        const addRecipeToFavorites = async () => {

            let favItemId = await restPost("/fmd/favorite-recipe-item/", {
                "recipeId": recid,
                "registeredUserId": getLoginItem("re")
            });

            setRecipeIsFavorite(favItemId);// -1 means not favorite


        }

        const deleteItemFromFavorites = async () => {


            await restDelete(`/fmd/favorite-recipe-item/${recipeIsFavorite}`); // no need to await, it's async


            setRecipeIsFavorite(-1);// -1 means not favorite


        }

        const toggleFavorite = async (recId) => {
            let fav = recipeIsFavorite;

            if (fav > -1) {
                console.log("recipeIsFavorite was true, so deleting it from favorites")

                await deleteItemFromFavorites();
                //setRecipeIsFavorite(null)

            } else if (!fav > -1) {
                console.log("recipeIsFavorite was false, so addid it to favorites in the uncategorized list")

                await addRecipeToFavorites();
                // setRecipeIsFavorite(null)

            } else {
                console.error("recipeIsFavorite is not getting set, maybe it's still a promise when a boolean is expected")
            }

            // TODO: this will probably cause a bug.... all the ingredients that have been added but not saved will disappear at this point
            // we need to localise the whole favorites part better

            // need to decide on requirement at this moment... should this effectively save it?
            doFirst();// database has changed, best to refresh the lot... not the best solution... just need to refresh the favorites part
        }


        const targetProfileToNutsPercent = async (targ) => {

            console.log("target profile to nutPercent is here " + JSON.stringify(targ));

            let answer = [-1, -1, -1];
            if ((targ) != null) {
                for (let i = 0; i < targ.targetNutrientProportionsList.length; i++) {
                    let nutProportion = targ.targetNutrientProportionsList[i];
                    let nutId = nutProportion.nutrientDto.nutrientId

                    if (nutId == 10) { // unchecked type there because one is str one is num for some reason
                        answer[0] = nutProportion.percentage
                    } else if (nutId == 20) {
                        answer[1] = nutProportion.percentage
                    } else if (nutId == 30) {
                        answer[2] = nutProportion.percentage
                    }

                    if (answer[0] > 0 && answer[1] > 0 && answer[2] > 0) {
                        break;// we have it.
                    }

                }


            }


            console.log("nut percent answer is " + JSON.stringify(answer));

            return answer;

        }
        const calculateRecipeFavouriteness = async (favs) => {

            let answer = -1;

            console.log("favorites: " + JSON.stringify(favs))

            if (await (favs) != null) {
                for (let i = 0; i < favs.favoritesFolderList.length; i++) {
                    let list = favs.favoritesFolderList[i].favoriteRecipeItemsList
                    for (let j = 0; j < list.length; j++) {
                        let favItem = list[j];

                        console.log("does" + favItem.recipeDto.recipeId + "equal" + recid)

                        if (favItem.recipeDto.recipeId == recid) { // unchecked type there because one is str one is num for some reason
                            console.log("this guy is in your favourite list!!!!!!!!!!!")

                            answer = favItem.favoriteRecipeItemId; // useful for updating and deleting later
                            break;
                        }
                    }
                    if (answer >= 0)
                        break;// we have the id.
                }
            }
            console.log("favorites: calculated whether favorite is returning: " + answer)
            setRecipeIsFavorite(answer)
        }


        const doFirst = async () => {

            let analyse = true;
            if (recid == 0) {
                analyse = false; // recipe zero is the starter recipe for a brand new recipe, no errors when refreshed
            }
            let recJson = await fetchFullRecipe(recid, analyse)

            if (isLoggedIn()) {
                let rand = await fetchFavorites(getLoginItem("re"))
                let rand2 = await fetchTargets(getLoginItem("re"))
            }
        }

        useEffect(() => {
            doFirst()

        }, []);
        {/* when this changes, do the useEffect again*/
        }

        return recipe.loading ? (
            <p>Loading...</p>
        ) : recipe.error ? (
            <p>We're sorry it hasn't worked this time, please try again.
                <br/>If this problem persists, please contact {privateSupportEmail()}
            </p>
        ) : (ings != null && recipe.data) ? (
            <>
                {transientTargetPopup ?
                    <TransientTargetPopup
                        targetMacros={targetMacros}
                        updateTarget={setTargetMacros}
                        closePopUp={closePopUp}
                        errorMessageData={errorMessageData}
                    /> : null}

                {recipePopupVisible ?
                    <><RecipeModifyPopUp closePopUp={closePopUp} setEditableRecFields={setEditableRecFields}
                                         editableFields = {editableRecFields}
                                       currentRecipe={recipe.data}
                                       saveAllFieldsToNewRecipe={saveAllFieldsToNewRecipe}/> </>: null}
                <NavBar/>
                <RecipeView
                    recipe={recipe.data} setRecipeDetail={setRecipeDetails}
                    ings={ings} setIngredients={setIngredients}
                    setTargetMacros={setTargetMacros}
                    targetMacros={targetMacros} setCurrSavedTarget={setCurSavedTarget}
                    resultMacros={resultMacros} setResultMacros={setResultMacros}
                    errorMessageData={errorMessageData}
                    doAnalysis={doAnalysis}
                    doFitting={doFitting}
                    doEqualise={doEqualise}
                    doAddIngredient={addIngredientToRecipeListing}
                    doRecipeRefresh={refreshTheLot}
                    setEditableRecFields={setEditableRecipeFields}
                    saveAllFieldsToNewRecipe={saveAllFieldsToNewRecipe}
                    closePopUp={closePopUp}
                    openPopUp={openPopUp}
                    sanitiseDisplayOrders={sanitiseDisplayOrders}
                    sortByDisplayOrders={sortByDisplayOrders}
                    recipeIsFavorite={recipeIsFavorite}
                    usersTargets={targets}
                    toggleFavorite={toggleFavorite}
                    setTransientTargetPopupVisible={setTransientTargetPopupVisible}
                />
                {
                   /* JSON.stringify(editableRecFields)*/
                }
            </>
        ) : (
            <p>Loading Ingredients
            </p>
        );
    }
;

export default RecipeRestWrapper;
