import {isUndefined, trim} from "lodash";
import {privateSupportEmail} from "../utils/FormattingUtils";
import React from "react";
import * as Const from "./ErrorConstants";
import {
    FMD_FOOD_NUTS_DONT_ADD,
    TUNA_FOODS_All_LOCKED,
    TUNA_FOODS_LABEL_NEGATIVE_NUTRIENT,
    TUNA_FOODS_LIST_EMPTY,
    TUNA_FOODS_LOCKED_NEGATIVE_QUANTITY,
    TUNA_FOODS_LOCKED_TOO_MANY_CALORIES,
    TUNA_NUTRIENT_PROPORTION_NEGATIVE,
    TUNA_NUTRIENTS_DONT_ADD_UP_TO_ONE,
    TUNA_TOTAL_CALS_NOT_POSITIVE
} from "./ErrorConstants";
import {use} from "express/lib/router";

const regexMatches = (varMayBeDodgy, regex, canBeUndefined) => {

    let answer = true;
    if (!isBlank(varMayBeDodgy)) {
        let ans = varMayBeDodgy.search(regex)
        if (ans < 0) {
            answer = false;
        }
    } else if (!canBeUndefined) { // it's blank, but shouldn't be
        answer = false;
    }

    return answer;
}

export const isBlank = (varMayBeAbsent) => {

    let blank = false;
    if (isUndefined(varMayBeAbsent) || varMayBeAbsent === null || varMayBeAbsent.length < 1 || trim(varMayBeAbsent).length < 1)
        blank = true;

    console.log("varMayBeAbsent is blank:" + blank + "it is " + varMayBeAbsent)
    return blank;
}


export const isValidString = (varMayBeDodgy, canBeBlank = false) => {


    // Start string ^ then alpha numeric ranges.
    // Then escaped dash and \s meaning whitespace, then each allowable symbol,
    // then + or  * for one or more or zero or more respectively, then $ means end string
    let notBlankRegex = /^[a-zA-Z0-9\-\s,()!:.]+$/;
    let canBeBlankRegex = /^[a-zA-Z0-9\-\s,()!:.]*$/;

    let regToUse = notBlankRegex;
    if (canBeBlank) {
        regToUse = canBeBlankRegex;
    }

    return regexMatches(varMayBeDodgy, regToUse, canBeBlank)
}


export const isValidPassword = (varMayBeDodgy) => {

    let canBeBlankRegex = /^[a-zA-Z0-9\-,()!:.]+$/;

    return regexMatches(varMayBeDodgy, canBeBlankRegex, false)
}

export const isValidEmail = (varMayBeDodgy) => {
    return regexMatches(varMayBeDodgy, /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, false)
}

export const isValidNumber = (varMayBeDodgy, canBeBlank = false) => {

    let notBlankRegex = /^[0-9.]+$/;
    let canBeBlankRegex = /^[0-9.]*$/; // could replace with nan and a check for negative

    let regToUse = notBlankRegex;
    if (canBeBlank) {
        regToUse = canBeBlankRegex;
    }

    return regexMatches(varMayBeDodgy, regToUse, canBeBlank)

}

/*
Client side food validation
 */
export const foodIsValid = async (foodDto, setErrorData) => {
    let isValid = true;
    let newErrArr = []; // array to store error code / messages


    if (isUndefined(foodDto) || foodDto == null) {
        newErrArr.push({
            "positionCode": Const.FOOD_NEW_ALL_BLANK, // to match what's in the ErrorMessage
            "message": "Please enter the info about the food you'd like to add"
        })

        await addMultipleErrorsToScreen(newErrArr, setErrorData);
        return isValid; // if foodDto is null, just return
    }

    if (isBlank(foodDto.foodName)) {
        isValid = false;
        newErrArr.push({
            "positionCode": Const.FOOD_NAME_ERR, // to match what's in the ErrorMessage
            "message": "Please enter the name of your food"
        })
    }

    if (!isValidString(foodDto.foodDescription, true)) {
        isValid = false;
        newErrArr.push({
            "positionCode": Const.FOOD_DESC_ERR, // to match what's in the ErrorMessage
            "message": "Please enter a description for your food without unusual punctuation, or leave it blank"
        })
    }

    let specPorCanBeBlank = true;
    if (!isBlank(foodDto.specificPortionSingular) || !isBlank(foodDto.specificPortionAmount)) {
        specPorCanBeBlank = false // because if you fill in one, you have to fill in the other
    }

    if (!specPorCanBeBlank) {
        if (!isValidString(foodDto.specificPortionSingular, false)) {
            isValid = false;
            newErrArr.push({
                "positionCode": Const.FOOD_PORT_NAME_ERR, // to match what's in the ErrorMessage
                "message": "Please enter a name for a convenient portion or leave both this and the amount blank "
            })
        }

        if (!isValidNumber(foodDto.specificPortionAmount, false)) {
            isValid = false;
            newErrArr.push({
                "positionCode": Const.FOOD_PORT_AMOUNT_ERR, // to match what's in the ErrorMessage
                "message": "Please enter a valid number for the portion amount"
            })
        }
    }

    if (!isValidNumber(foodDto.kcalPer100g, false)) {
        isValid = false;
        newErrArr.push({
            "positionCode": Const.FOOD_KCALS, // to match what's in the ErrorMessage
            "message": "Please check that this number is correct (0 is ok if there are no kcals in your food)"
        })
    }

    let carbVal = 0;
    let carbSug = 0;
    for (let i = 0; i < foodDto.nutrientOnFoodLabelsList.length; i++) {
        let nutonLab = foodDto.nutrientOnFoodLabelsList[i];
        let nut = nutonLab.nutrientDto;


        if (!isValidNumber(nutonLab.valuePer100g, false)) {
            isValid = false;
            newErrArr.push({
                "positionCode": Const.FOOD_NUT_ERR_ + nut.nutrientId, // to match what's in the ErrorMessage
                "message": "Please check that this number is correct and not blank (enter '0' if it's not on your label)"
            })
        }


        if (nut.nutrientId == 35) {
            carbSug = nutonLab.valuePer100g
        } else if (nut.nutrientId == 30) {
            carbVal = nutonLab.valuePer100g
        }

    }

    console.log("carb n sug carbVal: " + carbVal)
    console.log("carb n sug carbSug: " + carbSug)

    if (!isNaN(carbVal) && !isNaN(carbSug) && Number(carbVal) < Number(carbSug)) {

        console.log("carb n sug need an error: ")

        isValid = false;
        newErrArr.push({
            "positionCode": Const.FOOD_NUT_ERR_ + "35", // to match what's in the ErrorMessage
            "message": "Please check that carbs from sugar is not more than the total carbs"
        })
    }

    if (!isValid)
        await addMultipleErrorsToScreen(newErrArr, setErrorData);

    return isValid;
}


/*
Client side recipe validation
 */
export const recipeIsValid = async (recData, setErrorData) => {
    let isValid = true;
    let newErrArr = []; // array to store error code / messages

    for (let i = 0; i < recData.ingredientList.length; i++) {
        let ing = recData.ingredientList[i];
        let fd = ing.foodDto;

// this is belt and braces... it's not possible to put a non numeric or negative character in these fields in fact
        if (!isValidNumber(ing.kcalPer100g, false)) {
            isValid = false;
            newErrArr.push({
                "positionCode": Const.RECIPE_UNDER_FOOD_ENTRY_ + fd.foodId, // to match what's in the ErrorMessage
                "message": "amount must be a valid number zero or more"
            })
        }
    }


    if (!isValid)
        await addMultipleErrorsToScreen(newErrArr, setErrorData);

    return isValid;
}


/*
Client side validation
 */
export const loginIsValid = async (loginData, setErrorData) => {
    let isValid = true;
    let newErrArr = []; // array to store error code / messages

    // this is belt and braces... it's not possible to put a non numeric or negative character in these fields in fact
    if (isBlank(loginData.username)) {
        isValid = false;
        newErrArr.push({
            "positionCode": Const.LOGIN_UNAME_BLANK, // to match what's in the ErrorMessage
            "message": "Please enter the Username you used to sign up"
        })
    }

    if (isBlank(loginData.np)) {
        isValid = false;
        newErrArr.push({
            "positionCode": Const.LOGIN_PASSWORD_BLANK, // to match what's in the ErrorMessage
            "message": "Please enter the password you chose when you signed up"
        })
    }

// password dealt with client side, they can pass anthing at all through, maybe need some validation to avoid hacking??
    if (!isValid)
        await addMultipleErrorsToScreen(newErrArr, setErrorData);

    return isValid;
}


export const usernameValid = (username) => {
    let isValid = true;
    if (isBlank(username) || username.length < 3) {
        isValid = false;
    }

    return isValid
}

export const favoritesListIsValid = async (favoriteRecipeDto, setErrorData) => {

        let isValid = true;
        let newErrArr = []; // array to store error code / messages

        // this is belt and braces... it's not possible to put a non numeric or negative character in these fields in fact
        if (isBlank(favoriteRecipeDto.favoritesFolderName)) {
            isValid = false;
            newErrArr.push({
                "positionCode": Const.FAVORITE_RECIPE_LIST_BLANK, // to match what's in the ErrorMessage
                "message": "Please enter a name for your new Favorites Folder"
            })
        }

    // password dealt with client side, they can pass anthing at all through, maybe need some validation to avoid hacking??
        if (!isValid)
            await addMultipleErrorsToScreen(newErrArr, setErrorData);

        return isValid;
    }

/*
Client side validation
 */
export const registrationIsValid = async (regData, setErrorData) => {
    let isValid = true;
    let newErrArr = []; // array to store error code / messages
    await blankErrorMessagesOnScreen(setErrorData)

    // this is belt and braces... it's not possible to put a non numeric or negative character in these fields in fact
    if (!usernameValid(regData.username)) {
        isValid = false;
        newErrArr.push({
            "positionCode": Const.REG_UNAME_INVALID, // to match what's in the ErrorMessage
            "message": "Please enter a Username over 3 characters long"
        })
    }

    if (!isValidEmail(regData.email)) {
        isValid = false;
        newErrArr.push({
            "positionCode": Const.REG_EMAIL_INVALID, // to match what's in the ErrorMessage
            "message": "Please enter a valid email address"
        })
    }


    if (!isValidPassword(regData.np) || regData.np.length < 8 * 3) { // 3 char for each after obfs
        isValid = false;
        newErrArr.push({
            "positionCode": Const.REG_PASSWORD_INVALID, // to match what's in the ErrorMessage
            "message": "Please ensure your password is over 8 characters long. It can contain letters and numbers (no spaces) and the following symbols:  -,()!:."
        })
    }

    if (!(isBlank(regData.np) && isBlank(regData.npc))) { // if they're not both blank
        if (regData.np !== regData.npc) {
            isValid = false;
            newErrArr.push({
                "positionCode": Const.REG_PASSWORD_CONF, // to match what's in the ErrorMessage
                "message": "Your password does not match your confirmation"
            })
        }
    }

    if (!isValid)
        await addMultipleErrorsToScreen(newErrArr, setErrorData);

    return isValid;
}


/*
Server side validation all follows the same pattern, so whatever is being validated at the server side, it's sorted here.
TuNA, Food's, Recipe's or any other entities all use this for the server side
 */
export const getServerSideErrorMessage = (responseJson) => {

    let answer = null;
    if (!isUndefined(responseJson.errorType) && responseJson.errorType != null) {

        if (responseJson.errorType === "TunaUserInputException" || responseJson.errorType === "FmdUserInputException" || responseJson.errorType === "FmdUserCredentialsException") {

            switch (responseJson.messageCode) {

                case TUNA_FOODS_LIST_EMPTY:
                    answer = "Please add some ingredients to your recipe";
                    break;
                case TUNA_FOODS_LOCKED_TOO_MANY_CALORIES:
                    answer = "The calories in your locked foods amount to more than your calorie (kcal) target. \nPlease unlock some foods or reduce your target kcals";
                    break;
                case TUNA_FOODS_All_LOCKED:
                    answer = "You have locked in all of the foods in this meal.  Please unlock as many as you can for the calculation to work";
                    break;
                case TUNA_TOTAL_CALS_NOT_POSITIVE:
                    answer = "Total calories (kcals) target can't be zero or less. Please make it a number above zero";
                    break;
                case TUNA_FOODS_LOCKED_NEGATIVE_QUANTITY:
                    answer = "Negative quantities seem to be locked into the recipe. Please make all amounts 0 grams or more";
                    break;
                case TUNA_FOODS_LABEL_NEGATIVE_NUTRIENT:
                    answer = "There seems to be an invalid food in this recipe that is reporting a negative amount of one of it's nutrients";
                    break;
                case TUNA_NUTRIENT_PROPORTION_NEGATIVE:
                    answer = "Please use positive numbers for the nutrient targets";
                    break;
                case TUNA_NUTRIENTS_DONT_ADD_UP_TO_ONE:
                    answer = responseJson.fullMessage; // good error from the server with this one
                    break;
                case Const.FMD_FOOD_NUTS_DONT_ADD: // non tuna error
                    answer = responseJson.fullMessage;
                    break;
                case Const.FMD_LOGIN_CREDENTIALS_DONT_MATCH: // non tuna error
                    answer = answer = "The Username or Password you entered isn't recognised";
                    break;
                case Const.FMD_REG_USERNAME_IN_USE: // non tuna error
                    answer = "This Username is already taken";
                    break;

                default:
                    answer = responseJson.fullMessage;
            }
        } else {
            // There's a nasty, none user input error, probably need to do a full-on forward to a different screen here
            answer = "We're really sorry it hasn't worked this time, please try again later. \n" +
                "If this persists, please contact: \n " + privateSupportEmail() +
                " and give us the below information so we can fix this problem.\n\nTYPE:" +
                responseJson.errorType + <br/> + "\nCODE:" + responseJson.errorCode +
                <br/> + "\nMESSAGE:" + responseJson.fullMessage;
        }
    }
    return answer;
}

/*
Used for server side error if it occurs, there can only be one UserInput error at a time
 */
export const addSingleErrorToScreen = async (positionCode, message, setErrorData) => {

    let newErrorDatum = {"positionCode": positionCode, "message": message}
    let newErrArray = []
    newErrArray.push(newErrorDatum)
    await addMultipleErrorsToScreen(newErrArray, setErrorData) // good reuse, don't be put off by the name
}

/*
Used usually for client side errors, of which there can be many at once.
 */
export const addMultipleErrorsToScreen = async (errorsJson, setErrorData) => {
    await setErrorData(errorsJson);
}

export const blankErrorMessagesOnScreen = async (setErrorData) => {
    await setErrorData(null);
}

