import React, {useEffect, useState} from 'react'
import UserLoginView from "./UserLoginView";
import {restPost} from "../../utils/RestUtils";
import NavBar from "../nav-bar/NavBar";
import {
    addSingleErrorToScreen,
    blankErrorMessagesOnScreen,
    getServerSideErrorMessage,
    loginIsValid
} from "../../Validation/ErrorValidation";
import {isUndefined} from "lodash";
import {getLoginItem, saveLoginDataToSession} from "../../utils/LoginUtils";
import UserLoginConfirmationPopup from "./UserLoginConfirmationPopup";


const UserLoginWrapper = () => {

    const [loginData, setLoginData] = useState();
    const [errorMessageData, setErrorMessageData] = useState([]);
    const [loginConfirmationVisible, setLoginConfirmationVisible] = useState(false);

    const addLoginData = async (json) => {
        await setLoginData({
            ...loginData,
            ...json
        });

    }

    const closePopUp = () => {
        console.log("closepop");
        setLoginConfirmationVisible(false)
        window.location = "/"; // TODO: refactor these out at some point prob, just use state

    }

    const submitLoginData = async () => {

        let loginDto;

        if (isUndefined(loginData)) {
            loginDto = {}; // to avoid null pointers and weird behaviour if loginDto isn't even in the json (which it isn't at first)
        } else {
            loginDto = loginData;
        }

        let loginValid = await loginIsValid(loginDto, setErrorMessageData);// client side validation

        if (loginValid) {

            // on registration, not on login
            //  loginDto.vuid=localStorage.getItem("vuid")
            // It doesn't do a pemanent change, but this info needs to be put into the body, so it's a post
            let responseJson = await restPost("/fmd/registeredusers/login/", {loginDto});

            let errorText = getServerSideErrorMessage(responseJson);

            if (errorText != null) {
                //setErrorMessageData({"errorPosition" : json.errorCode, "message": error}) // for error in different positions
                await addSingleErrorToScreen(responseJson.messageCode, errorText, setErrorMessageData) // hard coded for error in same position regardless of the error
            } else {
                await blankErrorMessagesOnScreen(setErrorMessageData)

                //JWT will be needed for this
                saveLoginDataToSession(responseJson)

                setLoginConfirmationVisible(true)
                setLoginData(null)

            }

        }

    }


    const doFirst = async () => {

    }

    useEffect(() => {

        doFirst();


    }, []);


    return (<>

            {loginConfirmationVisible === true ?
                <UserLoginConfirmationPopup
                    userName={getLoginItem("us")}
                    closePopUp={closePopUp}
                /> : null}

            <NavBar/>
            <UserLoginView
                addLoginData={addLoginData}
                submitLoginData={submitLoginData}
                errorMessageData={errorMessageData}
            />
            <br/>{
                /*JSON.stringify(loginData)*/
        }
        </>
    );
};

export default UserLoginWrapper;
