import React, {useEffect} from 'react'
import NavBar from "../nav-bar/NavBar";
import PieChartDraggableView from "./PieChartDraggableView";


const PieChartDraggableWrapperExample = () => {

    const fatSetCallback = async (fatVal) => {
        //probably put this into targets

        console.log("fat callback is now:" + fatVal)
    }
    const proteinSetCallback = async (proteinVal) => {
        //probably put this into targets
        console.log("protein callback is now:" + proteinVal)

    }
    const carbSetCallback = async (carbVal) => {
        //probably put this into targets
        console.log("carbs callback is now:" + carbVal)

    }

    const loadScreen = async () => {


    }

    useEffect(() => {
        loadScreen();
    }, []);

    const topText = <><b>Adjust your targets (optional)</b><br/></>;
    const middleText = <><br/><b>% calories (kcals) from each nutrient</b><br/><br/></>;
    const bottomText = <><br/><p id={"piechart-instructions"}>Drag the slices <b> or </b> click
        the <b>&#43;</b>/<b>&#8722;</b> buttons <br/></p><br/></>

    return (<>
        <NavBar/>
        <PieChartDraggableView
            initialPositions={[10, 80, 10]}
            fatSetCallback={fatSetCallback}
            proteinSetCallback={proteinSetCallback}
            carbSetCallback={carbSetCallback}
            topText={topText}
            middleText={middleText}
            bottomText={bottomText}
        />
        <br/>
        <button onClick={() => {
        }}>Save Target
        </button>
        <br/>

    </>);
};

export default PieChartDraggableWrapperExample;
