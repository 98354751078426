import React from 'react'
import NavBar from "../nav-bar/NavBar";
import {privateSupportEmail} from "../../utils/FormattingUtils";


const AboutView = ({}) => {

    return (
        <>
            <NavBar/>
            <div className="outerDiv">
                <h1>About Us</h1>
                <br/>
                <p style={{textAlign:"center"}}>

                    Although the application is in its early stages and this section is far from complete, it's a good chance to quickly say thank you to a few helpful pals now that the site is live. <br/><br/>
                    <ul>
                    <li>To <b>Riaan Reiter</b>, the initial inspiration.</li>
                        <li>To <b>Steve Atkinson</b>, for tinkering and testing.</li>
                            <li>And last but not certainly not least, to <b>George O'Toole</b>: <br/>Aside from your helpful technical knowledge,  thanks for helping me stay connected, motivated and sane throughout.</li>
                    </ul>
                        <br/>As you see, there isn't much to this section at the moment, but the site is changing quickly, so check back soon!<br/><br/>
                    If you need to get in touch, contact us at:<br/>
                    <a href={"mailto:" + privateSupportEmail()}>{privateSupportEmail()}</a>

                </p>

                <br/>

            </div>
        </>
    );
};

export default AboutView;




