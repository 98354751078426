import React from "react";



export const TUNA_TOTAL_CALS_NOT_POSITIVE= "TUNA_TOTAL_CALS_NOT_POSITIVE";
    export const  TUNA_FOODS_LIST_EMPTY= "TUNA_FOODS_LIST_EMPTY";
    export const TUNA_FOODS_LOCKED_TOO_MANY_CALORIES= "TUNA_FOODS_LOCKED_TOO_MANY_CALORIES";
    export const  TUNA_FOODS_All_LOCKED= "TUNA_FOODS_All_LOCKED";
    export const TUNA_FOODS_LOCKED_NEGATIVE_QUANTITY= "TUNA_FOODS_LOCKED_NEGATIVE_QUANTITY";
    export const  TUNA_FOODS_LABEL_NEGATIVE_NUTRIENT= "TUNA_FOODS_LABEL_NEGATIVE_NUTRIENT";
    export const  TUNA_NUTRIENT_PROPORTION_NEGATIVE= "TUNA_NUTRIENT_PROPORTION_NEGATIVE";
    export const  TUNA_NUTRIENTS_DONT_ADD_UP_TO_ONE= "TUNA_NUTRIENTS_DONT_ADD_UP_TO_ONE";

// Constants to marry where in the ErrorValidation the error is calculated, to where in the ErrorMessage tag it is displayed
// Food
export const FOOD_NEW_ALL_BLANK = "FOOD_NEW_ALL_BLANK";
export const FMD_FOOD_NUTS_DONT_ADD = "FMD_FOOD_NUTS_DONT_ADD";
export const FOOD_NAME_ERR = "FOOD_NAME_ERR";
export const FOOD_DESC_ERR = "FOOD_DESC_ERR";
export const FOOD_PORT_NAME_ERR = "FOOD_PORT_NAME_ERR";
export const FOOD_PORT_AMOUNT_ERR = "FOOD_PORT_AMOUNT_ERR";
export const FOOD_KCALS = "FOOD_KCALS";
export const FOOD_NUT_ERR_ = "FOOD_NUT_ERR_"; // First bit before the nutrient Id.

//Recipe
export const RECIPE_ING_IN_RECIPE = "RECIPE_ING_IN_RECIPE";
export const RECIPE_UNDER_FOOD_ENTRY_ = "RECIPE_UNDER_FOOD_ENTRY_";

//Login
export const LOGIN_PASSWORD_BLANK = "LOGIN_PASSWORD_BLANK";
export const LOGIN_UNAME_BLANK = "LOGIN_UNAME_BLANK";
export const FMD_LOGIN_CREDENTIALS_DONT_MATCH = "FMD_LOGIN_CREDENTIALS_DONT_MATCH";

//Registration
export const REG_UNAME_INVALID = "REG_UNAME_INVALID";
export const REG_PASSWORD_INVALID = "REG_PASSWORD_INVALID";
export const FMD_REG_USERNAME_IN_USE = "FMD_REG_USERNAME_IN_USE";
export const REG_EMAIL_INVALID = "REG_EMAIL_INVALID";
export const REG_PASSWORD_CONF = "REG_PASSWORD_CONF";

//Favorites
export const FAVORITE_RECIPE_LIST_BLANK = "FAVORITE_RECIPE_LIST_BLANK";
