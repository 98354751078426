import React, {useEffect} from 'react'

const UserRegistrationConfirmationPopup = ({
                                        userName,
                                        closePopUp
                                    }) => {


    useEffect(() => {
        window.location.hash = "#topOfPage"

    }, []);


    return (
        <div className="popupstyle">
            <div className="popupstyle_content">
          <span className="closePopupX" onClick={(e) => {
              // same action here as clicking save!

              closePopUp();
          }}>
            &times;
          </span>
                <br/>
                <br/>
                <h2>Welcome {userName},</h2><br/>
                You are signed up and logged in to Fit My Diet.<br/>
                You can now save your targets, save your favorite recipes, categorize
                your favorites and stay organised.<br/>

                <br/>
                Welcome to the club, we're happy to have your here.

                <br/>
                <br/>
                <button onClick={() => {
                    closePopUp()
                }}>Continue
                </button>

            </div>
        </div>
    );
};

export default UserRegistrationConfirmationPopup;
