import React from 'react'
import {isUndefined} from "lodash";
import {isBlank} from "./ErrorValidation";


export const errorExists = (errorPosition, errorDataArray) => {

    let hasError = false;
    if (!isUndefined(errorDataArray) && errorDataArray != null) {
        for (let i = 0; i < errorDataArray.length; i++) {
            if (errorDataArray[i].positionCode === errorPosition) {
                hasError = true;
                break
            }
        }
    }
    return hasError;
};


export const styleIfError = (errorMessageCodes, errorDataArray, errorStyle = "inputerror", nonErrorStyle = "") => {

    let hasError = false;
    if (!isUndefined(errorDataArray) && errorDataArray != null) {
        for (let i = 0; i < errorDataArray.length; i++) {
            for (let j = 0; j < errorMessageCodes.length; j++) {
                if (errorDataArray[i].positionCode === errorMessageCodes[j]) {
                    hasError = true;
                    break;
                }
            }
        }
    }

    if (hasError) {
        return errorStyle;
    }
    return nonErrorStyle;
};

const ErrorMessage = ({errorCodes, errorDataArray}) => {

    let message = ""
    console.log("current errors:" + JSON.stringify(errorDataArray))
    console.log("current possible codes here:" + JSON.stringify(errorCodes))

    if (!isUndefined(errorDataArray) && errorDataArray != null) {

        console.log("1:")


        for (let i = 0; i < errorDataArray.length; i++) {

            for (let j = 0; j < errorCodes.length; j++) {
                if (errorDataArray[i].positionCode === errorCodes[j]) {


                    if (!isBlank(message)) {//messages on different lines
                        message += "\n"; // due to styling "pre-line" in errormessage css, the newline here is respected as if a <br>
                    }
                    message += errorDataArray[i].message

                    if (!isBlank(message)) {
                        message = <span>&nbsp;•&nbsp;{message}&nbsp;</span>
                    }

                    console.log("err mess:" + message)
                }
            }
        }
    }
    return (<span className="errormessage">{message}</span>);
};

export default ErrorMessage;
