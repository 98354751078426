import React, {useEffect, useState} from 'react'
import {getLoginItem, isLoggedIn} from "../../../utils/LoginUtils";
import PieChartRewardingView from "../../piechart-rewarding/PieChartRewardingView";
import PieChartSmallView from "../../piechart-small/PieChartSmallView";
import {highlightOnFocus, logJSON, numFormat} from "../../../utils/FormattingUtils";


const MacroPies = ({targetMacros, usersTargets, results, setSavedTarget, setTargetMacros, openTransientTargetPopUp}) => {

    const [targetModifyPopup, setTargetModifyPopup] = useState(false);

    const [optionsState, setOptionsState] = useState(-1);

    const closePopUp = () => {
        console.log("closepop");
        setTargetModifyPopup(false)
    }

    const getPercentFromPropList = (nutDbId, nutPropList) => {
        // order of these from the database can't be guaranteed
        for (let i = 0; i < nutPropList.length; i++) {
            if (nutDbId === nutPropList[i].nutrientDto.nutrientId) {
                console.log("returning " + nutPropList[i].percentage)
                return nutPropList[i].percentage;
            }
        }
    }


    const fetchDefaultForThisUser = async () => {
//TODO
        return null

    }


    const doFirst = async () => {

        console.log("targets pro" + JSON.stringify(targetMacros))
        console.log("results pro" + JSON.stringify(results))

        if (isLoggedIn()) {
            let regUser = getLoginItem("re");
            // setCurrentTarget(await fetchDefaultForThisUser(regUser))
        }

    }


    useEffect(() => {
console.log("refreshing Macro Pies seemingly targets in MacroPies" + logJSON(targetMacros));
    doFirst();
    }, [targetMacros, results]);

    return (
        <div>

            <table className="mainTable">
                <tbody>

                <tr>
                    <td style={{textAlign: "center", border: "1"}}>TARGET</td>

                    <td colSpan="2" style={{textAlign: "center", border: "0"}}>&nbsp;</td>

                    <td style={{textAlign: "center", border: "1"}}>ACHIEVED</td>





                </tr>
                <tr>

                    <td rowSpan="8" style={{textAlign: "center", cursor: "pointer"}} onClick={(e) => {
                        setOptionsState(-1)
                        openTransientTargetPopUp(true)
                    }}>

                        <PieChartSmallView uniqueName={"targetsPie"}
                                           widthPx={120}
                                           fat={targetMacros.fat}
                                           protein={targetMacros.protein}
                                           carbs={targetMacros.carbs}
                        />


                    </td>
                    <td colSpan={2} style={{fontSize: "18px", backgroundColor: '#80a1d9', textAlign: "center"}}>FAT</td>
                    <td rowSpan="8" style={{minWidth: "122px"}} title={"Change the ingredients below to get different results here"}>


                        {(results.fat + results.protein + results.carbs > 99) ? (
                            <>
                                <PieChartRewardingView
                                    uniqueName={"resultsRewardingPie"}
                                    resultsObj={results}
                                    targetsObj={targetMacros}
                                    widthPx={120}
                                />

                            </>
                        ) : ( <p style={{textAlign:'center'}} >No Results Yet</p>)}




                    </td>

                </tr>
                <tr>
                    <td style={{
                        width: "43px",
                        fontSize: "18px",
                        backgroundColor: '#80a1d9'
                    }}>{numFormat(targetMacros.fat, 0)}<span style={{fontSize: '8px', lineHeight: '0px'}}>%</span></td>
                    <td style={{width: "43px", fontSize: "18px", backgroundColor: '#80a1d9', textAlign: "right"}}
                        title={numFormat(results.fat - targetMacros.fat, 0, false, true) + " diff. (" + results.fat + ")"}>{numFormat(results.fat, 0)}<span
                        style={{fontSize: '8px', lineHeight: '0px'}}>%</span></td>
                </tr>
                <tr>
                    <td colSpan={2}
                        style={{fontSize: "18px", backgroundColor: '#cc6a55', textAlign: "center"}}>PROTEIN
                    </td>
                </tr>
                <tr>
                    <td style={{
                        fontSize: "18px",
                        backgroundColor: '#cc6a55'
                    }}>{numFormat(targetMacros.protein, 0)}<span style={{fontSize: '8px', lineHeight: '0px'}}>%</span>
                    </td>
                    <td style={{fontSize: "18px", backgroundColor: '#cc6a55', textAlign: "right"}}
                        title={numFormat(results.protein - targetMacros.protein, 0, false, true) + " diff. (" + results.protein + ")"}>{numFormat(results.protein, 0)}<span
                        style={{fontSize: '8px', lineHeight: '0px'}}>%</span></td>
                </tr>
                <tr>
                    <td colSpan={2} style={{fontSize: "18px", backgroundColor: '#f8e48a', textAlign: "center"}}>CARBS
                    </td>
                </tr>
                <tr>
                    <td style={{fontSize: "18px", backgroundColor: '#f8e48a'}}>{numFormat(targetMacros.carbs, 0)}<span
                        style={{fontSize: '8px', lineHeight: '0px'}}>%</span></td>
                    <td style={{fontSize: "18px", backgroundColor: '#f8e48a', textAlign: "right"}}
                        title={numFormat(results.carbs - targetMacros.carbs, 0, false, true) + " diff. (" + results.carbs + ")"}>{numFormat(results.carbs, 0)}<span
                        style={{fontSize: '8px', lineHeight: '0px'}}>%</span></td>
                </tr>
                <tr>
                    <td colSpan={2} title={"(calories)"}
                        style={{fontSize: "18px", backgroundColor: '#CCCCCC', textAlign: "center"}}>KCALS
                    </td>
                </tr>
                <tr>
                    <td style={{backgroundColor: '#CCCCCC'}}>
                        <input
                            value={targetMacros.kcals}
                            style={{width: "33px"}}
                            onFocus={(e) => {
                                highlightOnFocus(e)
                            }}
                            onChange={(e) => {
                                setTargetMacros({...targetMacros,
                                    "kcals": e.target.value})
                            }}/>
                    </td>
                    <td style={{fontSize: "18px", backgroundColor: '#CCCCCC', textAlign: "right"}}
                        title={numFormat(results.kcals - targetMacros.kcals, 0, false, true) + " diff. (" + results.kcals + ")"}>{numFormat(results.kcals, 0)}<span
                        style={{fontSize: '8px', lineHeight: '0px'}}></span></td>
                </tr>



                        {usersTargets ?
                            (   <tr>
                                <td style={{textAlign: "center", border: "4"}} colSpan={3}>&nbsp;

                                    <select  value={optionsState}

                                             onChange={(e) => {setOptionsState(e.target.value)}}

                                    >
                                    <option value="-1" disabled>Saved Targets...</option>
                                    {usersTargets.map(
                                        (targetDto, index) => (
                                            <>
                                                <option value={targetDto.targetProfileId}
                                                        onClick={() => {
                                                            setSavedTarget(targetDto) //The transient target gets saved appropriately within this function
                                                        }}
                                                >{targetDto.profileName}</option>
                                            </>
                                        )
                                    )

                                    }
                                    <option  onClick={() => {
                                        window.location = "/targets";


                                    }}>Add Saved Target...</option>
                                </select>

                                </td>
                                <td style={{textAlign: "center", border: "4"}}>⬇ FIT TO CHANGE</td>
                            </tr> )
                            :
                            (<tr><td style={{textAlign: "center", border: "4"}}>TOUCH TO CHANGE</td><td colSpan="2" style={{textAlign: "center", border: "0"}}>&nbsp;</td>
                                <td style={{textAlign: "center", border: "4"}}>⬇ FIT TO CHANGE</td></tr>)}

                </tbody>
            </table>


        </div>
    );


}

export default MacroPies;
