// classList - shows/gets all classes
// contains - checks classList for specific class
// add - add class
// remove - remove class
// toggle - toggles class

// Toggle visible or not when clicking on burger
export default function initNavListeners() {

    const links = document.querySelector(".nav-links");
    const body = document.querySelector("html");
    let burgeri = document.querySelector("#burgeri");

    // Height of links dropdown is different dependent on whether you're logged in or not.
    // theres a burgeri id on the logged in nav bar, and a burgeri-loggedin id if they're logged in
    // that's how you tell which height to make the drop down
    // (the height is in the show-nav-dropdown / show-nav-dropdown -loggedin classes
    let giveHeightToElement = "show-nav-dropdown"
    if (burgeri == null) {
        burgeri = document.querySelector("#burgeri-loggedin");
        giveHeightToElement = "show-nav-dropdown-loggedin"
    }

    body.addEventListener("click", function (e) {

        console.log("target...  id:" + e.target.id + "  class:" + e.target.classname + "  nodeName:" + e.target.nodeName)

        // Change height of links menu (therefore visibility) and color of the burger
        if (e.target.id === "burgeri" || e.target.id === "burgeri-loggedin") {
            links.classList.toggle(giveHeightToElement);
            burgeri.classList.toggle("color-burger-grey");
            console.log("toggling nav menu")
        } else {
            console.log("closing nav menu")
            links.classList.remove(giveHeightToElement); // click anywhere else and close it
            burgeri.classList.remove("color-burger-grey");
        }

    });

}