import React, {useEffect} from 'react'
import {Link} from "react-router-dom";




const FoodCreatePopUp = ({closePopUp, doFirst}) => {
    useEffect(() => {
        window.location.hash = "#topOfPage"

    }, []);
   return (
        <div className="popupstyle">
            <div className="popupstyle_content">
                <h3>Your food has been added</h3>
                      It can now be selected from the drop-down menu and added to a recipe<br/>

                <div style={{textAlign: "center"}}>
                <Link to={"/"}>
                    <button>Go to Recipe Search</button>
                </Link>
                    <button onClick={(e) => {
                        closePopUp();
                        window.location.href='/create-food'
                    }}>Add another food</button>
                </div>
            </div>
        </div>
    );
 };

export default FoodCreatePopUp;
