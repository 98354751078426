import React, {useEffect} from 'react'
import {useState} from 'react'
import {Link, useHistory} from "react-router-dom"
import {restGet} from "../../utils/RestUtils";
import {highlightOnFocus, makeNonBreaking, numToWord, restrictCharsBySplit} from "../../utils/FormattingUtils";
import {isBlank} from "../../Validation/ErrorValidation";

const RecipeSearch = () => {
    const [searchTerm, setSearchTerm] = useState('Enter your favourite meal');
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
    const history = useHistory();
    let autoSuggestBoxesLen = 0; // array index of total number of suggenstion boxes... so we know where to stop highlighting the next box
    let activeBoxEntityId = null;  // entity id , e.g. recipeId or foodId
    let charsBeforeSearch = 2;
    const itemsInSearch = 7;

    const [recipeAutoSuggestions, setRecipeAutoSuggestions] = useState({
        loading: true,
        data: null,
        error: null
    });
    const onSearch = (activeBoxIndex) => {

        let url;
        if (activeBoxIndex == -1) {
            const uriSearch = encodeURI(searchTerm);
            url = `/results?search=${uriSearch}`; // === '/results?auto-suggestion-Lists=' + auto-suggestion-Lists
            ;
        } else {

            const recipeIdForUrl = encodeURI(activeBoxEntityId);
            url = `/recipes/${recipeIdForUrl}`;
        }

        history.push(url)
    };


    const onKeyPress = (e) => {

        // alert(e.keyCode);
        switch (e.keyCode) {
            case 37://left
                break;
            case 38://up
                if (activeSuggestionIndex > -1) {// don't want to go past the top
                    setActiveSuggestionIndex(activeSuggestionIndex - 1)
                }

                // stops caret scrolling ot start of word in main input upon pressing up arrow
                e.preventDefault()
                break;
            case 39://right
                break;
            case 40: //down
                if (autoSuggestBoxesLen > activeSuggestionIndex) {
                    setActiveSuggestionIndex(activeSuggestionIndex + 1);
                }
                break;
            case 13://enter
                onSearch(activeSuggestionIndex);
                break;
        }


    };


    const AutoSuggestionListComponent = (props) => {


        let subtext = <div className="autosuggestionplaceholder">
            Type {numToWord(charsBeforeSearch)} letters for suggestions...
        </div>
        if (props.recipeAutoSuggestions.data != null && props.recipeAutoSuggestions.data.totalElements === 0
            && searchTerm.length != null && searchTerm.length >= charsBeforeSearch) {
            return (<>{subtext}
                <div className="autosuggestiondiv" style={{top: "35px"}}>
                <span className="autosuggestionerrorbox">
                    {makeNonBreaking("No Recipes Match. ")}<Link
                    onMouseDown={(e) => {
                        window.location = "/recipes/0";
                    }}
                    to="/recipes/0" style={{fontWeight: "normal"}}
                    className="autosuggestionerrorboxlink">{makeNonBreaking("Add Here")}</Link>
                </span></div>
            </>);
        } else {
            if (props.recipeAutoSuggestions.data != null) {
                return (<>{subtext}
                    <div className="autosuggestiondiv" style={{top: "35px"}}>
                        {props.recipeAutoSuggestions.data.itemsOnThisPage.map((rec, ind) => {
                            // Flag the active suggestion with a class
                            autoSuggestBoxesLen = ind;
                            console.log("activeFoodSuggestionIndex: " + activeSuggestionIndex);
                            let className = "autosuggestionbox";
                            if (ind === activeSuggestionIndex) { //active suggestion index is some kind of global flag set elsewhere to say which is highlighted
                                className = "activeautosuggestion"; // this is a css thing to highlight it
                                activeBoxEntityId = rec.recipeId; // now we know where to forward to when return is pressed.
                            }
                            let foodNameInDropdown = makeNonBreaking(rec.recipeName);
                            foodNameInDropdown = restrictCharsBySplit(foodNameInDropdown, 54, -5)
                            let substuff = makeNonBreaking(rec.duplicateNum > 1 ? " " + rec.duplicateNum + "" : "");
                            return (
                                <span key={rec.recipeId} className={className}
                                      onMouseDown={() => correctlyForward(rec.recipeId)}>
                                {foodNameInDropdown}<span
                                    style={{fontSize: '12px', lineHeight: '0px'}}>{substuff}</span>
                                <br/></span>
                            );
                        })}

                    </div>
                </>)
            } else {
                return subtext
                // not enough chars entered for any suggestion yet, no error, nothing needed
            }

        }
    };

    const correctlyForward = (recId) => {
        console.log("INTERCEPTED ON CLICK OR ON MOUSE DOWN")
        window.location = "/recipes/" + recId;

    }

    const blankResults = () => {

        setRecipeAutoSuggestions({
            loading: false,
            data: null,
            error: false
        })

        setActiveSuggestionIndex(-1)


    }

    const doPartMatchRecipeRestCall = async (searchTerm) => {
        console.log("auto-suggestion-Lists.length: " + searchTerm.length);

        if (searchTerm.length != null && searchTerm.length >= charsBeforeSearch) {
            let json = await restGet(`/fmd/recipes/detailed?p=1&s=${itemsInSearch}&keyword=${searchTerm}`);

            console.log("Full JSON from part keyword for dropdown: \n" + JSON.stringify(json));

            setRecipeAutoSuggestions({
                loading: false,
                data: json,
                error: false
            });

        } else {
            setRecipeAutoSuggestions({
                loading: false,
                data: null,
                error: false
            });
        }

    }
    const charEntered = (updatedKeyword) => {

        console.log("on change char entered: " + updatedKeyword);

        // remembered search term
        sessionStorage.setItem('rst', updatedKeyword);
        setActiveSuggestionIndex(-1);
        setSearchTerm(updatedKeyword);

        doPartMatchRecipeRestCall(updatedKeyword);
    };
    const onLd = (updatedKeyword) => {

        if (!isBlank(updatedKeyword)) {
            setSearchTerm(updatedKeyword); // So that clicking search works with session-remembered search string rst
        }
    };

    useEffect(() => {

        onLd(sessionStorage.getItem('rst'));

    }, []);

    return (
        <div style={{width: "90%", marginBottom: "30px", textAlign:"left", position: "relative", top: "2px", margin:"auto"}}>
            <input style={{width: "100%"}} placeholder={searchTerm}
                   value={sessionStorage.getItem('rst') ? sessionStorage.getItem('rst') : ''}
                   onChange={(e) => {
                       charEntered(e.target.value);
                   }
                   } onKeyDown={(e) => {

                onKeyPress(e);
            }}
                   onFocus={(e) => {
                       highlightOnFocus(e);
                       //To keep state after back is clicked in combo with the value setting in the input tag
                       charEntered(e.target.value);
                   }} onBlur={(e) => {
                // results need to disappear for neatness and for access to possible elements underneath
                blankResults();
            }}
                   onClick={(e) => {
                       //To keep state after back is clicked in combo with the value setting in the input tag
                       e.target.disabled = false;
                       setActiveSuggestionIndex(-1);//-1 means main input

                   }}
            />

            <AutoSuggestionListComponent recipeAutoSuggestions={recipeAutoSuggestions}/>
            <div style={{

                textAlign:"right"
            }} >
            <button style={{
                padding: "3px 5px 3px",
                marginLeft: "4px",
                marginRight: "0px"
            }} onClick={() => onSearch(activeSuggestionIndex)}>Find It!
            </button>
            </div>
        </div>

    )

};

export default RecipeSearch;
