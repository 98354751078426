import React, {useEffect} from 'react'


const RecipeModifyPopUp = ({
                               closePopUp,
                               currentRecipe,
                               setEditableRecFields,
                               saveAllFieldsToNewRecipe,
                               editableFields
                           }) => {

    useEffect(() => {
        window.location.hash = "#topOfPage"

        setEditableRecFields({
            "recipeName": currentRecipe.recipeName,
            "recipeSummary": currentRecipe.recipeSummary,
            "recipeInstructions": currentRecipe.recipeInstructions
        })

    }, []);

    return (
        <div className="popupstyle">
            <div className="popupstyle_content">
          <span className="closePopupX" onClick={(e) => {
              closePopUp()
          }}>
            &times;
          </span>

                <h3>Please reflect your changes in the recipe's Name, Summary and Instructions</h3>
                <label>
                    Recipe Name (please make it unique):<br/>
                    <input type="text" name="recipeName"
                           placeholder={currentRecipe.recipeName}
                           onChange={(ern) => {
                               let recipeName = ern.target.value;
                               setEditableRecFields({
                                   ...editableFields,
                                   "recipeName": recipeName
                               });
                           }}

                    />
                </label><br/>
                <label>
                    Summary (modify if necessary to describe your changes):<br/>
                    <textarea name="recipeSummary"
                              onChange={(erd) => {
                                  let recipeSummary = erd.target.value;
                                  setEditableRecFields({...editableFields, "recipeSummary": recipeSummary});
                              }}

                    >{currentRecipe.recipeSummary}</textarea>
                </label><br/>
                <label>
                    Instructions (add your changes so others can make this):<br/>
                    <textarea name="recipeInstructions"
                              onChange={(eri) => {
                                  let recipeInstructions = eri.target.value;
                                  setEditableRecFields({...editableFields, "recipeInstructions": recipeInstructions});
                              }}

                    >{currentRecipe.recipeInstructions}</textarea>
                </label>
                <br/>
                <button onClick={(e) => {
                    saveAllFieldsToNewRecipe();
                }}>Save Recipe
                </button>
            </div>
        </div>
    );
};

export default RecipeModifyPopUp;
