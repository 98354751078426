import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import HomeSearchScreen from './components/home-search/HomeSearchScreen'
import SearchResultsRestWrapper from './components/recipe-search-results/SearchResultsRestWrapper'
import './fmd.css'
import './draggablepiechart.css'


import RecipeRestWrapper from "./components/recipe-details/RecipeRestWrapper";
import CreateFoodRestWrapper from "./components/create-food/CreateFoodRestWrapper";
import UserLoginRestWrapper from "./components/user-login/UserLoginRestWrapper";
import RegistrationRestWrapper from "./components/registration/RegistrationRestWrapper";
import FavoritesWrapper from "./components/favorite-recipes/FavoritesWrapper";
import TargetsWrapper from "./components/targets/TargetsWrapper";
import PieChartRewardingWrapperExample from "./components/piechart-rewarding/PieChartRewardingWrapperExample";
import PieChartSmallWrapperExample from "./components/piechart-small/PieChartSmallWrapperExample";
import PieChartDraggableWrapperExample from "./components/piechart-draggable/PieChartDraggableWrapperExample";
import AboutView from "./components/static-pages/AboutView";
import ContactView from "./components/static-pages/ContactView";
import FaqView from "./components/static-pages/FaqView";


const Root = () => (
    <BrowserRouter>
        <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
        />
        <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no" />        <Switch>
            <Route exact path="/" component={HomeSearchScreen}/>
            <Route exact path="/results" component={SearchResultsRestWrapper}/>
            <Route exact path="/recipes/:recid" component={RecipeRestWrapper}/>
            <Route exact path="/create-food" component={CreateFoodRestWrapper}/>
            <Route exact path="/user-login" component={UserLoginRestWrapper}/>
            <Route exact path="/registration" component={RegistrationRestWrapper}/>
            <Route exact path="/favorites" component={FavoritesWrapper}/>
            <Route exact path="/targets" component={TargetsWrapper}/>
            <Route exact path="/profile" component={HomeSearchScreen}/>
            <Route exact path="/piedraggable" component={PieChartDraggableWrapperExample}/>
            <Route exact path="/pierewarding" component={PieChartRewardingWrapperExample}/>
            <Route exact path="/piesmall" component={PieChartSmallWrapperExample}/>
            <Route exact path="/about" component={AboutView}/>
            <Route exact path="/contact" component={ContactView}/>
            <Route exact path="/faq" component={FaqView}/>

        </Switch>
    </BrowserRouter>
);

ReactDOM.render(<Root/>, document.getElementById('root'));
