import React, {useEffect} from 'react'
import {isLoggedIn, saveVisitorIfNeeded} from "../../utils/LoginUtils";
import NavBarLoggedIn from "./NavBarLoggedIn";
import NavBarLoggedOut from "./NavBarLoggedOut";

const NavBar = () => {

    useEffect(() => {

        // should be in index.jsp?
        //let existingGUID = localStorage.getItem("vid");
        saveVisitorIfNeeded()

    }, []);

    return (<><a id="topOfPage"/>
        {isLoggedIn() ? (<NavBarLoggedIn/>) : (<NavBarLoggedOut/>)}
        </>
    );
};

export default NavBar;

