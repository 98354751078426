import React from 'react'
import {useState} from 'react'
import {restGet} from "../../utils/RestUtils";
import {highlightOnFocus, makeNonBreaking, numToWord, restrictCharsBySplit} from "../../utils/FormattingUtils";
import {Link} from "react-router-dom";

const FoodAdd = ({onIngredientAdd}) => {
    const [searchTerm, setSearchTerm] = useState('Add Ingredient');
    const [activeFoodSuggestionIndex, setActiveFoodSuggestionIndex] = useState(0);
    let autoSuggestBoxesLen = 0; // array index of total number of suggestion boxes... so we know where to stop highlighting the next box
    let activeBoxEntityId = null; // entity id , e.g. recipeId or foodId
    let charsBeforeSearch = 2;
    const itemsInSearch = 7;
    const [foodAutoSuggestions, setFoodAutoSuggestions] = useState({
        loading: true,
        data: null,
        error: null
    });

    const doPartMatchFoodRestCall = async (searchTerm) => {
        console.log("auto-suggestion-Lists.length: " + searchTerm.length);

        if (searchTerm.length != null && searchTerm.length >= charsBeforeSearch) {
            let json = await restGet(`/fmd/foods/detailed?p=1&s=${itemsInSearch}&keyword=${searchTerm}`);

            console.log("Full JSON from part keyword for dropdown: \n" + JSON.stringify(json));

            setFoodAutoSuggestions({
                loading: false,
                data: json,
                error: false
            });

        } else {
            setFoodAutoSuggestions({
                loading: false,
                data: null,
                error: false
            });
        }
    }

    const onAddFood = (activeBoxEntityId) => {
        console.log("onAdd clicked")
        onIngredientAdd(activeBoxEntityId);
    }

    const onKeyPress = (e) => {

        // alert(e.keyCode);
        switch (e.keyCode) {
            case 37://left
                break;
            case 38://up
                if (activeFoodSuggestionIndex > 0) {// don't want to go past the top
                    setActiveFoodSuggestionIndex(activeFoodSuggestionIndex - 1)
                }
                // stops caret scrolling to start of word in main input upon pressing up arrow
                e.preventDefault()
                break;
            case 39://right
                break;
            case 40:
                //alert('down');
                if (autoSuggestBoxesLen > activeFoodSuggestionIndex) {
                    setActiveFoodSuggestionIndex(activeFoodSuggestionIndex + 1);
                }
                break;
            case 13://enter
                onAddFood(activeBoxEntityId);
                //onSearch(-1);
                break;
            default:
                break;
        }
    };

    const AutoSuggestionListComponent = (props) => {


        let subtext = <div className="autosuggestionplaceholder">
            Type {numToWord(charsBeforeSearch)} letters for food suggestions to add...
        </div>
        if (props.foodAutoSuggestions.data != null && props.foodAutoSuggestions.data.totalElements === 0
            && searchTerm.length != null && searchTerm.length >= charsBeforeSearch) {
            return (<>{subtext}
                <div className="autosuggestiondiv" style={{top: "36px"}}>
                <span className="autosuggestionerrorbox">
                    {makeNonBreaking("No foods Match. ")}<Link
                    onMouseDown={(e) => {
                        window.location = "/create-food";
                    }}
                    to="/recipes/0" style={{fontWeight: "normal"}}
                    className="autosuggestionerrorboxlink">{makeNonBreaking("Add Here")}</Link>
                </span></div>
            </>);
        } else {
            if (props.foodAutoSuggestions.data != null) {
                return (<>{subtext}
                    <div className="autosuggestiondiv" style={{top: "36px"}}>
                        {props.foodAutoSuggestions.data.itemsOnThisPage.map((fd, ind) => {
                            // Flag the active suggestion with a class
                            autoSuggestBoxesLen = ind;
                            console.log("activeFoodSuggestionIndex: " + activeFoodSuggestionIndex);
                            let className = "autosuggestionbox";
                            if (ind === activeFoodSuggestionIndex) { //active suggestion index is some kind of global flag set elsewhere to say which is highlighted
                                className = "activeautosuggestion"; // this is a css thing to highlight it
                                activeBoxEntityId = fd.foodId; // now we know where to forward to when return is pressed.
                            }

                            let foodNameInDropdown = makeNonBreaking(fd.foodName);
                            foodNameInDropdown = restrictCharsBySplit(foodNameInDropdown, 54, -3)
                            let substuff = makeNonBreaking(" (" + fd.foodId + ")");

                            return (
                                <span key={fd.foodId} title={fd.foodName} className={className}
                                      onMouseDown={() => onAddFood(fd.foodId)}>
                                {foodNameInDropdown}<span style={{fontSize: '9px', lineHeight: '0px'}}>{substuff}</span>
                                <br/></span>
                            );
                        })}

                    </div>
                </>)
            } else {
                return subtext
                // not enough chars entered for any suggestion yet, no error, nothing needed
            }

        }
    };
    const blankResults = () => {

        setFoodAutoSuggestions({
            loading: false,
            data: null,
            error: false
        })

        setActiveFoodSuggestionIndex(0)


    }

    const charEntered = (updatedKeyword) => {
        console.log("on change char entered: " + updatedKeyword);
        // window.localStorage.setItem('rememberedSearchTerm', updatedKeyword);
        setActiveFoodSuggestionIndex(0);
        setSearchTerm(updatedKeyword);
        doPartMatchFoodRestCall(updatedKeyword);
    };


    return (
        <div style={{
            float: "left",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingBottom: "6px",
            paddingTop: "6px",
            position: "relative",
            width: "95.5%"
        }}>
            <input style={{width: "100%"}}
                   placeholder={searchTerm}
                   onChange={(e) => {
                       charEntered(e.target.value);
                   }} onKeyDown={(e) => {
                onKeyPress(e);
            }} onFocus={(e) => {
                //To keep state after back is clicked in combo with the value setting in the input tag
                highlightOnFocus(e);
                charEntered(e.target.value);
            }} onBlur={(e) => {
                // results need to disappear for neatness and for access to possible elements underneath
                blankResults();
            }} onClick={(e) => {
                //To keep state after back is clicked in combo with the value setting in the input tag
                e.target.disabled = false;
                setActiveFoodSuggestionIndex(0);//-1 means main input
            }}
            />
            <AutoSuggestionListComponent foodAutoSuggestions={foodAutoSuggestions}/>
        </div>
    )
};

export default FoodAdd;
