import React, {useEffect} from 'react'
import PieChartDraggableView from "../piechart-draggable/PieChartDraggableView";
import {FmdInputRow} from "../../utils/FmdInputRow";

const TargetUpdatePopUp = ({
                               targetToEdit,
                               updateTarget,
                               closePopUp,
                               getPercentFromPropList,
                               errorMessageData,

                           }) => {

    const topText = <><b>Adjust your targets (optional)</b><br/></>;
    const middleText = <><br/><b>% calories (kcals) from each nutrient</b><br/><br/></>;
    const bottomText = <><br/><p id={"piechart-instructions"}>Drag the slices <b> or </b> click
        the <b>&#43;</b>/<b>&#8722;</b> buttons <br/></p><br/></>



// defaults in case they don't roll over or edit anything
    let fatVal = getPercentFromPropList(10, targetToEdit.targetNutrientProportionsList);
    let proVal =  getPercentFromPropList(20, targetToEdit.targetNutrientProportionsList);
    let carbVal =  getPercentFromPropList(30, targetToEdit.targetNutrientProportionsList);
    let profName = targetToEdit.profileName;

    const setFatz = async (fat) => {
        console.log("fat being set as" + fat)
        fatVal = fat;
    }
    const setProteinz = async (pro) => {
        console.log("pro being set as" + pro)
        proVal = pro
    }
    const setCarbsz = async (car) => {
        console.log("car being set as" + car)
        carbVal = car
    }
    const setProfileNameLocal = (name) => {
        console.log("name is certainly being set as name" + JSON.stringify(name))
        console.log("name is certainly being set as name.profileName" + JSON.stringify(name.profileName))

        profName = name.profileName
        console.log("name is certainly being set as .. profName" + JSON.stringify(profName))
    }
    useEffect(() => {
        console.log("set profileName to " +targetToEdit.profileName)
        setProfileNameLocal({"profileName":targetToEdit.profileName})
        window.location.hash = "#topOfPage"
    }, []);


    return (
        <div className="popupstyle">
            <div className="popupstyle_content">
          <span className="closePopupX" onClick={(e) => {
              //setProfileNameLocal({"profileName":targetToEdit.profileName})
              updateTarget(profName, fatVal, proVal, carbVal)
          }}>
            &times;
          </span>
                <br/><br/><br/>
                <FmdInputRow jsonName={"profileName"} fldNameLabel={"Target Name"}
                             errorMessageCodes={[]}
                             dataAddFunction={setProfileNameLocal}
                             placeholder={"Name your target"}
                             errorMessageData={errorMessageData}
                             defaultVal={targetToEdit.profileName}/>

                <PieChartDraggableView
                    initialPositions={[getPercentFromPropList(10, targetToEdit.targetNutrientProportionsList),
                        getPercentFromPropList(20, targetToEdit.targetNutrientProportionsList),
                        getPercentFromPropList(30, targetToEdit.targetNutrientProportionsList)]}
                    fatSetCallback={setFatz}
                    proteinSetCallback={setProteinz}
                    carbSetCallback={setCarbsz}
                    topText={topText}
                    middleText={middleText}
                    bottomText={bottomText}
                />
                <br/>
                <button onClick={() => {
                   // setProfileNameLocal({"profileName":targetToEdit.profileName})
                    updateTarget(profName, fatVal, proVal, carbVal)
                }}>Save Target
                </button>
                <br/>

            </div>
        </div>
    );
};

export default TargetUpdatePopUp;
