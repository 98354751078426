import React from 'react'
import { useState } from 'react'
import {restGet} from "../../utils/RestUtils";
import {highlightOnFocus, makeNonBreaking, numToWord, restrictCharsBySplit} from "../../utils/FormattingUtils";

const NutrientAdd = ({onNutrientAdd}) => {
    const [searchTerm, setSearchTerm] = useState('Add Another Nutrient');
    const [activeNutSuggestionIndex, setActiveNutSuggestionIndex] = useState(0);
    let autoSuggestBoxesLen = 0; // array index of total number of suggestion boxes... so we know where to stop highlighting the next box
    let activeBoxEntityId = null; // entity id , e.g. recipeId or foodId
    let charsBeforeSearch = 1;
    const itemsInSearch = 15;

    // to exclude the items already on the screen from being selected to be added again
    // initial state is the compulsory nutrients hard coded into the compulsory section of the page
    const [excludeFromAutosuggestions, setExcludeFromAutosuggestions] = useState([10, 20, 30, 35]);

    const [nutAutoSuggestions, setNutAutoSuggestions] = useState({
        loading: true,
        data: null,
        error: null
    });



      const doPartMatchNutRestCall = async (searchTerm) => {
        console.log("auto-suggestion-Lists.length: " + searchTerm.length);

        if (searchTerm.length != null && searchTerm.length >= charsBeforeSearch) {
            let json = await restGet(`/fmd/nutrients?p=1&s=${itemsInSearch}&keyword=${searchTerm}`);

            console.log("Full JSON from part keyword for dropdown: \n" + JSON.stringify(json));

            setNutAutoSuggestions({
                loading: false,
                data : json,
                error: false
            });

        } else {
            setNutAutoSuggestions({
                loading: false,
                data: null,
                error: false
            });
        }
    }



    const onAddNut = (activeBoxEntityId) => {

       // excludeFromAutosuggestions.push(activeBoxEntityId);


        setExcludeFromAutosuggestions(excludeFromAutosuggestions => excludeFromAutosuggestions.concat(activeBoxEntityId));
        console.log("onAdd clicked")
        onNutrientAdd(activeBoxEntityId);
        console.log("in the exclude array now after push" + excludeFromAutosuggestions);
    }

    const onKeyPress = (e) => {

        // alert(e.keyCode);
        switch (e.keyCode) {
            case 37://left
                break;
            case 38://up
                if(activeNutSuggestionIndex>0){// don't want to go past the top
                    setActiveNutSuggestionIndex(activeNutSuggestionIndex-1)}
                // stops caret scrolling to start of word in main input upon pressing up arrow
                e.preventDefault()
                break;
            case 39://right
                break;
            case 40:
                //alert('down');
                if(autoSuggestBoxesLen>activeNutSuggestionIndex) {
                    setActiveNutSuggestionIndex(activeNutSuggestionIndex + 1);
                }
                break;
            case 13://enter
                onAddNut(activeBoxEntityId);
                //onSearch(-1);
                break;
            default://enter
                break;
        }
    };

    const excludedFromAutoSuggestions = (isNumExcluded) => {
        console.log("**exc from auto sugg: " + JSON.stringify(excludeFromAutosuggestions))
        return excludeFromAutosuggestions.includes(isNumExcluded);
    }

    const AutoSuggestionListComponent = (props) => {

        let subtext =<div className="autosuggestionplaceholder">
            Type {numToWord(charsBeforeSearch)} letter{numToWord(charsBeforeSearch)!=="1" ?"":"s"} for possible nutrients...
        </div>
        if(props.nutAutoSuggestions.data != null && props.nutAutoSuggestions.data.totalElements === 0
            && searchTerm.length !=null && searchTerm.length >= charsBeforeSearch){
            return (<>{subtext}<div className="autosuggestiondiv" style={{top: "36px"}}>
                <span class="autosuggestionerrorbox" >
                    {makeNonBreaking("No nutrients match")}
                </span></div></>);
        }else{
            if (props.nutAutoSuggestions.data != null){
                return (<>{subtext}<div className="autosuggestiondiv"  style={{top: "36px"}}>
                    {props.nutAutoSuggestions.data.itemsOnThisPage.map((nt, ind) => {
                        // Flag the active suggestion with a class
                        autoSuggestBoxesLen = ind;
                        console.log("activeNutSuggestionIndex: " + activeNutSuggestionIndex);
                        let className = "autosuggestionbox";
                        if (ind === activeNutSuggestionIndex) { //active suggestion index is some kind of global flag set elsewhere to say which is highlighted
                            className = "activeautosuggestion"; // this is a css thing to highlight it
                            activeBoxEntityId = nt.nutrientId; // now we know where to forward to when return is pressed.
                        }

                        let entNameInDropdown = makeNonBreaking(nt.nutrientName);
                        entNameInDropdown = restrictCharsBySplit(entNameInDropdown, 54, -3)
                        let substuff = makeNonBreaking(" (" + nt.nutrientId + ")");

                        if(!excludedFromAutoSuggestions(nt.nutrientId)) {
                            return (
                                <span key={nt.nutrientId} title={nt.nutrientName} className={className}
                                      onMouseDown={() => onAddNut(nt.nutrientId)}>
                                {entNameInDropdown}<span style={{fontSize: '9px', lineHeight: '0px'}}>{substuff}</span>
                                <br/></span>
                            );
                        }
                    })}

                </div>
                </>)
            }else{
                return subtext
                // not enough chars entered for any suggestion yet, no error, nothing needed
            }
        }
    };

    const blankResults = () => {

        setNutAutoSuggestions({
            loading: false,
            data: null,
            error: false
        })

        setActiveNutSuggestionIndex(0)
    }

    const charEntered = (updatedKeyword) => {
        console.log("on change char entered: " + updatedKeyword);
        // window.localStorage.setItem('rememberedSearchTerm', updatedKeyword);
        setActiveNutSuggestionIndex(0);
        setSearchTerm(updatedKeyword);
        doPartMatchNutRestCall(updatedKeyword);
    };

    return(
        <div style={{float: "left", border:"none", padding:"6 5", position:"relative", width:"100%"}}>
            <input style={{width: "100%"}} placeholder={searchTerm}
            onChange={(e) => {
                charEntered(e.target.value);
            }} onKeyDown={(e) => {
                onKeyPress(e);
            }} onFocus={(e) => {
                //To keep state after back is clicked in combo with the value setting in the input tag
                highlightOnFocus(e);
                charEntered(e.target.value);
            }} onBlur={(e) => {
                // results need to disappear for neatness and for access to possible elements underneath
                blankResults();
            }} onClick={(e) => {
                //To keep state after back is clicked in combo with the value setting in the input tag
                e.target.disabled=false;
                setActiveNutSuggestionIndex(0);//-1 means main input
            }}
            />
            <AutoSuggestionListComponent nutAutoSuggestions={nutAutoSuggestions}/>


        </div>
    )
};

export default NutrientAdd;
