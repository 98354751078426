import React, {useEffect} from 'react'
import {isUndefined} from "lodash";
import bin from "../../img/bin.png";
import pen from "../../img/pen.png";

import {FmdInputRow} from "../../utils/FmdInputRow";
import {FAVORITE_RECIPE_LIST_BLANK} from "../../Validation/ErrorConstants";
import PieChartSmallView from "../piechart-small/PieChartSmallView";
import {dateWordFormat} from "../../utils/FormattingUtils";

const TargetsView = ({
                         usersTargets,
                         onRemoveTargetProfile,
                         openTargetCreatePopUp,
                         openTargetUpdatePopUp,
                         setNewTargetName,
                         getPercentFromPropList,
                         errorMessageData
                     }) => {
    useEffect(() => {
        console.log("param that went into small pie view user tatrgets " + JSON.stringify(usersTargets));
    }, []);


    return (
        <div className="outerDiv">
            <h1>My Targets</h1>



            <table className="mainTable">
                <tbody>
                <tr>
                    <FmdInputRow jsonName={"profileName"} fldNameLabel={"New Target"}
                                 errorMessageCodes={[FAVORITE_RECIPE_LIST_BLANK]}
                                 dataAddFunction={setNewTargetName}
                                 placeholder={"Enter its name here"}
                                 compulsory={false}
                                 errorMessageData={errorMessageData}/>
                </tr>
                <tr style={{textAlign: "center"}}>


                    <td colSpan={2} style={{border: "none"}}>
                        <button onClick={() => {
                            openTargetCreatePopUp(true)
                        }}>Add Target...
                        </button>
                    </td>
                </tr>


                </tbody>
            </table>
            <table className="mainTable" >
                <tbody>
                {
                    !isUndefined(usersTargets.targetProfilesList) ? (

                        usersTargets.targetProfilesList.map((targetProfile, listInd) => (
                            <table className="mainTable" style={{marginBottom:30}}>
                                <tr><td colSpan={2} style={{border: 0}}><h3>{targetProfile.profileName}</h3></td></tr>
                                <tr key={listInd} style={{height: 100, padding: 0}}>
                                    <td style={{textAlign: "center", cursor: "pointer", height: 100, width: 100}} onClick={(e) => {
                                        openTargetUpdatePopUp(targetProfile)
                                    }}>
                                        <PieChartSmallView uniqueName={listInd}
                                                           widthPx={100}
                                                           fat={getPercentFromPropList(10, targetProfile.targetNutrientProportionsList)}
                                                           protein={getPercentFromPropList(20, targetProfile.targetNutrientProportionsList)}
                                                           carbs={getPercentFromPropList(30, targetProfile.targetNutrientProportionsList)}/>
                                    </td>
                                    <td rowSpan={2} style={{verticalAlign:"top"}}>

                                        <table style={{border: 0, paddingTop:8}}>
                                            <tr>
                                                <td style={{width : 60, textAlign: "center", padding:"6px 4px 6px 4px", fontWeight:600, backgroundColor:'#80a1d9'}}>{getPercentFromPropList(10, targetProfile.targetNutrientProportionsList)}%</td>
                                                <td style={{width : 60, textAlign: "center", padding:"6px 4px 6px 4px", fontWeight:600,backgroundColor:'#cc6a55'}}>{getPercentFromPropList(20, targetProfile.targetNutrientProportionsList)}%</td>
                                                <td style={{width : 60,  textAlign: "center", padding:"6px 4px 6px 4px", fontWeight:600,backgroundColor:'#f8e48a'}}>{getPercentFromPropList(30, targetProfile.targetNutrientProportionsList)}%</td>
                                            </tr>
                                            <tr>
                                                <td style={{width : 40, textAlign: "center", padding:"6px 4px 6px 4px", fontWeight:600,backgroundColor:'#80a1d9'}}>FAT</td>
                                                <td style={{width : 60, textAlign: "center", padding:"6px 4px 6px 4px", fontWeight:600,backgroundColor:'#cc6a55'}}>PRO</td>
                                                <td style={{width : 60, textAlign: "center", padding:"6px 4px 6px 4px", fontWeight:600,backgroundColor:'#f8e48a'}}>CARB</td>
                                            </tr>
                                        </table>
                                        <span style={{fontStyle:"italic"}}>Updated {dateWordFormat(targetProfile.dateCreated)}</span>

                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: "center"}}>
                                        <img style={{cursor: "pointer"}} src={bin} alt="Delete from Targets"
                                             onClick={(e) => {
                                                 onRemoveTargetProfile(targetProfile.targetProfileId)
                                             }}
                                        />&nbsp;
                                        <img style={{cursor: "pointer"}} src={pen} alt="Edit Target"
                                             onClick={(e) => {
                                                 openTargetUpdatePopUp(targetProfile)
                                             }}
                                        />
                                    </td>

                                </tr>
                            </table>
                        ))
                    ) : (<></>
                    )
                }
                </tbody>
            </table>

        </div>
    );
};

export default TargetsView;
