import React from 'react'
import {
    FMD_LOGIN_CREDENTIALS_DONT_MATCH,
    LOGIN_PASSWORD_BLANK,
    LOGIN_UNAME_BLANK
} from "../../Validation/ErrorConstants";
import {Link} from "react-router-dom";
import {FmdInputRow} from "../../utils/FmdInputRow";


const UserLoginView = ({
                           addLoginData,
                           submitLoginData,
                           errorMessageData,
                       }) => {

    return (
        <div className="outerDiv">
            <h1>Sign In</h1>
            <p>
            Please sign in here, or <Link to={"/registration"}>quickly Sign Up</Link> for free.
                </p>
            <table className="mainTable" >
                <tbody>
                <tr>
                    <FmdInputRow jsonName={"username"} errorMessageCodes={[LOGIN_UNAME_BLANK]}
                                 dataAddFunction={addLoginData}
                                 errorMessageData={errorMessageData}/>
                </tr>
                <tr>
                    <FmdInputRow jsonName={"np"} fldNameLabel={"Password"} inputType={"password"}
                                 errorMessageCodes={[LOGIN_PASSWORD_BLANK, FMD_LOGIN_CREDENTIALS_DONT_MATCH]}
                                 dataAddFunction={addLoginData}
                                 errorMessageData={errorMessageData}/>
                </tr>
                <tr>
                    <td colSpan={2} style={{border: "0px", textAlign: "center", padding:"10px"}}>
                        <br/>
                        <button onClick={() => {
                            submitLoginData()
                        }}>Sign In</button>
                    </td>
                </tr>
                </tbody>
            </table>
            <br/>


        </div>
    );
};

export default UserLoginView;
