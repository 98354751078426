import React, {useEffect} from 'react'
import PieChartDraggableView from "../piechart-draggable/PieChartDraggableView";
import {FmdInputRow} from "../../utils/FmdInputRow";

const TransientTargetPopup = ({
                                  targetMacros,
                                  updateTarget,
                                  closePopUp,
                                  errorMessageData
                              }) => {

    const topText = <><b>Adjust your targets (optional)</b><br/></>;
    const middleText = <><b>% calories (kcals) from each nutrient</b><br/><br/></>;
    const bottomText = <>Drag the slices <b> or </b> click
        the <b>&#43;</b>/<b>&#8722;</b> buttons <br/></>

    let fatVal = targetMacros.fat;
    let proVal =  targetMacros.protein;
    let carbVal =  targetMacros.carbs;
    let kcalVal =  targetMacros.kcals;
    const setFatz = async (fat) => {
        console.log("fat being set as" + fat)

        fatVal = fat;
    }
    const setProteinz = async (pro) => {
        console.log("pro being set as" + pro)

        proVal = pro
    }
    const setCarbsz = async (car) => {
        console.log("car being set as" + car)

        carbVal = car
    }
    const setKcalz = async (kcalJson) => {
        console.log("kcals being set as.. and it really is: " + JSON.stringify(kcalJson))

        kcalVal = kcalJson.kcals

    }
    useEffect(() => {
        setKcalz({"kcals": targetMacros.kcals});
        window.location.hash = "#topOfPage"

    }, []);

    
    return (
        <div className="popupstyle">
            <div className="popupstyle_content">
          <span className="closePopupX" onClick={(e) => {
              // same action here as clicking save!
              updateTarget({
                  "fat": fatVal,
                  "protein": proVal,
                  "carbs": carbVal,
                  "kcals": kcalVal
              })

              closePopUp();
          }}>
            &times;
          </span>
                <br/><br/><br/>
  <FmdInputRow jsonName={"kcals"} fldNameLabel={"Calories (Kcals)"}
                             errorMessageCodes={[]}
                             dataAddFunction={setKcalz}
                             errorMessageData={errorMessageData}
                             defaultVal={targetMacros.kcals}/>
<br/>
                <PieChartDraggableView
                    initialPositions={[targetMacros.fat, targetMacros.protein, targetMacros.carbs]}
                    fatSetCallback={setFatz}
                    proteinSetCallback={setProteinz}
                    carbSetCallback={setCarbsz}
                    topText={topText}
                    middleText={middleText}
                    bottomText={bottomText}
                />
                <br/>
                <button onClick={() => {
                    updateTarget({
                        "fat": fatVal,
                        "protein": proVal,
                        "carbs": carbVal,
                        "kcals": kcalVal
                    })

                    closePopUp();
                }}>Save Target
                </button>
                <br/>


            </div>
        </div>
    );
};

export default TransientTargetPopup;
