import React, {useEffect} from 'react'
import logo from '../../img/fmdSmall.jpg'
import initNavListeners from './navMenu'
import {Link} from "react-router-dom";
import {logout} from "../../utils/LoginUtils";


const NavBarLoggedIn = () => {

// TODO: reloadTheLot instead of window location?
    const navToWithRefresh = async (str) => {
        window.location = str;
    }

    useEffect(() => {
        initNavListeners();


    }, []);

    return (<>
            <nav>
                <div className={"nav-center"}>

                    <div className={"nav-header"}>
                        <div className={"nav-left"}>
                            &nbsp;<Link to={"/"}><img alt="logo" src={logo} className={"nav-logo"}/></Link>
                            &nbsp;&nbsp;&nbsp;&nbsp;

                            <span className={"user-plus"} onClick={() => {
                                logout();
                            }}>
                        <i className={"fa-solid fa-user-minus"}></i>
                    </span>&nbsp;&nbsp;&nbsp;&nbsp;

                        </div>

                        <div className={"nav-right"}>
                            {/*<input className={"nav-search"} placeholder={"Enter Recipe"}/>*/}
                            &nbsp;
                            <Link to={"/"}>
                            <span className={"magnifying-glass"}>
                                <i className={"fa-solid fa-magnifying-glass"}></i>
                            </span>
                            </Link>
                            &nbsp;&nbsp;
                            <button style={{backgroundColor: "#FFFFFF", padding: 4, margin: 0}}
                                    className={"nav-burger"}>
                                <i id={"burgeri-loggedin"} className={"fa-solid fa-bars"}></i>
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;

                        </div>
                    </div>


                    <ul className="nav-links">
                        <li></li>
                        <li></li>
                        <li>
                        <span onClick={() => {
                            navToWithRefresh("/targets");
                        }}>
            <Link className="menuloggedin">My Targets</Link>
            </span>
                        </li>
                        <li>
                        <span onClick={() => {
                            navToWithRefresh("/favorites");
                        }}>
            <Link className="menuloggedin">My Favorites</Link>
            </span>
                        </li>
                        <li>
                        <span onClick={() => {
                            logout();
                        }}>
            <Link className="menuloggedin">Sign Out</Link>
            </span>
                        </li>

                        <hr/>

                        <li>
                    <span onClick={() => {
                        navToWithRefresh("/recipes/0");
                    }}>
                    <Link> Add New Recipe</Link>
                    </span></li>
                        <li>
                            <Link to={"/create-food"}>Add Ingredient</Link>
                        </li>
                        <li>
                            <Link to={"/about"}>About</Link>
                        </li>
                        <li>
                            <Link to={"/contact"}>Contact</Link>
                        </li>
                        <li>
                            <Link to={"/faq"}>Help/FAQ</Link>
                        </li>


                    </ul>
                </div>


            </nav>
            <div className="behind-nav-pad">
                &nbsp;
            </div>
        </>);
};

export default NavBarLoggedIn;
