import {logJSON} from "./FormattingUtils";

// urls for rest services dependent on the environment concerned
const localUrl = "http://localhost:8077"
const devUrl = "https://fmd-api.fitmydiet.com:8077"
const liveUrl = "https://fmd-api.fitmydiet.com:8078"
let currentUrl;
const existingUrl = window.location.href;

// decide on the correct api according to the environment
if (existingUrl.includes("dev.fitmydiet.com") || existingUrl.includes("dev-fit-my-diet-")) {
    console.log("ENVIRONMENT IS dev " + devUrl + "  calling url is:" + existingUrl)
    currentUrl = devUrl
} else if (existingUrl.includes("fitmydiet.com")) {
    console.log("ENVIRONMENT IS live " + liveUrl+ "  calling url is:" + existingUrl)
    currentUrl = liveUrl
} else {
    console.log("ENVIRONMENT IS local " + localUrl + "  calling url is:" + existingUrl)
    currentUrl = localUrl
}


export const restGet = async (uriEnd) => {

    const fullUri = `${currentUrl}${uriEnd}`;
    console.log("Unencoded REST endpoint URI:\n" + fullUri);
    const url = encodeURI(fullUri);

    const response = await fetch(url);
    const json = await response.json()

    console.log("REST json RESPONSE:\n" + logJSON(json));
    return json;

}

export const restPost = async (uriEnd, reqJson) => {

    console.log("REST SAVE uri:\n" + uriEnd);
    console.log("REST SAVE ATTEMPT:\n" + logJSON(reqJson));

    const fullUri = `${currentUrl}${uriEnd}`;

    let options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(reqJson)
    };

    const response = fetch(fullUri, options);
    const json = (await response).json();
    //  console.log("REST SAVE response:\n" + JSON.stringify(json));

    return json;

}


export const restPut = async (uriEnd, reqJson) => {

    console.log("REST SAVE uri:\n" + uriEnd);
    console.log("REST SAVE ATTEMPT:\n" + logJSON(reqJson));

    const fullUri = `${currentUrl}${uriEnd}`;

    let options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(reqJson)
    };

    //  const response = fetch(fullUri, options);
    //   const json = (await response).json();
    //  console.log("REST SAVE response:\n" + JSON.stringify(json));
    const url = encodeURI(fullUri);
    const response = await fetch(url, options);
    const json = (await response).json();

    console.log("REST json RESPONSE:\n" + logJSON(json));
    return await json;
}


export const restDelete = async (uriEnd) => {

    const fullUri = `${currentUrl}${uriEnd}`;
    console.log("Unencoded REST endpoint delete URI:\n" + fullUri);

    let options = {
        method: 'delete',

    };

    const url = encodeURI(fullUri);
    const response = await fetch(url, options);
    const json = (await response).json();

    console.log("REST json RESPONSE:\n" + logJSON(json));
    return json;

}
