import {restPost} from "./RestUtils";
import {isBlank} from "../Validation/ErrorValidation";
import {recursiveObjToString} from "./FormattingUtils";
import {isNull} from "lodash";

const saveVisitor = async (visitorDto) => {
    let responseJson = await restPost("/fmd/visitors/", {visitorDto});

    if (!isBlank(responseJson.newVisitorId)) {
        console.log("responseJson" + JSON.stringify(responseJson))
        console.log("responseJson.newVisitorId" + JSON.stringify(responseJson.newVisitorId))
        localStorage.setItem("vuid", responseJson.newVisitorId);
    }

    return null;
    // don't worry about error handling here, if it doesn't work, just continue as normal, the error will be server side
}
export const saveVisitorIfNeeded = async () => {

    if (isBlank(localStorage.getItem("vuid"))) {
        // add a visitor to the database
        let visitorDto = {
            //"guid": uuid(),
            "referrer": document.referrer,
            "language": navigator.language,
            "userAgent": navigator.userAgent,
            "navigatorInfo": recursiveObjToString(navigator)
        }
        await saveVisitor(visitorDto)
    }
}


export const saveLoginDataToSession = (loginResponseJson) => {

    sessionStorage.setItem("liu", JSON.stringify(loginResponseJson));

}

export const logout = (loginResponseJson) => {

    sessionStorage.setItem("liu", null);
    window.location = "/"
}


export const getLoginItem = (itm) => {
    // need to use jwt here.

    const json = sessionStorage.getItem("liu");
    const loginf = JSON.parse(json);

    console.log("getting login variable" + JSON.stringify(loginf))
    if (!isNull(loginf)) {
        return loginf[itm];
    }
    return null;
}

export const getVisitorId = () => {
    // need to use jwt here.

  return localStorage.getItem("vuid")
}




export const isLoggedIn = () => {
    // need to use jwt here.

    const json = sessionStorage.getItem("liu");
    const loginf = JSON.parse(json);

    if (!isNull(loginf)) {
        if (!isBlank(JSON.stringify(loginf.re))) {
            return true
        }
    }
    return false;
}