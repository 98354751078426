import {isEmpty, isUndefined, round} from "lodash";

function wait(ms)
{
    var d = new Date();
    var d2 = null;
    do { d2 = new Date(); }
    while(d2-d < ms);
}

export default function displayRewardingPieChart(canvasId, achievedRatios, targetRatios) {

    console.log("displayRewardingPieChart invoked ratios: " + "canvas " + canvasId + " "+ JSON.stringify(achievedRatios))
    const achievedUserParamSliceArray = [
        ['• Fat\n' + achievedRatios[0] + "%", achievedRatios[0], fmdColors[0], fmdColors[3]],
        ['• Protein\n' + achievedRatios[1] + "%", achievedRatios[1], fmdColors[1], fmdColors[3]],
        ['• Carbs\n' + achievedRatios[2] + "%", achievedRatios[2], fmdColors[2], fmdColors[3]]
    ];

    pieRewardingTarget = null;
    pieRewardingTarget = new PieChartJS(canvasId, achievedUserParamSliceArray,targetRatios, true);

}

function highlight(pieId) {
    pieRewardingTarget.highlightSlice(pieId);
}

// see X11 color names = https://en.wikipedia.org/wiki/Web_colors
var fmdColors = [
    '#80a1d9'
    , '#cc6a55'
    , '#f8e48a'
    , '#f7b529'

];


// declare global variable (not inside function)
var pieRewardingTarget;

//displayRewardingPieChart('mycanvas-fmd-target', [10,10,80]);


function PieChartJS(canvasElementId, userParamSliceArray, targetRatios, startFrom6Oclock) {

    var pieObject = {
        canvas: document.getElementById(canvasElementId) // copy from parameter
        , userParamSliceArray: userParamSliceArray // copy from parameter
        , ctx: null
        // sliceArray info consists of:
        // this.sliceRadius
        //this.radStartAngle
        //this.radEndAngle
        //this.sliceUserParams;
        , sliceArray: []
        , totalAllValue: 0
        , centX: 0
        , centY: 0
        , radius: 0
        , MAX_RADIUS: 0
        , HIGHLIGHTED_RADIUS: 47 // in px
        // convert 1 Degree to Radian = Math.PI / 180
        , ONE_DEGREE_TO_RADIAN: 0.017453292519943295
        // convert 1 Radian to Degree = 180 / Math.PI
        , ONE_RADIAN_TO_DEGREE: 57.29577951308232
        // How many Degree in 1 full circle ? = 360 Degree
        // How many Radian angle in 1 full circle ? = (2 * Math.PI) = 6.283185307179586 Radian
        , MAX_RADIAN_VALUE: 6.283185307179586
        , degToRad
        , radToDeg
        // get point [x,y] from a slice
        , getPointXY
        , SliceOfPie
        , drawSlice
        , initialiseCharts
        , drawLabels
        , updateSliceArray
        , refreshSliceArray
        , getRadianDegree
        , onMouseOver
        , removeAllHighlightSlice
        , highlightSlice
        , unHighlightSlice
        , isPointInsideSliceOfPie
        , redraw
        , animateSmaller
        , animateBigger
        , _create
    };

    console.log("creating the rewarding pie anew")
    // IIFE (Immediately-invoked function expression) -> return PieChartJS of this userParamSliceArray set object
    let current =  pieObject._create(canvasElementId, userParamSliceArray, startFrom6Oclock);



let slicesToHighlightInds = []
    if (!isUndefined(targetRatios)) {
        for (let i = 0; i<3; i++){
            if (round(targetRatios[i]) === round(userParamSliceArray[i][1])){
                console.log("highlighting slice: " + i);
                //  highlight(i)
                // pieRewardingTarget.sliceArray[i].sliceRadius = 80//this.originalRadius;
              slicesToHighlightInds.push(i)


                // pieRewardingTarget.highlightSlice(1);
                // pieRewardingTarget.highlightSlice(0);
            }
        }
    }


    pieObject.highlightSlice(slicesToHighlightInds);
    return current;




} // function PieChartJS(canvas, userParamSliceArray) {


/////////////////////////////////////////////////////////////////////////////////////////////////////


// start/init
function _create(canvasElementId, userParamSliceArray, startFrom0oClock) {
    if (canvasElementId == null || userParamSliceArray == null) {
        return null;
    }

    this.canvas = document.getElementById(canvasElementId);
    if (this.canvas == null) {
        return null;
    }

    console.log('part into create: ');

    this.userParamSliceArray = userParamSliceArray;

    // parsing to boolean true/false, true = slice start from top, false = slice start from 3 O'Clock
    this.startFrom0oClock = startFrom0oClock ? true : false;

    this.ctx = this.canvas.getContext('2d');

    // constant value
   // this.ctx.font = 'bold 12px sans-serif'; // label font

    this.ctx.lineWidth = 5;

    // calculate total value for degToRad
    this.totalAllValue = 0;
    userParamSliceArray.forEach(function (value, index, ele) {
        // calculate total
        this.totalAllValue += value[1];
    }.bind(this)); // send 'this' to the function !!
    console.log('total all value: ' + this.totalAllValue);

    // init
    this.initialiseCharts();

    // catch mouse event related to this canvas
  //  this.canvas.addEventListener('mousemove', this.onMouseOver.bind(this), false);


    // make Responsive by catch window (viewport) resize and re-init
    // window.addEventListener('resize', this.onWindowResize.bind(this), false);

    return this; // return of 'this' (PieChartJS) object
}


function degToRad(deg) {
    // Math.PI = 3.141592653589793
    // Math.PI / 180 = 0.017453292519943295

    return deg * this.ONE_DEGREE_TO_RADIAN; //deg * (Math.PI / 180);
}


function radToDeg(rad) {
    return rad * this.ONE_RADIAN_TO_DEGREE; //rad * (180 / Math.PI);
}


function getPointXY(sliceRadius, radianAngle) {
    //console.log('getPointXY(' + sliceRadius + ',' + radianAngle + ')');

    // WARNING: this function is slow because using Math.cos() and Math.sin()
    // use cache if possible

    return {
        'x': this.centX + sliceRadius * Math.cos(radianAngle),
        'y': this.centY + sliceRadius * Math.sin(radianAngle)
    };
}


function SliceOfPie(userParamSliceArray, radStartAngle, radEndAngle, radius) {

    // for enlarging slice size animation by changing its radius size
    // so need to save individual slice's radius
    // in this context this means an element of SliceArray
    this.sliceRadius = radius;
    this.radStartAngle = radStartAngle;
    this.radEndAngle = radEndAngle;
    this.sliceUserParams = userParamSliceArray;

    // RMH a new one
    this.isHighlighted = false;

}

function drawSlice(slice) {
    let ctx = this.ctx;

    if (ctx) {


        let borderColor = '#fff';// non highlighted border color
        let lineWidth = 0;// non highlighted border color

        // draw border ONLY if this slice is active
        if (slice.isHighlighted) {

            if (slice.sliceUserParams[3]) {
                borderColor = slice.sliceUserParams[3];
            }
lineWidth = 15

        }
        ctx.strokeStyle = borderColor;

        // set this point as beginning of path
        ctx.beginPath();

        // 1. draw the arc (THIS IS starting point)
        ctx.arc(this.centX, this.centY, slice.sliceRadius, slice.radStartAngle, slice.radEndAngle);

        // 2. draw line from end of arc to center
        ctx.lineTo(this.centX, this.centY); // line to go back to center make a PIE SLICE

        // 3. draw line from center to start of arc (to complete line)
        // let point = this.getPointXY(slice.sliceRadius, slice.radStartAngle);
        // ctx.lineTo(point.x, point.y);

        // use closePath() instead of calculate the point using getPointXY() --> slow math calculation
        ctx.closePath(); // NOTE: must call closePath() before call fill() or stroke() !!

        // IMPORTANT: default stroke is in the MIDDLE of the line,
        // to change it to 'INNER', we need to work in sequence: fill() then clip() then stroke())
        ctx.fillStyle = slice.sliceUserParams[2];

        ctx.fill();

        //console.log('drawSlice(slice), this is the active slice: ' + this.activeSliceOfPie);

        // need to draw border

        ctx.save(); // save before clip
        ctx.clip();

        // lineWidth was previously defined but not working, so always define it before use
        ctx.lineWidth = lineWidth; // will be used in HALF (because inner clip)



        // use the provided value if existed or use default WHITE
        //if (slice.sliceUserParams[3]) {
        //  borderColor = slice.sliceUserParams[3];
        //}
        ctx.stroke();


        // restore will let open all canvas area (remove clip)
        ctx.restore();

        // }
    }
}

function initialiseCharts() {
    if (!this.canvas) {
        return;
    }

    // get parent size
    let parentW = this.canvas.parentNode.offsetWidth;
    let parentH = this.canvas.parentNode.offsetHeight;

    // resize canvas as BIG as parent
   // if (this.canvas.width != parentW || this.canvas.height != parentH) {
        //console.log('parent: ' + parentW + '*' + parentH + ', canvas: ' + this.canvas.width + '*' + this.canvas.height);

        let maxDiameterPie = Math.min(parentW, parentH);
        let maxRadiusPie = maxDiameterPie / 2
        // square w = h
        this.canvas.width = maxDiameterPie;


        this.canvas.width = maxDiameterPie;
        //this.MIN_RADIUS = this.MAX_RADIUS - 50; // -5 : gap to avoid pie go outside parent
        this.canvas.height = this.canvas.width;


        // minus 10%
        this.originalRadius = (maxRadiusPie * 90) / 100;

        // center
        this.centX = maxDiameterPie / 2;
        this.centY = this.centX;//this.height / 2;

        console.log ("am i really refreshing")

        // resized means NEED TO recreate all slices and do full draw(), not only redraw same slices
        this.refreshSliceArray(true);//true); // force

        console.log ("doing a redraw it seems")
        this.redraw();
   // }
}

function drawLabels() {
    //console.log('drawLabels()');
    /*
    if (this.ctx) {
        let xy, centerRadian;
        for (let i in this.sliceArray) {
            let slice = this.sliceArray[i];

            // to optimize speed by avoiding next calculation of xy, use cache !!
            if (slice.cachedRadius && slice.cachedRadius == slice.sliceRadius) {
                // hit cache (same as cached value), so use it
                xy = slice.cachedXY;
                //console.log('** cache hit');
            } else {
                // no cache OR different with cached value, so update it
                slice.cachedRadius = slice.sliceRadius;

                // calculate center radian angle
                if (this.startFrom0oClock && slice.radStartAngle > slice.radEndAngle) {
                    centerRadian = (slice.radStartAngle + slice.radEndAngle) - this.MAX_RADIAN_VALUE;
                    centerRadian /= 2.0;
                } else {
                    centerRadian = (slice.radStartAngle + slice.radEndAngle) / 2.0;
                }

                // get the point XY using slice's current radius
                xy = this.getPointXY(slice.sliceRadius - 40, centerRadian);

                // save into cache
                slice.cachedXY = xy;
            }

            let text = this.userParamSliceArray[i][0];
            this.ctx.font = 'bold 14px sans-serif'
            let textWidth = this.ctx.measureText(text);
            if (xy['x'] + textWidth.width >= this.canvas.width) {
                // over to the right, so change it
                xy['x'] = this.canvas.width - (textWidth.width + 3);
            }
            const approxSize = 1;

            // text outline (stroke)
            this.ctx.fillStyle = '#ccc';
            this.ctx.fillText(text, xy['x'] - approxSize, xy['y'] - approxSize); // top left
            this.ctx.fillText(text, xy['x'] - 0, xy['y'] - approxSize); // top center
            this.ctx.fillText(text, xy['x'] + approxSize, xy['y'] - approxSize); // top right

            this.ctx.fillText(text, xy['x'] - approxSize, xy['y'] - 0); // middle left
            this.ctx.fillText(text, xy['x'] - 0, xy['y'] - 0); // middle center
            this.ctx.fillText(text, xy['x'] + approxSize, xy['y'] - 0); // middle right

            this.ctx.fillText(text, xy['x'] - approxSize, xy['y'] + approxSize); // bottom left
            this.ctx.fillText(text, xy['x'] - 0, xy['y'] + approxSize); // bottom center
            this.ctx.fillText(text, xy['x'] + approxSize, xy['y'] + approxSize); // bottom right

            // main text, same like as the pie
            this.ctx.fillStyle = this.userParamSliceArray[i][2];
            this.ctx.fillStyle = '#555555';//this.userParamSliceArray[i][2];
            this.ctx.fillText(text, xy['x'], xy['y']);
        }
    }

     */
}

function updateSliceArray(radius) {
    console.log('updateSliceArray(' + radius + ')');

    for (let i in this.userParamSliceArray) {
        this.sliceArray[i].sliceRadius = radius;
    }
}

function refreshSliceArray() {
    if (this.totalAllValue <= 0) {
        this.sliceArray = []; // no slice
        return;
    }

    let radStartAngle = 0, radEndAngle = 0;

    if (this.startFrom0oClock) {

        radStartAngle = 90 * Math.PI / 180

    }
    for (let i in this.userParamSliceArray) {


        console.log("refreshing slices...")
        // how many degree this 1 slice ? .. 360 * (value / total)
        let angle = this.MAX_RADIAN_VALUE * (this.userParamSliceArray[i][1] / this.totalAllValue);
        radEndAngle = radStartAngle + angle;

        if (this.startFrom0oClock && radEndAngle > this.MAX_RADIAN_VALUE) {
            radEndAngle -= this.MAX_RADIAN_VALUE;
        }

        this.sliceArray[i] = new this.SliceOfPie(this.userParamSliceArray[i], radStartAngle, radEndAngle, this.originalRadius);

        // update for next loop
        radStartAngle = radEndAngle;
    }
}

function getRadianDegree(x, y) {
    let x2 = x - this.centX;
    let y2 = y - this.centY;
    let v1dl = Math.sqrt((x2 * x2) + (y2 * y2));

    let radDegree = Math.acos(x2 / v1dl);
    if (y2 < 0) {
        radDegree = this.MAX_RADIAN_VALUE - radDegree;
    }

    return radDegree;
}

function onMouseOver(e) {
    let x = e.pageX - this.canvas.offsetLeft,
        y = e.pageY - this.canvas.offsetTop,
        chk = (x - this.centX) * (x - this.centX) + (y - this.centY) * (y - this.centY);
    // v1dl,
    // deg;

    //console.log('x: ' + x + ', y: ' + y + ', chk: ' + chk + ', centX: ' + this.centX + ', centY: ' + this.centY + ', radius: ' + this.originalRadius);

    // is point inside radius ?
    if (chk <= this.originalRadius * this.originalRadius) {
        // it is inside radius, so check inside which slice ?

        for (let i in this.sliceArray) {
            if (this.isPointInsideSliceOfPie(x, y, this.sliceArray[i])) {
                // this point is inside this slice of pie

            // TODO:    maybe just display labels on rollover or press or something


                // reset previous active slice (if exist)
                if (this.activeSliceOfPie) {
                    if (this.activeSliceOfPie != i) {
                        // there is previous active slice and it is not this slice
                        // so remove it
                        this.sliceArray[this.activeSliceOfPie].sliceRadius = this.originalRadius;

                        // highlight new slice
                        this.highlightSlice(i);
                    }
                } else {
                    // no previous active slice, so activate this slice
                    //console.log('onMouseOver(), point: ' + x + ',' + y + ', inside pie: ' + i);
                    this.highlightSlice(i);
                }

                break;
            }
        }
    } else {
        if (this.activeSliceOfPie) {
            let slice = this.sliceArray[this.activeSliceOfPie];

            if (!this.isPointInsideSliceOfPie(x, y, slice)) {
                // outside
                this.removeAllHighlightSlice();
                this.redraw();
            }
        }
    }
}

function removeAllHighlightSlice() {
    // reset all sclies' radius
    for (let i in this.sliceArray) {
        this.sliceArray[i].sliceRadius = this.originalRadius;
    }

    // remove active slice
    delete this.activeSliceOfPie;
    this.redraw();
}

function unHighlight(pieId) {

    pieRewardingTarget.unHighlightSlice(pieId);

}

function unHighlightSlice(slicesArr) {

    for (let i = 0; i < slicesArr.length; i++) {
        this.sliceArray[i].isHighlighted = false;
    }
    this.slicesToAnimateIndices = slicesArr; // needs to be global because of how animate works
    this.animateBigger();
    this.redraw()
}

 function highlightSlice(slicesArr) {

    for (let i = 0; i < slicesArr.length; i++) {
        this.sliceArray[slicesArr[i]].isHighlighted = true;
    }
    this.slicesToAnimateIndices = slicesArr; // needs to be global because of how animate works
    this.animateSmaller()

    let everythingHighlighted = true;

    for (let i = 0; i < this.sliceArray.length; i++) {
        let slice = this.sliceArray[i]
        if (!slice.isHighlighted) {
            everythingHighlighted = false
            break
        }
    }

    if (everythingHighlighted){
       // here is were we should give some sort of reward image... the fmd logo or something
       // alert ("congrats")
    }

}

function isPointInsideSliceOfPie(x, y, slice) {
    //console.log('isPointInsideSliceOfPie(' + x + ',' + y + ', slice angle:' + slice.radStartAngle + ',' + slice.radEndAngle + ')');

    // 1. check if the angle is inside or not (because cheaper/faster operator then checking distance/radius ???? TODO:)
    // calculate the Radian angle of this point
    let angle = Math.atan2(y - this.centY, x - this.centY);

    // if negative then convert to positive
    if (angle < 0) {
        angle += this.MAX_RADIAN_VALUE;
    }
    //console.log('angle: ' + angle);

    if (this.startFrom0oClock && slice.radStartAngle > slice.radEndAngle) {

        if (angle < slice.radStartAngle && angle > slice.radEndAngle) {
            return false;
        }
    } else if (angle < slice.radStartAngle || angle > slice.radEndAngle) {
        //console.log('** point is OUTSIDE slice angle');
        return false; // this point is outside of pie
    }

    // 2. check distance and radius
    let pointDistance = ((x - this.centX) * (x - this.centX)) + ((y - this.centY) * (y - this.centY));
    if (pointDistance > slice.sliceRadius * slice.sliceRadius) {
        //console.log('** point is OUTSIDE slice distance');
        return false; // point is outside radius
    }

    return true; // this point is inside slice of pie
}

function redraw() {
    //console.log('redraw(), full drawing without setting sliceArray');
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    for (let i in this.sliceArray) {
        this.drawSlice(this.sliceArray[i]);
    }
    this.drawLabels();
}

function animateSmaller() {
    //console.log('animate()');
    const animIncrementStep = 1;  // this value dictates the speed of transition (in px)


let animSlices = [];
    for (let i = 0; i < this.slicesToAnimateIndices.length; i++) {
        animSlices.push(this.sliceArray[this.slicesToAnimateIndices[i]]) // is the order preserved with a push?
    }

    if (! isEmpty(animSlices)) {

        if (animSlices[0].sliceRadius <= this.HIGHLIGHTED_RADIUS) { // if it IS the requested size - use the first int he array as a yardstick

            for (let i = 0; i < animSlices.length; i++) {
                animSlices[i].sliceRadius = this.HIGHLIGHTED_RADIUS; // finalise the size just for accuracy in case we've overshot
            }



        } else { // we're not the right size yet
            // request to call this function again (recursive)
            window.requestAnimationFrame(this.animateSmaller.bind(this)); // use bind() for next loop

            for (let i = 0; i < animSlices.length; i++) {
                animSlices[i].sliceRadius -= animIncrementStep; // this value dictates the speed of transition (in px)
            }
        }

        // make radius bigger (pop out effect)
// must redraw all slices, because previous active slice must be refreshed too
        this.redraw();
    }

}

function animateBigger() {
    //console.log('animate()');

    const animIncrementStep = 10;  // this value dictates the speed of transition (in px)

    let animSlice = this.sliceArray[this.sliceToAnimateIndex] // stored globally because of how bind works animate can't have parameters
    if (animSlice) {

        //console.log('animate(), active slice: ' + this.activeSliceOfPie);

        // make it BIGGER radius in steps (feel like animation)
        if (animSlice.sliceRadius >= this.originalRadius) {
            // limit
            // we're done, lets end up here
            animSlice.sliceRadius = this.originalRadius;
        } else {
            // request to call this function again (recursive)
            window.requestAnimationFrame(this.animateBigger.bind(this)); // use bind() for next loop
            // make radius bigger (pop out effect)
            animSlice.sliceRadius += animIncrementStep;
        }


        // must redraw all slices, because previous active slice must be refreshed too
        this.redraw();
    }

}








