import React from 'react'
import {highlightOnFocus} from "../../utils/FormattingUtils";
import NutrientAdd from "../auto-suggestion-Lists/NutrientAdd";
import ErrorMessage, {styleIfError} from "../../Validation/ErrorMessage";
import * as Const from "../../Validation/ErrorConstants";
import question from '../../img/question.png';

import {FmdInputRow} from "../../utils/FmdInputRow";


const CreateFoodView = ({
                            nutris,
                            onNutrientAdd,
                            onChangeOfNutrientQuantity,
                            onChangeOfFat,
                            onChangeOfProtein,
                            onChangeOfCarbs,
                            onChangeOfSugar,
                            addFoodData,
                            submitFoodData,
                            excludeFromOptionals,
                            errorMessageData,


                        }) => {

    return (
        <>

            <div className="outerDiv">

                <h1>Add Ingredient</h1>
             <p>
                Your new ingredient will be available to select on the Recipe screen.
             </p>

                <table className="mainTable">
                    <tbody>
                    <tr>


                        <FmdInputRow jsonName={"foodName"}
                                     fldNameLabel={"Food Name"}
                                     errorMessageCodes={[Const.FOOD_NAME_ERR]}
                                     dataAddFunction={addFoodData}
                                     errorMessageData={errorMessageData}/>


                    </tr>


                    </tbody>
                </table>

                <br/>


                <h3>
                    Nutrients On Label (per 100g)
                </h3>

                <table className="main-table-highlighted">

                    <tr>
                        <FmdInputRow jsonName={"kcalPer100g"}
                                     fldNameLabel={"Kcal"}
                                     errorMessageCodes={[Const.FOOD_KCALS, Const.FMD_FOOD_NUTS_DONT_ADD]}
                                     dataAddFunction={addFoodData}
                                     errorMessageData={errorMessageData}
                                     compulsory={true}/>
                    </tr>
                    <tr>
                        <FmdInputRow jsonName={"fatVal"}
                                     fldNameLabel={"Fat (g)"}
                                     errorMessageCodes={[Const.FOOD_NUT_ERR_ + "10"]}
                                     dataAddFunction={onChangeOfFat}
                                     errorMessageData={errorMessageData}
                                     compulsory={true}/>

                    </tr>
                    <tr>

                        <FmdInputRow jsonName={"proVal"}
                                     fldNameLabel={"Protein (g)"}
                                     errorMessageCodes={[Const.FOOD_NUT_ERR_ + "20"]}
                                     dataAddFunction={onChangeOfProtein}
                                     errorMessageData={errorMessageData}
                                     compulsory={true}/>

                    </tr>
                    <tr>

                        <FmdInputRow jsonName={"carbVal"}
                                     fldNameLabel={"Carbs (g)"}
                                     errorMessageCodes={[Const.FOOD_NUT_ERR_ + "30"]}
                                     dataAddFunction={onChangeOfCarbs}
                                     errorMessageData={errorMessageData}
                                     compulsory={true}/>

                    </tr>
                    <tr>

                        <FmdInputRow jsonName={"sugVal"}
                                     fldNameLabel={"of which sugar"}
                                     errorMessageCodes={[Const.FOOD_NUT_ERR_ + "35"]}
                                     dataAddFunction={onChangeOfSugar}
                                     errorMessageData={errorMessageData}
                                     compulsory={true}/>

                    </tr>

                </table>
                <br/>
                <h3>Convenient Amount (optional)</h3>



                <table className="main-table-highlighted">
                    <tr>

                        <td colSpan={3} style={{border:"none"}}>

                            <p>
                                The easiest amount to add to a recipe in one go, like:

                                <ul>
                                    <li><b>'each'</b> for something like an apple or orange</li>
                                    <li><b>'slice'</b> for something like bread</li>
                                    <li><b>'can'</b> for a canned food</li>
                                </ul>
                            </p>


                        </td>
                    </tr>
                    <tbody>
                    <tr>
                        <FmdInputRow jsonName={"specificPortionAmount"}
                                     fldNameLabel={"Amount (grams)"}
                                     errorMessageCodes={[Const.FOOD_PORT_AMOUNT_ERR]}
                                     dataAddFunction={addFoodData}
                                     errorMessageData={errorMessageData}
                                     compulsory={false}
                                     toolTip="The weight of a convenient amount in grams."
                        />
                    </tr>
                    <tr>
                        <FmdInputRow jsonName={"specificPortionSingular"}
                                     fldNameLabel={"Name of Portion"}
                                     errorMessageCodes={[Const.FOOD_PORT_NAME_ERR]}
                                     dataAddFunction={addFoodData}
                                     errorMessageData={errorMessageData}
                                     defaultVal={"each"}
                                     compulsory={false}
                                     toolTip="The name of the most convenient amount of these e.g. 'each', 'clove', 'slice' or 'spoonful'.
                                     Make blank if it isn't relevant."

                        />


                    </tr>

                    </tbody>
                </table>
                <br/>
                <div style={{textAlign:"center"}}>
                <button onClick={() => {
                    submitFoodData()
                }}>Add New Food...
                </button>
                </div>

                <br/>
                <h3>Other nutrients (g per 100g) </h3>


                <table className={"main-table-highlighted"}>
                    <tbody>
                    <tr key={-1}>
                        <td colSpan={2} style={{border:"none"}}>
                            <NutrientAdd onNutrientAdd={onNutrientAdd}/>
                        </td>
                    </tr>
                    {nutris.map((nut, index) => (
                        !excludeFromOptionals.includes(nut.nutrientDto.nutrientId)) ? ( // the compulsory ones are excluded
                        <tr key={nut.nutrientId}>
                            <td style={{textAlign: "right"}}>
                                <label title="compulsory (but can set to 0)"
                                       className="field-compulsory">{nut.nutrientDto.nutrientName}:</label>
                            </td>
                            <td>
                                <input
                                    className={styleIfError(Const.FOOD_NUT_ERR_ + nut.nutrientDto.nutrientId, errorMessageData)}
                                    style={{width: "44px"}}
                                    key={nut.nutrientId}
                                    onFocus={(e) => {
                                        highlightOnFocus(e)
                                    }}
                                    onChange={(e) => {
                                        onChangeOfNutrientQuantity(nut.nutrientDto.nutrientId, e.target.value, index)
                                    }}
                                /> (g per 100g)
                                <ErrorMessage errorCodes={[Const.FOOD_NUT_ERR_ + nut.nutrientDto.nutrientId]}
                                              errorDataArray={errorMessageData}/>

                            </td>
                        </tr>
                    ) : (null))}
                    </tbody>
                </table>
                <br/><br/><br/>
                {
                  /*  JSON.stringify(nutris)} */
                }
                <br/>
                {
                    /* JSON.stringify(errorMessageData)} */
                }
                <br/><br/>
            </div>
        </>
    );
};

export default CreateFoodView;
