import React from 'react'

const FavoriteStar = ({

                          loggedIn,
                          onImage,
                          offImage,
                          inactiveImage,
                          onInactiveClick,
                          onToggleFavorite,
                          recipeFavorite
}) => {

    let starImage = loggedIn()  ? (recipeFavorite >= 0 ? (onImage) : (offImage)): (inactiveImage)
let alt = loggedIn()  ? (recipeFavorite >= 0 ? ("Unfavorite Me") : ("Make Me a Favorite")): ("Sign In to Use Favorites")
    return (


        <span className="tooltippopup" onClick={(e) => {

            loggedIn() ? (onToggleFavorite()) : (
                document.getElementById("favoritepopupcontext").classList.toggle("show")
            )
            ;
        }}><img style={{
            cursor: "pointer",
            textAlign: "right",
            verticalAlign: "bottom",
            margin: "0px",
            paddingLeft: 2,
            paddingBottom: 2
        }}  src={starImage} title={alt} alt={alt}

        /><span style={{width:380}} className="tooltippopuptext" id={"favoritepopupcontext"}>{alt}</span>
                        </span>

    );
 };

export default FavoriteStar;
