import React from 'react'
import {highlightOnFocus, titleCase} from "./FormattingUtils";
import ErrorMessage, {styleIfError} from "../Validation/ErrorMessage";
import questionMark from '../img/question.png';
import {obfuscate} from "./ObfUtil";
import {isEmpty} from "lodash";

export const FmdInputRow = ({
                                jsonName,
                                errorMessageData,
                                dataAddFunction,
                                compulsory = true,
                                fldNameLabel = titleCase(jsonName),
                                errorMessageCodes,
                                inputType = "text",
                                toolTip = "",
                                imageForTip = questionMark,
                                placeholder = "",
                                defaultVal
                            }) => {

    let nb = "\u00a0";
    let nonBreakField = fldNameLabel.replace(/ /g, nb)

    let errorColspan = toolTip.length > 0 ? (2):(1);
    const popit = async () => {

        var tooltippopup = document.getElementById("popup" + jsonName);
        console.log("running the function with "+ jsonName + " " + tooltippopup)
        tooltippopup.classList.toggle("show");
    }

        return (
        <>
            <td style={{
                maxWidth: "100%",
                whiteSpace: "nowrap",
                textAlign: "right",
                verticalAlign: "top",
                paddingTop: 2.5,
                paddingRight: 0,

            }}>
                {compulsory ? (<label title="required" className="field-compulsory">&nbsp;<sup
                        className="field-compulsory-indicator">REQ&nbsp;</sup>{nonBreakField}&nbsp;&nbsp;</label>)
                    : (<label title="optional">&nbsp;{nonBreakField}&nbsp;&nbsp;</label>)}
            </td>
            <td style={{width: "100%", paddingRight: 0, border: "none"}}>
            <table style={{width: "100%", padding: "0 0 0 0", border: "none", borderCollapse:"collapse"}}>
            <tr>

            <td style={{width: "100%", paddingRight: 0, border: "none"}}>
                <input
                    id={jsonName}
                    type={inputType}
                    placeholder={placeholder}
                    className={styleIfError(errorMessageCodes, errorMessageData, "inputboxerror", "")}
                    style={{width: '100%', paddingLeft: 4, paddingRight: 0}}
                    defaultValue={defaultVal}
                    onFocus={(e) => {
                        highlightOnFocus(e)
                    }}
                    onChange={(e) => {
                        let val = e.target.value;
                        val = inputType === "password" ? (obfuscate(val)) : (val);
                        dataAddFunction({[jsonName]: val})
                    }}
                />


</td>
                {toolTip.length > 0 ? (
                    <td style={{border: "none"}}>




                        <span className="tooltippopup" onClick={(e) => {
                            popit()
                        }}><img className={"round-tappable-image"} style={{paddingLeft:2}}
                          src={imageForTip} title={toolTip} alt={toolTip}


                        /><span style={{width:380}} className="tooltippopuptext" id={"popup"+jsonName}>{toolTip}</span>
                        </span></td>

                ) : ("")}
            </tr>
                {!isEmpty(errorMessageData)? (
                <tr>

                    <td colspan={errorColspan} style={{border:"none", textAlign:"left"}}>
                        <ErrorMessage errorCodes={errorMessageCodes} errorDataArray={errorMessageData}/>
                    </td>

            </tr>)
                    :("")}

            </table></td>
        </>
    );

}


