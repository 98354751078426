import React from 'react'
import {numFormat, highlightOnFocus} from "../../../utils/FormattingUtils"
import upPortionArrow from '../../../img/upPortion.png';
import downPortionArrow from '../../../img/downPortion.png';
import bin from '../../../img/bin.png';
import closedPad from '../../../img/padlockClosedRed.png';
import openedPad from '../../../img/padllockOpen.png';
import FoodAdd from "../../auto-suggestion-Lists/FoodAdd";
import ErrorMessage from "../../../Validation/ErrorMessage";
import * as Const from "../../../Validation/ErrorConstants";
import {RECIPE_UNDER_FOOD_ENTRY_} from "../../../Validation/ErrorConstants";

const IngredientsList = ({ingreds, onIngredientAdd, onIngredientBinned, onPortionProportionChange, onChangeOfIngredientQuantity, onChangeOfIngredientLockedStatus, onRoundPortionClicked, moveIngUpInList, moveIngDownInList, errorDataArray}) => {
    let timer
    return (<div>
             <table  className="mainTable">
                <tbody>
                <tr><td colSpan={4}>
                    <div>
                        <FoodAdd onIngredientAdd={onIngredientAdd}/>
                    </div>
                </td>
                </tr>
                    {ingreds.map((ing, index) => (
                        <tr key={ing.foodDto.foodId}>
                            <td style={{textAlign: "right"}} title={ing.displayOrder + ": " + ing.ingredientId + " / " + ing.foodDto.foodId}>
                                {ing.foodDto.portionQuantity != null ?(
                                    <>
                                        <b>&nbsp;</b>
                                        <img style={{cursor: "pointer"}} src={upPortionArrow} title="round up" onClick={(e) => {onRoundPortionClicked(1,index, ing)}} />
                                        <img style={{cursor: "pointer"}} src={downPortionArrow}  title="round down" onClick={(e) => {onRoundPortionClicked(-1, index, ing)}} />
                                        <input  style={{ width: "44px" }} value={ing.portionProportion === undefined ? (numFormat(ing.quantity/ing.foodDto.portionQuantity, 2)) : ing.portionProportion}
                                                onChange={(e) => {onPortionProportionChange(e.target.value, index, ing)}}
                                                onFocus={(e) => {highlightOnFocus(e)}}
                                        />&nbsp;
                                        {ing.foodDto.specificPortionPlural}
                                        &nbsp;of&nbsp;
                                    </>
                                ):(<> </>)}
                                <b>{ing.foodDto.foodName}</b>
                                &nbsp;
                                <input
                                    value={numFormat(ing.quantity, 0)}
                                    style={{ width: "44px" }}
                                    onChange={(e) => {
                                        onChangeOfIngredientQuantity(e.target.value, index, ing);
                                    }}
                                onFocus={(e) => {highlightOnFocus(e)}}/>g
                                <ErrorMessage errorCodes={[Const.RECIPE_UNDER_FOOD_ENTRY_ + ing.foodDto.foodId]} errorDataArray={errorDataArray}/>
                            </td>
                            <td>
                                <img style={{cursor: "pointer"}} src={ing.quantityLocked? closedPad : openedPad} alt="Lock / Unlock for Fitting"
                                     onClick={(e) => {
                                         //todo:
                                        // not much work to get working, still a little......

                                         let oldLockedStatusOpen =  e.target.getAttribute('src')===openedPad;

                                      //   need to get this to work properly. prob use existing ings as state if at all possilbe... should be i think, i frequires full re-render of ingredient list it's ok I think. React will sort efficiency'
                                        onChangeOfIngredientLockedStatus( e.target.getAttribute('src')===openedPad, index, ing);
                                         oldLockedStatusOpen? e.target.setAttribute( 'src', closedPad) : e.target.setAttribute( 'src', openedPad);
                                     }}
                                />
                            </td>
                            <td>
                                <img style={{cursor: "pointer"}} src={bin} alt="remove"
                                     onClick={(e) => {onIngredientBinned(index)}}
                                />
                            </td>

                            <td style={{ textAlign: "center", minWidth:33}}>

                                {(ing.displayOrder != ingreds.length) ?(
                                    <img style={{cursor: "pointer"}} src={downPortionArrow} title="move down"  alt="move down" onClick={(e) => {moveIngDownInList(ing)}} />
                                ):(<></>)}

                                {(ing.displayOrder != 1) ?(
                                    <img style={{cursor: "pointer", textAlign: "right"}} src={upPortionArrow} title="move up" alt="move up" onClick={(e) => {moveIngUpInList(ing)}} />
                                ):(<></>)}

                                {ing.ingredientId != null && ing.ingredientId>0 ?(<></>
                                ):(
                                    <b  style={{cursor: "pointer", color: '#990033'}} title="unsaved">*</b>
                                )}

                            </td>

                        </tr>
                    ))}

                </tbody>
            </table>
            <br/>
        </div>
    );
 };

export default IngredientsList;
