import React, {useEffect, useState} from 'react'
import {restDelete, restGet, restPost, restPut} from "../../utils/RestUtils";
import NavBar from "../nav-bar/NavBar";
import {
    addSingleErrorToScreen,
    blankErrorMessagesOnScreen,
    favoritesListIsValid,
    getServerSideErrorMessage
} from "../../Validation/ErrorValidation";
import {getLoginItem} from "../../utils/LoginUtils";
import FavoritesView from "./FavoritesView";
import {isEmpty, isUndefined} from "lodash";


const FavoritesWrapper = () => {

        const [currentFavItems, setCurrentFavItems] = useState({});// recipes in the currently selected list
        const [allUsersLists, setAllUsersLists] = useState([]); // all the lists for this user
        const [newListName, setNewListName] = useState(); // all the lists for this user

        const [errorMessageData, setErrorMessageData] = useState([]);


        const setItemsToCurrentlyView = async (idOfList, fullJson = allUsersLists,) => {

            let favLists = fullJson.favoritesFolderList


            favLists.map((list, index) => {

                if ((isUndefined(idOfList) || isEmpty(idOfList)) && list.listType === 10) { // ten is unassigned, this by default
                    // these are the uncategorized ones and are the default
                    console.log("found my list" + JSON.stringify(list))
                    setCurrentFavItems(list);
                } else if (list.favoritesFolderId === idOfList) {
                    setCurrentFavItems(list);
                }

            })

        }

        const onRemoveFavoritesFolder = async (recipeListId, itemIndex) => {


            console.log("recipeListId, itemIndex" + recipeListId + " " + itemIndex)
            // because changing the state variable directly results in failure to rerender
            let favsOnScreen = JSON.parse(JSON.stringify(currentFavItems));

            let fullListsWithUser = JSON.parse(JSON.stringify(allUsersLists));
            let unassignedId
            let listToDitchId
            let listToDitch

            for (let i = 0; i < fullListsWithUser.favoritesFolderList.length; i++) {

                if (fullListsWithUser.favoritesFolderList[i].listType === 10) { // unassigned
                    unassignedId = fullListsWithUser.favoritesFolderList[i].favoritesFolderId
                }

                if (fullListsWithUser.favoritesFolderList[i].favoritesFolderId === recipeListId) {
                    // this is the one specified that we need to deal with (delete)
                    listToDitch = fullListsWithUser.favoritesFolderList[i]
                    listToDitchId = listToDitch.favoritesFolderId
                }
            }

            // reassign all favorites in here to unsasigned list
            for (let i = 0; i < listToDitch.favoriteRecipeItemsList.length; i++) {
                let itemToUnassign = listToDitch.favoriteRecipeItemsList[i]
                // TODO: just do a delete as well below with the right vars passed in, and do this bit server side
                await onReasignItem(itemToUnassign, unassignedId)
            }

            let listToRefreshTo = currentFavItems.favoritesFolderId
            if (currentFavItems.favoritesFolderId === listToDitchId) {
                listToRefreshTo = unassignedId; // if we're deleting what we're currently viewing, go to unassigned on refresh
            }

            let justDeletedId = await restDelete(`/fmd/favorites-folder/${listToDitchId}`); // no need to await, it's async
            loadScreen(listToRefreshTo)
        }

        const onRemoveItemFromFavorites = async (recipeListId, itemIndex) => {


            for (let i = 0; i < allUsersLists.favoritesFolderList.length; i++) {

                if (allUsersLists.favoritesFolderList[i].favoritesFolderId === recipeListId) {

                    let itemId = allUsersLists.favoritesFolderList[i].favoriteRecipeItemsList[itemIndex].favoriteRecipeItemId

                    await restDelete(`/fmd/favorite-recipe-item/${itemId}`); // no need to await, it's async
                    break;
                }
            }

            loadScreen(currentFavItems.favoritesFolderId)

        }


        const onAddFavoritesFolder = async () => {

            let favoritesFolderDto;
            let registeredUserId = getLoginItem("re");
            if (isUndefined(newListName)) {
                favoritesFolderDto = {}; // to avoid null pointers and weird behaviour if foodDto isn't even in the json (which it isn't at first)
            } else {
                favoritesFolderDto = {
                    "registeredUserDto": {registeredUserId},
                    "favoritesFolderName": newListName.favoritesFolderName
                };
            }

            console.log("the json is here... " + JSON.stringify(favoritesFolderDto))


            let listIsValid = await favoritesListIsValid(favoritesFolderDto, setErrorMessageData);// client side validation

            if (listIsValid) {
                let responseJson = await restPost("/fmd/favorites-folder/", {favoritesFolderDto});
                let errorText = getServerSideErrorMessage(responseJson); // message chosen on client side here
                let errorCode = responseJson.messageCode;
                if (errorText != null) {
                    //setErrorMessageData({"errorPosition" : json.errorCode, "message": error}) // for error in different positions
                    await addSingleErrorToScreen(errorCode, errorText, setErrorMessageData) // hard coded for error in same position regardless of the error
                } else {
                    await blankErrorMessagesOnScreen(setErrorMessageData)

                    loadScreen(currentFavItems.favoritesFolderId)

                }

            }

        }


        const onReasignItem = async (favRecItem, favoritesFolderId) => {


            console.log("new list id is:" + favoritesFolderId);

            console.log("on reasign our fav item:" + JSON.stringify(favRecItem));

            let reassignedListItem = {
                "favoriteRecipeItemDto": {
                    "favoritesFolderDto": {favoritesFolderId},
                    ...favRecItem
                }
            }

            console.log("on reasign our fav item after change:" + JSON.stringify(reassignedListItem));

            await (await restPut("/fmd/favorite-recipe-item/", reassignedListItem));

            loadScreen(currentFavItems.favoritesFolderId);
        }


        const fetchListsForThisUser = async (userId, flipToThisId = null) => {

            let responseJson = await restGet(`/fmd/registeredusers/${userId}/favorites-folder/`);
            let errorText = getServerSideErrorMessage(responseJson);

            if (errorText != null) {
                //setErrorMessageData({"errorPosition" : json.errorCode, "message": error}) // for error in different positions
                await addSingleErrorToScreen(responseJson.messageCode, errorText, setErrorMessageData) // hard coded for error in same position regardless of the error
            } else {

                await setAllUsersLists(responseJson)

                await setItemsToCurrentlyView(flipToThisId, responseJson)

                console.log(JSON.stringify(responseJson));
            }

        }


        const loadScreen = async (listDefault = null) => {

            let regUser = getLoginItem("re");


            fetchListsForThisUser(regUser, listDefault)

        }


        useEffect(() => {

            loadScreen(null);

        }, []);


        return (<>
                <NavBar/>
                <FavoritesView
                    currentFavItems={currentFavItems}
                    allUsersList={allUsersLists}
                    changeListToView={setItemsToCurrentlyView}
                    onRemoveItemFromFavorites={onRemoveItemFromFavorites}
                    setNewListName={setNewListName}
                    onAddFavoritesFolder={onAddFavoritesFolder}
                    onRemoveFavoritesFolder={onRemoveFavoritesFolder}
                    errorMessageData={errorMessageData}
                    onReasignItem={onReasignItem}
                />
                <br/>
            </>
        );
    }
;

export default FavoritesWrapper;
