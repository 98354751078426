import React, {useEffect} from 'react'
import displayPieChart from "./piechart-small.js";

const PieChartSmallView = ({
                               uniqueName,
                               fat,
                               protein,
                               carbs,
                               widthPx = 32, // pass in what you want it to display at first

                           }) => {


    const doFirst = async () => {


// todo: consider the effeciency of this, and review efficiency of pie chart small. For the small and rewarding one shouldn't i do this in index once only

        console.log("am i even refreshing")


        let initialPositions = [fat, protein, carbs]

        console.log(" initial positions " + JSON.stringify(initialPositions));

        await displayPieChart(uniqueName, initialPositions)
    }

    useEffect(() => {
        console.log("am i even in use effect")

        doFirst();

    }, [doFirst]);


    return (
        <div className={uniqueName} id={"outerdiv" + uniqueName}
             style={{textAlign: 'center', alignContent: 'center', width: widthPx, height: widthPx}}>
            <canvas id={uniqueName}>
                Sorry, our pie chart isn't compatible with your browser, please contact support
            </canvas>
        </div>
    );


};

export default PieChartSmallView;




