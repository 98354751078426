import React from 'react'
import * as Err from "../../Validation/ErrorConstants";
import {FmdInputRow} from "../../utils/FmdInputRow";
import cross from '../../img/cross.png';
import tick from '../../img/tick.png';
import question from '../../img/question.png';
import {isNull} from "lodash";

const RegistrationView = ({
                              addRegisteredUserData,
                              submitRegisteredUserData,
                              errorMessageData,
                              unameInUse
                          }) => {


// To calculatte which tool tip image and advice is needed due to username availability

    let unameImage
    let unameTip
    console.log("this is the username for sure:" + unameInUse)
    if (isNull(unameInUse)) {
        unameImage = question
        unameTip = "3 or more characters, no punctuation"
    } else if (!unameInUse) {
        unameImage = tick
        unameTip = "Available"

    } else if (unameInUse) {
        unameImage = cross
        unameTip = "Already taken"
    }


    return (
        <div className="outerDiv">
            <h1>Sign Up</h1>
            <p>
            Please enter your details so you can have permanent access to all of your recipes.
                </p>
            <table className="mainTable">
                <tbody>
                <tr>
                    <FmdInputRow jsonName={"username"} imageForTip={unameImage} toolTip={unameTip}
                                 errorMessageCodes={[Err.REG_UNAME_INVALID, Err.FMD_REG_USERNAME_IN_USE]}
                                 dataAddFunction={addRegisteredUserData}
                                 errorMessageData={errorMessageData}/>
                </tr>
                <tr>
                    <FmdInputRow jsonName={"email"} errorMessageCodes={[Err.REG_EMAIL_INVALID]}
                                 dataAddFunction={addRegisteredUserData}
                                 errorMessageData={errorMessageData}/>
                </tr>
                <tr>
                    <FmdInputRow jsonName={"np"} fldNameLabel={"Password"}
                                 errorMessageCodes={[Err.REG_PASSWORD_INVALID]} dataAddFunction={addRegisteredUserData}
                                 errorMessageData={errorMessageData} inputType="password"
                                 toolTip="Greater than 8 characters, no spaces"/>
                </tr>
                <tr>
                    <FmdInputRow jsonName={"npc"} fldNameLabel={"Confirm Password"}
                                 errorMessageCodes={[Err.REG_PASSWORD_CONF]} dataAddFunction={addRegisteredUserData}
                                 errorMessageData={errorMessageData} inputType="password"
                                 toolTip="Must be identical to the above"/>
                </tr>
                </tbody>
            </table>
            <br/>
            <div style={{textAlign: "center"}}>
                <button onClick={() => {
                    submitRegisteredUserData()
                }}>Sign Up
                </button>
            </div>
            <br/>
        </div>
    );
};

export default RegistrationView;
